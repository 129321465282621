import { Helmet } from 'react-helmet-async';
import { Login } from '../../features/auth'

export const LoginPage = () => {
    return (
        <>
            <Helmet>
                <title>True Price - Вхід</title>
                <link rel="canonical" href="https://true-price.top/login" />
            </Helmet>
            <Login />
        </>);
}

import { LanguageDetectorModule } from 'i18next';

/**
 * В будущем когда будет определение из сервера например, нужно сделать эту функцию:
 * https://www.i18next.com/misc/creating-own-plugins
 */


const languageDetector: LanguageDetectorModule = {
    type: 'languageDetector',
    init: function (services: any, detectorOptions: any, i18nextOptions: any) {
        /* use services and options */
    },
    detect: function () {
        // You'll receive a callback if you passed async true
        /* return detected language */
        // callback('de'); if you used the async flag
        const storageLanguage = localStorage.getItem('language');
        if (storageLanguage) {
            return storageLanguage;
        } else {
            return 'ua';
        }
    },
    cacheUserLanguage: function (lng: string) {
        /* cache language */
        localStorage.setItem('language', lng);
        
    },
};

export default languageDetector;

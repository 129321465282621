import { Helmet } from 'react-helmet-async';
import { Contacts } from '../../features/contacts'

export const ContactsPage = () => {
    return (
        <>
            <Helmet>
                <title>True Price - Контакти</title>
                <link rel="canonical" href="https://true-price.top/contacts" />
            </Helmet>
            <Contacts />
        </>);
}

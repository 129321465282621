import { Trans, useTranslation } from "react-i18next";
import classes from "./contacts.module.css";

export const Contacts = () => {
  const { t } = useTranslation(["PageFAQ"]);
  return (
    <section>
      <div className={classes.title}>Контакти</div>
      <div className={classes.text}>
        <Trans
          i18nKey={t('answer13')}
          values={{ link: true }}
          components={{ a: <a></a> }}
        />
      </div>
    </section>
  );
};

import { Helmet } from 'react-helmet-async';
import { Offer } from '../../features/offer/offer.component'

export const OfferPage = () => {
    return (
        <>
            <Helmet>
                <title>True Price - ПУБЛІЧНА ОФЕРТА</title>
                <link rel="canonical" href="https://true-price.top/offer" />
            </Helmet>
            <Offer />
        </>);
}

import React from 'react';
import classes from './footer.module.css';
import logoImg from './logo.svg';
// import chromeIcon from './chrome.svg';
// import edgeIcon from './edge.svg';
import { useTranslation } from 'react-i18next';
import Menu from './menu/menu.component';
import methodIcon from './method.svg';
import up from './up.svg';
import { Link } from 'react-router-dom';
const FooterComponent = () => {
    const { t } = useTranslation('footer');
    const up = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <footer className={classes.footer}>
            <hr className={classes.devider} />
            <div className={classes.pageFooter}>
                <div className={classes.logoWrapper}>
                    <div className={classes.logo}></div>
                </div>
                <Menu />
                <div className={classes.installWrapper}>
                    <div className={classes.installText}>{t('installText')}</div>
                    <div className={classes.installIcons}>
                        <a href="https://chrome.google.com/webstore/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-ro/obkfkpbaobcmhjmopldnakdgbiecjbbl?hl=uk">
                            <div className={classes.chromeIcon}></div>
                        </a>
                        <a href="https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-/cibppbhjepploffdgeblccilmmepkkjm">
                            <div className={classes.edgeIcon}></div>
                        </a>
                    </div>
                    <div className={classes.upArrow} onClick={up}></div>
                </div>
            </div>
            <div className={classes.appFooter}>
                <div className={classes.appFooterContainer}>
                    <div className={classes.copyright}>©TruePrice 2022. {t('copyright')}</div>
                    <div className={classes.method}>
                        <div className={classes.methodIcon}></div>
                    </div>
                    <div className={classes.docsWrapper}>
                        <div className={classes.docs}>
                            <div className={classes.item}>
                                <Link to="/policy">{t('privacyPolicy')}</Link>
                            </div>
                            <div className={classes.dot}></div>
                            <div className={classes.item}>
                                <Link to="/offer">{t('termsofUse')}</Link>
                            </div>
                            <div className={classes.dot}></div>
                            <div className={classes.item}>
                                <Link to="/contacts">{t('contacts')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;

import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import classes from './subscribe-cost.module.css'
export const SubscribeCost: FC = () => {
    const { t } = useTranslation(['capabilities', 'ui']);
    const { width } = useWindowDimensions();
    const isMobile = width < 768;
    // check in location #capabilites and scrollInto
    const location = useLocation();
    useEffect(() => {
        // Проверить, есть ли в location.hash символ #
        if (location.hash) {
          // Найти элемент по id, равному location.hash без символа #
          const element = document.getElementById(location.hash.slice(1));
    
          // Если элемент найден, прокрутить к нему с плавной анимацией
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, [location]); // Передать location в качестве зависимости для хука useEffect
    return (
        <section className={classes.section} id="capabilites">
            {/* <div className={classes.sectionDivine}></div> */}
            <h2 className={classes.header}>
                {t('header')}
            </h2>
            <p className={classes.subHeader}>
                {t('subHeader')}
            </p>
            {/* <div className={classes.content}>
                <div className={classes.leftTextWrapper}>
                    <div className={classes.title}>
                        {t('subscribeTextTitle_1')}
                    </div>
                    <div className={classes.text}>
                        {t('subscribeText_1')}
                    </div>
                </div>
                {isMobile ?
                    <div className={classes.part2Wrapper}>
                        <div className={classes.cost}>
                            <div className={classes.costImage}></div>
                        </div>
                        <div className={classes.rightTextWrapper}>
                            <div className={classes.title}>
                                {t('subscribeTextTitle_2')}
                            </div>
                            <div className={classes.text}>
                                {t('subscribeText_2')}
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className={classes.cost}>
                            <div className={classes.costImage}></div>
                        </div>
                        <div className={classes.rightTextWrapper}>
                            <div className={classes.title}>
                                {t('subscribeTextTitle_2')}
                            </div>
                            <div className={classes.text}>
                                {t('subscribeText_2')}
                            </div>
                        </div>
                    </>
                }
            </div> */}
        </section>
    )
}
import { FC, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { truePriceAPI } from '../../../../app/api/true-price-api';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { urlBase64ToUint8Array } from '../../../../utils/urlBase64ToUint8Array';
import classes from './push.module.css'

interface PushProps {
    t: TFunction<"settings"[], undefined>,
    onLoaded: (status: boolean) => void
}

export const Push: FC<PushProps> = ({ t, onLoaded }) => {
    const [pushSubscription, setPushSubscription] = useState<boolean | PushSubscription>(
        false
    );
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.auth.user.accessToken);

    const {
        data: havePushSubscribe,
        isLoading: havePushSubscribeisLoading,
        error: havePushSubscribeError,
    } = truePriceAPI.useFetchCheckSubPushQuery(
        { token, subData: JSON.stringify(pushSubscription) },
        {
            skip: !token || !pushSubscription,
        }
    );

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("https://true-price.top/service-worker.js")
                .then((registration) => {
                    return registration.pushManager
                        .getSubscription()
                        .then((subscription) => {
                            if (subscription) {
                                setPushSubscription(subscription);
                            }
                        });
                });
        }
    }, []);

    useEffect(() => {
        if (
            havePushSubscribe &&
            havePushSubscribe["status"] &&
            havePushSubscribe.status === false &&
            typeof pushSubscription !== "boolean"
        ) {
            pushSubscription.unsubscribe();
            setPushSubscription(false);
        }
    }, [havePushSubscribe]);

    useEffect(() => {
        if (havePushSubscribeisLoading === false) {
            onLoaded(true);
        }
    }, [havePushSubscribeisLoading])

    const removeSub = async () => {
        if (typeof pushSubscription !== "boolean") {
            await dispatch(
                truePriceAPI.endpoints.fetchRemoveSubPush.initiate({
                    token,
                    subData: JSON.stringify(pushSubscription),
                })
            );
            pushSubscription.unsubscribe();
            setPushSubscription(false);
        }
    };

    const addSub = async () => {
        const subscription = await createSubscription();
        if (subscription) {
            await dispatch(
                truePriceAPI.endpoints.fetchAddSubPush.initiate({
                    token,
                    subData: JSON.stringify(subscription),
                })
            );
            setPushSubscription(subscription);
        }
    };

    const createSubscription = async () => {
        const registration = await navigator.serviceWorker.ready;
        const convertedVapidKey = urlBase64ToUint8Array(
            "BNvJHYQFanyypOxKPecE5lY1VF58RAu6_vFqbYAfKJ94aP5XvyFmQL4fHOadgRYNtAtyzSEnGHMN9LGYA-1HR64"
        );

        // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
        // send notifications that don't have a visible effect for the user).
        const newSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
        });
        if (newSubscription) {
            return newSubscription;
        }
    };

    return (
        <div className={classes.pushContainer}>
            <div className={classes.pushStatus}>{t("settingsPush")}</div>
            <div className={classes.pushStatus}>
                {pushSubscription ? (
                    <button
                        className={[classes.pushStatusBtn, classes.removeSub].join(" ")}
                        onClick={removeSub}
                    >
                        {t("removeSub")}
                    </button>
                ) : (
                    <button className={classes.pushStatusBtn} onClick={addSub}>
                        {t("addSub")}
                    </button>
                )}
            </div>
        </div>
    )
}

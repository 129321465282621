import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import classes from './language.module.css'

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setLanguage, selectLanguage } from './languageSlice';


const Language = () => {
    const { i18n } = useTranslation();

    // const language = useAppSelector(selectLanguage);
    // const dispatch = useAppDispatch();
    let language = i18n.language;
    // console.log('i18n.language',i18n.language)

    // useEffect(() => {
    //     const storageLanguage = localStorage.getItem('language');
    //     if (storageLanguage === 'UA' || storageLanguage === 'RU') {
    //         dispatch(setLanguage(storageLanguage));
    //     }
    // }, [])

    const [active, setActive] = React.useState(false)
    const changeLanguage = (e: any) => {
        const newLanguage = e.target.textContent?.toLowerCase();
        if (active && (newLanguage === 'ua' || newLanguage === 'ru')) {
            i18n.changeLanguage(newLanguage);
            // dispatch(setLanguage(newLanguage))
            // localStorage.setItem('language', newLanguage)
        }
        setActive(!active)
    }
    return (
        <div className={[classes.wrapper, active ? classes.active : undefined].join(' ')} onClick={changeLanguage}>
            <div className={classes.selected}>
                <span className={classes.name}>{language.toUpperCase()}</span>
                <i className={[classes.arrow, classes.down].join(' ')}></i>
            </div>
            <div className={classes.optionWrapper}>
                <div className={classes.divider}></div>
                <div className={classes.option}>
                    <span className={classes.name}>{language === 'ua' ? 'RU' : 'UA'}</span>
                </div>
            </div>
        </div>
    )
}

export default Language;
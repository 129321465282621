import { createApi } from '@reduxjs/toolkit/query/react';
import fetchStreamQuery from './fetchStreamQuery';
import { IProductDetails, ISourceNone } from './interfaces/all-site-search.interface';
import { AllSiteSearchDto } from './dto/all-site-search.dto';

const streamBaseUrl = 'https://true-price.top/api';

export const streamAPI = createApi({
    reducerPath: 'streamAPI',
    baseQuery: fetchStreamQuery({ baseUrl: streamBaseUrl }),
    endpoints: (build) => ({
        fetchAllSiteSearchData: build.query<IProductDetails | ISourceNone, AllSiteSearchDto>({
            query: (data) => ({
                url: `/all/all-site-search`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../../ui";
import { Push } from "./push/push.component";
import classes from "./settings.module.css";
import { Telegram } from "./telegram/telegram.component";

export const Settings = () => {
  const { t } = useTranslation(["settings"]);
  const [loaded, setLoaded] = useState(false);
  const [telegramLoaded, setTelegramLoaded] = useState(false);
  const [pushLoaded, setPushLoaded] = useState(false);

  useEffect(() => {
    if (telegramLoaded && pushLoaded) {
      setLoaded(true);
    }
  }, [telegramLoaded, pushLoaded]);
  return (
    <section className={classes.section}>
      <div className={classes.settingsTitle}>{t("settings")}</div>
      {!loaded && <Loader />}
      <div className={classes.settingsFlex} style={
        loaded ? { display: 'flex' } : { display: 'none' }
      }>
        <Telegram t={t} onLoaded={setTelegramLoaded} />
        <Push t={t} onLoaded={setPushLoaded} />
      </div>

    </section>
  );
};

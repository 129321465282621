import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { json } from 'stream/consumers';
import { IDiscountPrice } from '../../../app/api/interfaces/discount-price.interface';
import { ISubscribeNotificationsFullList } from '../../../app/api/interfaces/subscribe-notifications-full-list.interface';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { useAppSelector } from '../../../app/hooks';
import { useAppDispatch } from '../../../app/store';
import { FillBtn, Loader, Pagination } from '../../ui';
import classes from './notification-settings.module.css';

interface NotificationCardProps {
    notificationSub: ISubscribeNotificationsFullList;
    prices: IDiscountPrice[];
    toggleUnsubscriptionCheckbox: (id: number) => void;
    unsubscriptionCheckboxSelected: boolean;
}

const NotificationCard: FC<NotificationCardProps> = ({ notificationSub, prices, toggleUnsubscriptionCheckbox, unsubscriptionCheckboxSelected }) => {
    const { t } = useTranslation(['notificationSettings']);
    const [price, setPrice] = useState<IDiscountPrice | {}>({});
    useEffect(() => {
        const foundPrice = prices.find((p) => p.goodId === Number(notificationSub.goodId.rozetkaId));
        if (foundPrice) {
            setPrice(foundPrice);
        }
    }, [prices, notificationSub.goodId]);
    const [show, setShow] = useState<boolean>(false);
    const [newSettings, setNewSettings] = useState<ISubscribeNotificationsFullList>({ ...notificationSub });
    const saveSettings = () => {
        if (JSON.stringify(newSettings) !== JSON.stringify(prevSettings)) {
            trigger({
                token,
                subscribeNotificationsFullList: {
                    ...newSettings,
                    goodId: Number(notificationSub.goodId.rozetkaId),
                },
            });
        }
    };
    const [trigger, result, lastPromiseInfo] = truePriceAPI.useLazyFetchUpdateSubscribeSettingsQuery();
    const updateIsLoading = result.isFetching;
    const updateError: any = result.error;
    const token = useAppSelector((state) => state.auth.user.accessToken);
    const [prevSettings, setPrevSettings] = useState({ ...notificationSub });
    useEffect(() => {
        if (updateIsLoading === false && !updateError) {
            setPrevSettings({ ...newSettings });

            setShow(false);
        } else if (updateIsLoading === false && updateError) {
            setNewSettings({ ...prevSettings });
        }
    }, [updateIsLoading, updateError]);

    const dispatch = useAppDispatch();

    // пакетное удаление подписок
    const [unsubscribeLoading, setUnsubscribeLoading] = useState<boolean>(false);
    const unsubscribeHandler = async () => {
        if (unsubscribeLoading) return;
        setUnsubscribeLoading(true);
        await dispatch(
            truePriceAPI.endpoints.fetchUnsubscribeProduct.initiate({
                token,
                goodId: Number(notificationSub.goodId.rozetkaId),
            }),
        );
        setUnsubscribeLoading(false);
    };

    return (
        <div className={classes.card}>
            <div className={classes.cardBody}>
                <div className={classes.checkboxWrapper}>
                    <input
                        className={classes.checkbox}
                        id={`checkbox_${notificationSub.id}`}
                        type="checkbox"
                        checked={unsubscriptionCheckboxSelected}
                        onChange={(e) => toggleUnsubscriptionCheckbox(notificationSub.id)}
                    />
                    <label htmlFor={`checkbox_${notificationSub.id}`}></label>
                </div>
                <div className={classes.imgWrapper}>
                    <img src={notificationSub.goodId.img ? notificationSub.goodId.img : '/public/not_found.webp'} className={classes.img} alt="good img" />
                </div>
                <div className={classes.goodInfo}>
                    <input
                        className={classes.goodName}
                        value={newSettings.name}
                        type="text"
                        id="tovarName"
                        disabled={!show}
                        onChange={(e) =>
                            setNewSettings({
                                ...newSettings,
                                name: e.target.value,
                            })
                        }
                    />
                    {'price' in price && price.price && <div className={classes.goodPrice}>{price.price} грн</div>}
                </div>
                <div className={classes.discountWrapper}>
                    {('discount' in price && typeof price.discount === 'number') && 
                        <div className={classes.discount}>{price.discount < 0 ? `+${Math.abs(price.discount)}%` : `-${price.discount}%`}</div>
                    }
                </div>
            </div>
            <div className={classes.cardButtons}>
                <a href={notificationSub.goodId.link} className={classes.onRozetkaText}>
                    {t('onRozetka')}
                </a>
                <div onClick={(e) => setShow(!show)} className={classes.settings}></div>
                <div onClick={unsubscribeHandler} className={classes.remove}></div>
            </div>
            {show && (
                <div className={classes.notificationsContainer}>
                    <i className={classes.arrow}></i>
                    <div className={classes.notificationWrapper}>
                        <div className={classes.notificationPaymentTitle}>{t('payments')}</div>
                        <div className={classes.notificationPayments}>
                            <div className={classes.notificationChekbox}>
                                <input
                                    checked={newSettings.privatBank === 0 ? false : true}
                                    onChange={(e) =>
                                        setNewSettings({
                                            ...newSettings,
                                            privatBank: newSettings.privatBank === 0 ? 1 : 0,
                                            subscribeStatus: 1,
                                        })
                                    }
                                    required
                                    className={classes.notificationChekbox__input}
                                    type="checkbox"
                                    id="private"
                                    name="private"
                                />
                                <label className={classes.notificationChekbox__lable} htmlFor="private">
                                    {t('paymentPrivate')}
                                </label>
                                <div className={classes.notificationImgPrivate}></div>
                                <input
                                    className={classes.input}
                                    onChange={(e) =>
                                        setNewSettings({
                                            ...newSettings,
                                            needPartsPrivatBank: Number(e.target.value),
                                            subscribeStatus: 1,
                                        })
                                    }
                                    type="number"
                                    max="24"
                                    min="0"
                                    value={newSettings.needPartsPrivatBank}
                                ></input>
                            </div>
                            <div className={classes.notificationChekbox}>
                                <input
                                    checked={newSettings.monobank === 0 ? false : true}
                                    onChange={(e) =>
                                        setNewSettings({
                                            ...newSettings,
                                            monobank: newSettings.monobank === 0 ? 1 : 0,
                                            subscribeStatus: 1,
                                        })
                                    }
                                    required
                                    className={classes.notificationChekbox__input}
                                    type="checkbox"
                                    id="mono"
                                    name="mono"
                                />
                                <label className={classes.notificationChekbox__lable} htmlFor="mono">
                                    {t('paymentMono')}
                                </label>
                                <div className={classes.notificationImgMono}></div>
                                <input
                                    onChange={(e) =>
                                        setNewSettings({
                                            ...newSettings,
                                            needPartsMonobank: Number(e.target.value),
                                            subscribeStatus: 1,
                                        })
                                    }
                                    className={classes.input}
                                    type="number"
                                    max="24"
                                    min="0"
                                    value={newSettings.needPartsMonobank}
                                ></input>
                            </div>
                        </div>
                        <div className={classes.notificationPrice}>
                            <div className={classes.notificationPaymentTitle}>{t('price')}</div>
                            <input
                                value={newSettings.changePrice}
                                onChange={(e) =>
                                    setNewSettings({
                                        ...newSettings,
                                        changePrice: Number(e.target.value),
                                        checkPrice: 1,
                                        subscribeStatus: 1,
                                    })
                                }
                                className={classes.input}
                                type="number"
                                max="100"
                                min="0"
                            ></input>
                            <p className={classes.percent}>%</p>
                        </div>
                        <div className={classes.notificationPaymentTitle}>{t('notifications')}</div>
                        <div className={classes.notification_btns}>
                            <div className={classes.notification_check}>
                                <input
                                    checked={newSettings.telegram === 0 ? false : true}
                                    onChange={(e) =>
                                        setNewSettings({
                                            ...newSettings,
                                            telegram: newSettings.telegram === 0 ? 1 : 0,
                                        })
                                    }
                                    className={classes.notificationChekboxSocial__input}
                                    type="checkbox"
                                    id="telegram"
                                    name="telegram"
                                />
                                <label className={classes.notificationChekbox__lable} htmlFor="telegram">
                                    Telegram
                                </label>
                            </div>
                            <div className={classes.notification_check}>
                                <input
                                    checked={newSettings.push === 0 ? false : true}
                                    onChange={(e) => setNewSettings({ ...newSettings, push: newSettings.push === 0 ? 1 : 0 })}
                                    className={classes.notificationChekboxSocial__input}
                                    type="checkbox"
                                    id="push"
                                    name="push"
                                />
                                <label className={classes.notificationChekbox__lable} htmlFor="push">
                                    Push
                                </label>
                            </div>
                        </div>
                        <FillBtn disabled={updateIsLoading} onClick={saveSettings}>
                            {t('paymentBtn')}
                        </FillBtn>
                    </div>
                </div>
            )}
        </div>
    );
};

export const NotificationSettings = () => {
    const { t } = useTranslation(['notificationSettings', 'ui']);
    const token = useAppSelector((state) => state.auth.user.accessToken);
    const {
        data: subscribeNotificationsList,
        isLoading: isLoadingNotificationsList,
        error: errorSubscribeNotificationsList,
    } = truePriceAPI.useFetchSubscribeNotificationsFullListQuery(token, {
        skip: !token,
    });

    const [renderGoods, setRenderGoods] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [renderIds, setRenderIds] = useState<any>([]);
    useEffect(() => {
        if (subscribeNotificationsList && subscribeNotificationsList?.length > 0) {
            setTotalPages(Math.ceil(subscribeNotificationsList.length / 5));
            const goodsSlice = subscribeNotificationsList.slice((page - 1) * 5, page * 5);
            if (goodsSlice.length > 0) {
                setRenderGoods(goodsSlice);
                const ids = goodsSlice.map((good: ISubscribeNotificationsFullList) => Number(good.goodId.rozetkaId));
                setRenderIds(ids);
            } else if (page > 1) {
                setPage(page - 1);
            } else {
                setRenderIds([]);
                setRenderGoods([]);
            }
        } else if (renderIds.length > 0) {
            setRenderIds([]);
            setRenderGoods([]);
        }
    }, [subscribeNotificationsList, page]);

    const handlePageChange = (page: number) => {
        if (!subscribeNotificationsList) {
            return;
        }
        if (page > 0 && page <= totalPages && subscribeNotificationsList?.length > 0) {
            setPage(page);
            const goodsSlice = subscribeNotificationsList.slice((page - 1) * 5, page * 5);
            setRenderGoods(goodsSlice);
            const ids = goodsSlice.map((good: ISubscribeNotificationsFullList) => Number(good.goodId.rozetkaId));
            setRenderIds(ids);
        } else if (!subscribeNotificationsList || subscribeNotificationsList?.length < 1) {
            setPage(1);
            setRenderIds([]);
            setRenderGoods([]);
        }
    };

    const {
        data: currentPrice,
        isFetching: isFetchingCurrentPrice,
        error: errorCurrentPrice,
    } = truePriceAPI.useFetchDiscountPriceQuery(
        { token, ids: renderIds },
        {
            skip: !token || renderIds.length === 0,
        },
    );

    const [checkedForUnsubsciption, setCheckedForUnsubsciption] = useState<any>([]);
    const toggleUnsubscriptionCheckbox = (id: number) => {
        if (checkedForUnsubsciption.includes(id)) {
            setCheckedForUnsubsciption(checkedForUnsubsciption.filter((item: number) => item !== id));
        } else {
            setCheckedForUnsubsciption([...checkedForUnsubsciption, id]);
        }
    };

    const getCheckedForUnsubsciption = useCallback(
        (id: number) => {
            return checkedForUnsubsciption.includes(id);
        },
        [checkedForUnsubsciption],
    );

    const selecteAllForUnsubscription = () => {
        if (checkedForUnsubsciption.length === renderGoods.length) {
            setCheckedForUnsubsciption([]);
        } else {
            setCheckedForUnsubsciption(renderGoods.map((good: ISubscribeNotificationsFullList) => good.id));
        }
    };
    const dispatch = useAppDispatch();
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);
    const unsubscribe = async () => {
        if (checkedForUnsubsciption.length === 0 || isUnsubscribing) {
            return;
        }
        setIsUnsubscribing(true);
        const result: any = await dispatch(
            truePriceAPI.endpoints.fetchUnsubscribeProducts.initiate({
                token,
                ids: checkedForUnsubsciption,
            }),
        );
        setCheckedForUnsubsciption([]);
        // console.log(result);
        // if (result['data'] === null) {
        //     setPage(1)
        // }
        setIsUnsubscribing(false);
    };

    return (
        <section className={classes.section}>
            <div className={classes.title}>{t('title')}</div>
            {subscribeNotificationsList && currentPrice && renderGoods.length > 0 && <div className={classes.subtitle}>{t('subtitle')}</div>}
            <div className={classes.notificationsWrapper}>
                {isLoadingNotificationsList && renderGoods.length === 0 && <Loader />}
                {subscribeNotificationsList && (
                    <>
                        {currentPrice && renderGoods.length > 0 && (
                            <>
                                {renderGoods.map((item: ISubscribeNotificationsFullList) => (
                                    <NotificationCard
                                        unsubscriptionCheckboxSelected={getCheckedForUnsubsciption(item.id)}
                                        toggleUnsubscriptionCheckbox={toggleUnsubscriptionCheckbox}
                                        key={item.id}
                                        notificationSub={item}
                                        prices={currentPrice}
                                    />
                                ))}

                                <div className={classes.paginationAndRemoveWrapper}>
                                    <div className={classes.packetUnsubscribeButtons}>
                                        <button className={classes.packetUnsubscribeButton} onClick={selecteAllForUnsubscription}>
                                            {t('selectAll')}
                                        </button>
                                        <i className={classes.separator}></i>
                                        <button className={classes.packetUnsubscribeButton} onClick={unsubscribe}>
                                            {t('delete')}
                                        </button>
                                    </div>
                                    {((page === 1 && renderGoods.length === 5) || page > 1) && (
                                        <Pagination totalPages={totalPages} page={page} handlePageChange={handlePageChange} />
                                    )}
                                </div>
                            </>
                        )}

                        {renderGoods?.length === 0 && <div className={classes.noMessages}>{t('noSubscriptions')}</div>}
                    </>
                )}
                {errorSubscribeNotificationsList && 'status' in errorSubscribeNotificationsList && (
                    <div className={classes.noMessages}>
                        {JSON.parse(JSON.stringify(errorSubscribeNotificationsList.data))?.message === 'NOT_SUBSCRIBED' ? t('noSubscription', { ns: 'ui' }) : ''}
                    </div>
                )}
            </div>
        </section>
    );
};

import classes from './news.module.css';
import { Trans, useTranslation } from 'react-i18next';
import FillBtn from '../ui/fill-btn/fill-btn.component';
import ImgNews from './img/any/img.jpg';
import ImgPrice from './img/any/price.png';
import IcoTg from './img/any/telegram.png';
import Edge from './img/any/edge.png';
import Settings from './img/any/settings.png';
import { useEffect } from 'react';

export const News = () => {
    const { t } = useTranslation(['news']);

    return (
        <div className={classes.wrapper}>
            <div className={classes.news}>{t('title')}</div>
            <div className={classes.containerNews}>
                <div className={classes.blockNews}>
                    <a href="https://true-price.top/news/update-5-0-5" itemProp="url">
                        <div className={classes.textNews} itemScope itemType="http://schema.org/NewsArticle">
                            <div className={classes.titleNews}>
                                <a href="https://true-price.top/news/update-5-0-5" itemProp="url">
                                    <span itemProp="headline">Оновлення 5.0.5</span>
                                </a>
                            </div>
                            {/* @ts-ignore */}
                            <div className={classes.dateNews} itemProp="datePublished" content="2024-07-14T00:21:00+08:00">11/07/2024</div>
                            <div className={classes.descriptionNews}>
                                <p itemProp="articleBody">
                                    Ми раді представити оновлення нашого розширення 5.0.5!<br /><br />
                                    Ось короткий список змін:<br />
                                    <ul>
                                        <li>Покращено алгоритм пошуку для більшої кількості пропозицій.</li>
                                        <li>Додано нове поле "Схожість найменувань" з відсотками відповідності.</li>
                                        <li>Додано нове поле "Рейтинг" з клікабельним посиланням на відгуки.</li>
                                        <li>Можливість перетягування та зміни розміру вікна віджета.</li>
                                        <li>Новий дозвіл на маніпуляцію cookies для захисту конфіденційності.</li>
                                        <li>Інші покращення: шкала завантаження, анімація завантаження на іконку, обмеження на кількість пошуків, підтвердження "Я не робот".</li>
                                    </ul>
                                    <br />
                                    Натисніть "Читати повністю", щоб ознайомитися з усіма змінами детальніше.<br /><br />
                                    <div className={classes.alignCenter}>
                                        <FillBtn>Читати повністю</FillBtn>
                                    </div>
                                    <meta itemProp="image" content="/news-assets/5-0-5.webp" />
                                    <div className={classes.imgNews} style={{ width: '100%' }}>
                                        <img src='/news-assets/5-0-5.webp' alt='Оновлення 5.0.5' itemProp="image" />
                                    </div>
                                </p>
                            </div>
                        </div>
                    </a>
                    <a href="https://true-price.top/news/mobile-fix" itemProp="url">
                        <div className={classes.textNews} itemScope itemType="http://schema.org/NewsArticle">
                            <div className={classes.titleNews}>
                                <a href="https://true-price.top/news/mobile-fix" itemProp="url">
                                    <span itemProp="headline">Покращення пошуку та новий розділ на cайті "Перевірити"</span>
                                </a>
                            </div>
                            {/* @ts-ignore */}
                            <div className={classes.dateNews} itemProp="datePublished" content="2024-07-11T00:20:00+08:00">11/07/2024</div>
                            <div className={classes.descriptionNews}>
                                <p itemProp="articleBody">
                                    Вітаємо! Раді повідомити про оновлення для покращення роботи мобільного додатка.<br /><br />
                                    Оновлено алгоритм пошуку та додано підтримку більшої кількості пристроїв для сканування штрих-кодів.<br />
                                    Відкрийте для себе нові можливості без необхідності встановлювати мобільний додаток – просто скористайтесь нашим сайтом.<br /><br />
                                    Читайте далі, щоб дізнатися більше про ці зміни!<br />
                                    <div className={classes.alignCenter}>
                                        <FillBtn>Читати повністю</FillBtn>
                                    </div>
                                </p>
                            </div>
                            <meta itemProp="image" content="/news-assets/new-scan.png" />
                            <div className={classes.imgNews} style={{ width: '100%' }}>
                                <img src='/news-assets/new-scan.png' alt='Новий сканер' itemProp="image" />
                            </div>
                        </div>
                    </a>
                    <a href="https://true-price.top/news/chatgpt" itemProp="url">
                        <div className={classes.textNews} itemScope itemType="http://schema.org/NewsArticle">
                            <div className={classes.titleNews}>
                                <a href="https://true-price.top/news/chatgpt" itemProp="url">
                                    <span itemProp="headline">Новий революційний спосіб пошуку товарів - ChatGPT та True Price</span>
                                </a>
                            </div>
                            {/* @ts-ignore */}
                            <div className={classes.dateNews} itemProp="datePublished" content="2024-07-11T00:00:00+08:00">11/07/2024</div>
                            <div className={classes.descriptionNews}>
                                <p itemProp="articleBody">
                                    Дізнайтеся, як ChatGPT та True Price, безкоштовний онлайн-консультант, допоможуть вам легко підібрати товари, зробити розрахунки та знайти найкращі ціни в Україні.<br />
                                    <div className={classes.alignCenter}>
                                        <FillBtn>Читати повністю</FillBtn>
                                    </div>
                                </p>
                            </div>
                            <meta itemProp="image" content="/news-assets/true-price-chatgpt.webp" />
                            <div className={classes.imgNews} style={{ width: '100%' }}>
                                <img src='/news-assets/true-price-chatgpt.webp' alt='ChatGPT та True Price' itemProp="image" />
                            </div>
                        </div>
                    </a>
                    <div className={classes.textNews}>
                        <div className={classes.titleNews} itemProp="headline">Мобільний додаток та пошук у віджеті</div>
                        {/* @ts-ignore */}
                        <div className={classes.dateNews} itemProp="datePublished" content="2024-01-26T00:00:00+08:00">26/01/2024</div>
                        <div className={classes.descriptionNews}>
                            <p itemProp="articleBody">
                                Нова функція для користувачів браузерного розширення: мобільний додаток для Android<br /><br />
                                Ми раді повідомити вам, що ми створили <a href="https://play.google.com/store/apps/details?id=top.true_price_m.twa">мобільний додаток для Android</a>, який вже доступний в Google Play. Цей додаток дозволить вам швидко і зручно порівнювати ціни на товари в різних магазинах, а також шукати товари за назвою або за штрих-кодом.<br /><br />
                                Якщо ви користуєтеся iPhone, то поки що єдиним варіантом для вас залишається встановити додаток з браузера. В мобільному додатку ми додали пошук за назвою товару, і за штрих-кодом. Якщо сканер штрих-коду доступний на вашому пристрої, то поруч з кнопкою пошуку з'явиться іконка сканера. Наведіть камеру на штрих-код і додаток покаже всі магазини, в яких можна купити цей товар. Тепер мобільний додаток також показує порівняння цін у різних магазинах, як у віджеті розширення.<br /><br />
                                Ми також оновили розширення для браузера. В ньому ми оновили дизайн віджета, додали можливість шукати товари через поле для вводу, і запускати віджет на будь-якій сторінці натисканням на іконку розширення. Ми також покращили алгоритми пошуку, щоб вони знаходили ще більше товарів.<br /><br />
                                Ми сподіваємося, що вам сподобаються наші новинки і вони зроблять ваш онлайн-шопінг ще простішим і вигіднішим. Якщо у вас є якісь питання або пропозиції, будь ласка, пишіть нам на support@true-price.top. Ми будемо раді почути вашу думку.
                            </p>
                        </div>
                        <meta itemProp="image" content="/news-assets/26-01-2024.webp" />
                        <div className={classes.imgNews} style={{ width: '100%' }}>
                            <img src='/news-assets/26-01-2024.webp' alt='Мобільний додаток' />
                        </div>
                    </div>
                    <div className={classes.textNews}>
                        <div className={classes.titleNews} itemProp="headline">Контроль доступу до сайтів</div>
                        {/* @ts-ignore */}
                        <div className={classes.dateNews} itemProp="datePublished" content="2023-08-26T00:00:00+08:00">26/08/2023</div>
                        <div className={classes.descriptionNews}>
                            <p itemProp="articleBody">
                                Розширення за замовчуванням запускається на всіх сайтах, які ви відвідуєте. Однак розширення підтримує й інші режими.<br /><br />
                                У прикладі ви можете бачити як змінити права доступу розширення з <b>"всі сайти"</b> на <b>"коли ви натискаєте розширення"</b>. Цей режим дуже зручний, і ми б його поставили за замовчуванням, але тоді більшість користувачів ніколи б не дізналися про цей віджет.<br /><br />
                                <b>Як це працює.</b><br />
                                Під час відвідування будь-якого сайту True Price запросить доступ до цього сайту, і коли ви натиснете на іконку розширення, це дозволить True Price працювати на цьому сайті. Таким чином ви повністю контролюєте де хочете бачити віджет, а де ні прямо в процесі використання.<br /><br />
                                <b>Ще один режим</b><br />
                                Цей режим дає змогу заздалегідь вказати список сайтів, на яких розширення працюватиме, а на інших ні.
                                Для налаштування списку сайтів натисніть правою кнопкою миші по іконці розширення і оберіть пункт "керувати розширенням", у вікні, що відкрилося, знайдіть пункт "доступ до сайту" і у випадаючому списку оберіть "на певних сайтах", у вікні, що відкрилося, вкажіть, до яких сайтів True Price матиме доступ.
                            </p>
                        </div>
                        <meta itemProp="image" content="/news-assets/2023-08-26.gif" />
                    </div>
                    <div className={classes.textNews}>
                        <div className={classes.titleNews} itemProp="headline">Віджет "Порівняння цін у магазинах"</div>
                        {/* @ts-ignore */}
                        <div className={classes.dateNews} itemProp="datePublished" content="2023-08-25T00:00:00+08:00">25/08/2023</div>
                        <div className={classes.descriptionNews}>
                            <p itemProp="articleBody">
                                True Price зростає завдяки вашій підтримці, і сьогодні ми перейменували розширення на <b>"True Price - помічник у магазинах України".</b> Дякуємо що долучаєтеся та купуєте підписку, це допомагає нам розвиватися.<br /><br />
                                При відвідуванні онлайн-магазинів, цей інноваційний інструмент автоматично шукає схожі товари в інших магазинах і відображає найнижчу ціну на товар. Більше не потрібно порівнювати ціни вручну, адже це зробить новий віджет True Price.<br /><br />
                                А ще віджет доступний користувачам без підписки, але під час переходу в магазин, який знайшов віджет, користувачі без підписки чекатимуть невеликий час, це потрібно, щоб віджет залишався доступним усім абсолютно безкоштовно.<br /><br /><b>Важливо!</b><br />У зв'язку з тим що розширення починає працювати у всіх інтернет-магазинах, йому потрібен доступ і до інших магазинів. Оскільки ми намагаємося охопити більшість магазинів за допомогою універсального алгоритму пошуку назв товарів, ми не можемо вказати до яких конкретно сайтів потрібен доступ, тому розширення запитує доступ до всіх сайтів. Ви можете відключити розширення на будь-якому сайті самі, або повністю відключити віджет.<br /><br />
                                Оскільки функція нова, в ній можливі помилки, наприклад спроба знайти товар на сторінці на якій немає товарів, або може помилково знайти не той товар. Ми будемо працювати над поліпшенням надійності функції.<br /><br />
                                Тепер детальніше про те, як саме працює нова функція. При відвідуванні будь-якого сайту, розширення шукає за meta тегами (це спеціальні описові теги вмісту сторінки) назву товару. За цими ж тегами пошуковики такі як google визначають вміст сторінки.<br />
                                Далі розширення відправляє запит до нас на сервер, і наш сервер шукає цей товар у більш ніж 150 магазинах. Якщо товар знайдено, ви бачите список магазинів і ціну в кожному.<br /><br />
                                <b>Безпека.</b><br />
                                Розширення має відкритий код, і конкретно це оновлення пройшло детальну перевірку фахівцями з google перед публікацією в магазин. Кожен користувач може бачити яку інформацію зберігає розширення і куди її відправляє. Тому ви можете не турбуватися про збір вашої особистої інформації. А ще нагадуємо що віджет і доступ до сайту ви можете обмежити за вашим бажанням.<br /><br />
                                <b>Що далі?</b><br />
                                Ми б дуже хотіли зробити оновлення True Price до 5 версії, але на жаль останні 2 роки розширення стабільно працює в мінус. Ми покриваємо витрати зі своїх особистих коштів і віримо, що колись про наше розширення дізнається більше людей, і ми зможемо почати розробку складнішого і не менш корисного функціоналу. Розповідайте друзям, купуйте підписку, ну або просто залиште відгук у магазині. Спасибі!
                            </p>
                        </div>
                        <meta itemProp="image" content="/news-assets/2023-08-25.png" />
                        <div className={classes.imgNews} style={{ width: '100%' }}>
                            <img src='/news-assets/2023-08-25.png' alt='Віджет "Порівняння цін у магазинах"' />
                        </div>
                    </div>
                    <div className={classes.textNews}>
                        <div className={classes.titleNews} itemProp="headline">Значок "реальна знижка"</div>
                        {/* @ts-ignore */}
                        <div className={classes.dateNews} itemProp="datePublished" content="2023-01-27T00:00:00+08:00">27/01/2023</div>
                        <div className={classes.descriptionNews}>
                            <p itemProp="articleBody">
                                <b>Хочемо нагадати про одну з найкорисніших функцій True Price.</b>
                                <br />Це значок реальної знижки на картці товару. Значок відображає, наскільки змінилася ціна за останні 3 місяці щодо найнижчого значення.
                                <br />З ним легше і швидше знаходити вигідні пропозиції, особливо під час акцій.
                                <br />Подивіться скріншот, та оцініть, наскільки зручно серед усіх товарів знайти справжню знижку (15%).<br />Якщо Вам сподобалася ця функція, підтримайте проект придбавши підписку та отримайте доступ до неї.
                            </p>
                        </div>
                        <meta itemProp="image" content="/news-assets/27-01-2023.webp" />
                        <div className={classes.imgNews} style={{ width: '100%' }}>
                            <img src='/news-assets/27-01-2023.webp' alt='Значок "реальна знижка"' />
                        </div>
                    </div>
                    <div className={classes.textNews}>
                        <div className={classes.titleNews}>Edge</div>
                        <div className={classes.dateNews}>28/12/2022</div>
                        <div className={classes.descriptionNews}>
                            <p>
                                <span>👋 З наступаючим Новим роком!</span> <br />
                                У нас багато нових користувачів, дякуюємо, що приєдналися! Але частина користувачів змушена використовувати обмежену версію True Price, оскільки
                                використовують його в Microsoft Edge браузері. Сьогодні ми зробили для таких користувачів подарунок, тепер розширення є в магазині надбудов для
                                Edge. Ті, хто вже встановив його з магазину Chrome у свій Edge, можуть тепер розкрити весь функціонал розширення за допомогою функції авторизації.
                                <br />
                                <span>Як працює оновлена функція авторизації?</span> <br />
                                При відвідуванні інтернет-магазину rozetka.com.ua розширення True Price запропонує авторизуватись за допомогою Google облікового запису. Якщо
                                користувач відмовиться, розширення більше не запитуватиме, але показуватиме графік ціни (це безкоштовна функція).
                                <br />
                                <span>Важливо!</span> У будь-який момент ви можете змінити обліковий запис авторизації у розширенні, для цього натисніть на іконку розширення, і в
                                вікні з налаштуваннями натисніть кнопку "змінити" навпроти слова Email.
                            </p>
                        </div>
                        <div className={[classes.imgNews, classes.flex].join(' ')}>
                            <img className={classes.image1} src={Edge} alt="" />
                            <img src={Settings} alt="" />
                        </div>
                    </div>
                    <div className={classes.textNews}>
                        <div className={classes.titleNews}>Старі ціни повернулись!</div>
                        <div className={classes.dateNews}>09/12/2022</div>
                        <div className={classes.descriptionNews}>
                            <p>
                                <span>Всім привіт 👋</span> <br />
                                Ви просили, ми зробили! Усі старі ціни повернулися на графік ціни 🥳
                                <br />
                                Так як раніше розширення працювало тільки з товарами від розетки, ціни на товари від інших продавців відображаються з 23.11.2022р
                            </p>
                        </div>
                        <div className={classes.imgNews}>
                            <img src={ImgPrice} alt="" />
                        </div>
                    </div>
                    <div className={classes.textNews}>
                        <div className={classes.titleNews}>TruePrice оновлено до версії 4.0</div>
                        <div className={classes.dateNews}>25/11/2022</div>
                        <div className={classes.telegram}>
                            <div className={classes.icoTelegram}>
                                <a href="https://t.me/true_price_ua">
                                    <img src={IcoTg} alt="telegram" />
                                </a>
                            </div>
                            <div className={classes.telegramLink}>
                                <div className={classes.telegramLink}>
                                    <a href="https://t.me/true_price_ua">канал новин</a>
                                </div>
                                <div className={classes.telegramLink}>
                                    <a href="https://t.me/true_price_chat">чат для спілкування</a>
                                </div>
                            </div>
                        </div>
                        <div className={classes.descriptionNews}>
                            <p>
                                <span>Друзі, привіт!</span> <br />
                                Скоро новорічні свята, а це означає, що всі активно купуватимуть подарунки, тому ми вирішили випустити нову версію True Price😊 З новою версією,
                                робити покупки буде не просто вигідно, а ще й швидко та зручно, адже ми додали багато нових функцій та покращили старі.
                            </p>{' '}
                            <br />
                            <span>Що змінилося?</span> <br />
                            <span style={{ marginLeft: '3%' }}>— З True Price</span> тепер можна відстежувати товари не лише від інтернет-магазину розетка, а й від інших продавців{' '}
                            <br />
                            <span style={{ marginLeft: '3%' }}>— “Вигідна пропозиція”</span> - тепер замість шильдика, на картці товару відображатиметься відсоток знижки або
                            націнки на товар, якщо ж ціна не змінювалася, відображатиметься 0% <br />
                            <span style={{ marginLeft: '3%' }}>— Налаштування</span> тепер можна міняти просто натиснувши на іконку розширення у верхньому правому куті вашого
                            браузера <br />
                            <br />
                            <span>Нові можливості:</span> <br />
                            <span style={{ marginLeft: '3%' }}>— “Сортування за реальною знижкою”</span> - натиснувши на кнопку, товари відсортуються від найбільшої реальної знижки
                            до найменшої <br />
                            <span style={{ marginLeft: '3%' }}>— “Поліпшене меню”</span> - тепер фільтрація товарів стала набагато зручнішою, тільки після вибору всіх фільтрів, ви
                            застосовуєте їх <br />
                            <span style={{ marginLeft: '3%' }}>— “Сповіщення”</span> - це можливість відстежувати знижки та кредитні пропозиції на товари, що вас цікавлять. Просто
                            виберіть товар, натисніть на кнопку <span>“Відстежувати”</span> або на дзвіночок у картці товару, налаштуйте необхідні параметри і натисніть кнопку{' '}
                            <span>Зберегти</span>, готово, як тільки один з параметрів зміниться, вам прийде повідомлення. <br />
                            Повідомлення можуть надходити до <b>телеграм</b> <i>(підключення телеграма знаходиться в особистому кабінеті)</i> <b>пуш</b>{' '}
                            <i>(на комп'ютер чи ноутбук, а також на телефон)</i>
                        </div>
                    </div>
                    <div className={classes.titleNews}>Чинні обмеженняя:</div>
                    <div className={classes.descriptionNews}>
                        <div style={{ marginLeft: '3%' }}>
                            {' '}
                            — Покращене меню працює лише у категоріях. Наприклад, якщо ви використовуєте пошук, покращене меню працювати не буде.
                        </div>{' '}
                        <div style={{ marginLeft: '3%' }}> — Покращена картка товару може не працювати, якщо ви перебуваєте поза категорією.</div>
                    </div>
                    <div className={classes.imgNews}>
                        <img src={ImgNews} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

import { FC, Suspense } from 'react'
import { Menu } from '../../features/admin';
import classes from './admin.module.css'

interface AdminProps {
    children?: React.ReactNode;
}

export const AdminPage: FC<AdminProps> = ({ children }) => {
    return (
        <div className={classes.adminContainer}>
            <div className={classes.menuWrapper}>
                <Menu />
            </div>
            <div className={classes.pageContent}>
                <Suspense fallback={<div>Loading...</div>}>
                    {children}
                </Suspense>
            </div>
        </div>
    )
}
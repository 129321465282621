// @ts-nocheck
import { useEffect, useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { truePriceAPI } from '../../app/api/true-price-api';
import { FillBtn, Loader } from '../ui';
import { useAppDispatch, useAppSelector } from '../../app/store';
import classes from './redirect.module.css'

export const Redirect = () => {
    let params: any = useParams();
    const { t } = useTranslation(['redirect']);
    const [trigger, result, lastPromiseInfo] = params?.id === 'all' ? truePriceAPI.useLazyFetchRedirectAllQuery() : truePriceAPI.useLazyFetchRedirectQuery();
    const isLoading = result.isFetching;
    const error: any = result.error;
    const resultData = result.data;

    const token = useAppSelector((state) => state.auth.user.accessToken);
    const isSubscribed = useAppSelector((state) => state.auth.user.isSubscribed);
    const isSubscribedExpired = useAppSelector((state) => state.auth.user.isSubscribedExpired);

    const { data: subscriptionData, isFetching: isFetchingSubscriptionData, isLoading: isLoadingSubscriptionData, error: errorSubscriptionData } = truePriceAPI.useFetchCheckSubQuery({ token }, {
        skip: !token,
        pollingInterval: 60000,
    });

    const [time, setTime] = useState(10);
    const determineIfUserIsSubscriber = async () => {
        if (subscriptionData?.status === 1) {
            return true;
        } else {
            return false;
        }
    }
    // window.googlefc = window.googlefc || {};

    // const messageShow = useRef(null);
    // googlefc.controlledMessagingFunction = async (message) => {
    //     // Determine if the user is a subscriber asynchronously.
    //     const isSubscriber = await determineIfUserIsSubscriber();
    //     if (isSubscriber) {
    //         // If the user is a subscriber, don't show any messages.
    //         console.log('cancel message user is subscriber', isSubscriber)
    //         message.proceed(false);
    //     } else {
    //         if (messageShow.current) {
    //             console.log('cancel message show', messageShow.current)
    //             message.proceed(true);
    //             return;
    //         }
    //         // check date last message show
    //         const lastMessageShow = localStorage.getItem('lastMessageShow');
    //         const lastMessageShowDate = new Date(lastMessageShow);
    //         const now = new Date();
    //         const diff = now.getTime() - lastMessageShowDate.getTime();
    //         // const diffHours = Math.floor(diff / 1000 / 60 / 60);
    //         const diffMinutes = Math.floor(diff / 1000 / 60);
    //         console.log('diffMinutes', diffMinutes)
    //         if (diffMinutes < 180) {
    //             message.proceed(false);
    //         } else {
    //             localStorage.setItem('lastMessageShow', now.toString());
    //             messageShow.current = message;
    //             message.proceed(true);
    //         }
    //     }
    // }

    useEffect(() => {
        if (params && params.id) {
            trigger({ ...params });
        }
        const interval = setInterval(() => {
            setTime((prevTime: number) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    clearInterval(interval);
                    return prevTime;
                }
            });
        }, 1000);
        // adblock
        // const scriptAd = document.createElement("script");
        // scriptAd.src = "https://fundingchoicesmessages.google.com/i/pub-2782602503141410?ers=1";
        // scriptAd.nonce = "ViWEs7nh1zBlwPwJkO9_Ww";
        // scriptAd.async = true;
        // document.body.appendChild(scriptAd);
        // signalGooglefcPresent()

        // const script = document.createElement("script");
        // script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2782602503141410";
        // script.crossOrigin = "anonymous";
        // script.async = true;
        // document.body.appendChild(script);
        // window.adsbygoogle = window?.adsbygoogle ? window?.adsbygoogle : []
        // window.adsbygoogle.push({});

        return () => clearInterval(interval);
    }, [params])

    useEffect(() => {
        if (resultData) {
            // get lost time and set tiemout to reriect
            setTimeout(() => {
                if (resultData?.link) {
                    window.location.href = resultData.link;
                }
            }, time * 1000);
        }
    }, [resultData])

    useEffect(() => {
        if (resultData?.link) {
            window.location.href = resultData.link;
        }
    }, [resultData])

    // const signalGooglefcPresent = () => {
    //     if (!window.frames['googlefcPresent']) {
    //         if (document.body) {
    //             const iframe = document.createElement('iframe');
    //             iframe.style = 'width: 0; height: 0; border: none; z-index: -1000; left: -1000px; top: -1000px;';
    //             iframe.style.display = 'none';
    //             iframe.name = 'googlefcPresent';
    //             document.body.appendChild(iframe);
    //         } else {
    //             setTimeout(signalGooglefcPresent, 0);
    //         }
    //     }
    // };

    return (
        <section className={classes.section}>
            <div className={classes.title}>{t('title')}</div>
            {/* <div className={classes.subTitle}>
                <Trans i18nKey={t('subtitle1')} components={{ strong: <strong></strong> }}></Trans></div> */}
            {/* <div className={classes.subTitle}>{t('subtitle')} {time}</div> */}
            <div className={classes.content}>
                {isLoading && <Loader />}
                {(resultData) && (
                    <a href={resultData.link}>
                        <FillBtn>{t('btn')}</FillBtn>
                    </a>
                )
                }
                {error && <div className={classes.error}>{t('unknownError')}</div>}
            </div>
            {/* <div style={{ width: '100%', height: '100%' }} className={classes.imgContainer}>
                <ins className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-format="autorelaxed"
                    data-ad-client="ca-pub-2782602503141410"
                    data-ad-slot="9663142809"></ins>
                <div className={classes.imgText}>{t('imgText')}</div>
                <img src='/odi.webp' height="500"></img>
            </div> */}
        </section>
    )
}

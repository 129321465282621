import { useState } from 'react';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { useAppSelector } from '../../../app/store';
import { ChartDate, SelectedDate } from '../../ui/chart-date/chart-date.component';
import classes from './func.module.css'

export const Func = () => {
    const token = useAppSelector(state => state.auth.user.accessToken);

    // menu
    const [selectedDateMenu, setSelectedDateMenu] = useState<SelectedDate>([false, false]);
    const changeSelectedDateMenu = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateMenu([start, end]);
    }

    const { data: menuData, isLoading: isLoadingMenu, error: errorMenu } = truePriceAPI.useFetchCountMenuQuery({ token, start: selectedDateMenu[0], end: selectedDateMenu[1] }, {
        skip: !token,
    });

    // chart
    const [selectedDateChart, setSelectedDateChart] = useState<SelectedDate>([false, false]);
    const changeSelectedDateChart = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateChart([start, end]);
    }

    const { data: chartData } = truePriceAPI.useFetchCountChartQuery({ token, start: selectedDateChart[0], end: selectedDateChart[1] }, {
        skip: !token,
    });

    // sort
    const [selectedDateSort, setSelectedDateSort] = useState<SelectedDate>([false, false]);
    const changeSelectedDateSort = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateSort([start, end]);
    }

    const { data: dataSort } = truePriceAPI.useFetchCountSortQuery({ token, start: selectedDateSort[0], end: selectedDateSort[1] }, {
        skip: !token,
    });

    // Discount
    const [selectedDateDiscount, setSelectedDateDiscount] = useState<SelectedDate>([false, false]);
    const changeSelectedDateDiscount = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateDiscount([start, end]);
    }

    const { data: dataDiscount } = truePriceAPI.useFetchCountDiscountQuery({ token, start: selectedDateDiscount[0], end: selectedDateDiscount[1] }, {
        skip: !token,
    });


    return (
        <section className={classes.section}>
            <h1>Функции</h1>

            <div className={classes.wrapper}>
                <div className={classes.title}>Меню</div>
                {menuData && menuData !== undefined && (
                    <ChartDate chartData={menuData} changeSelectedDate={changeSelectedDateMenu} labelName='Использований' />
                )}
            </div>

            <div className={classes.wrapper}>
                <div className={classes.title}>График цены</div>
                {chartData && (
                    <ChartDate chartData={chartData} changeSelectedDate={changeSelectedDateChart} labelName='Использований' />
                )}
            </div>

            <div className={classes.wrapper}>
                <div className={classes.title}>Сортировка по реальной скидке</div>
                {dataSort && (
                    <ChartDate chartData={dataSort} changeSelectedDate={changeSelectedDateSort} labelName='Использований' />
                )}
            </div>

            <div className={classes.wrapper}>
                <div className={classes.title}>Скидка на карточке товара</div>
                {dataDiscount && (
                    <ChartDate chartData={dataDiscount} changeSelectedDate={changeSelectedDateDiscount} labelName='Использований' />
                )}
            </div>

        </section>
    )
}

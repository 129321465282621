import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { Barcode, BarcodeCapture, BarcodeCaptureListener, BarcodeCaptureSession } from "scandit-web-datacapture-barcode";

import { useSDK } from "./sdk";

export const ScannerComponent : React.FC<any> = ({ updateSearchValue, showVideo }) => {
  const host = useRef<HTMLDivElement | null>(null);
  const { loaded, sdk } = useSDK();
  const [barcode, setBarcode] = useState<Barcode | null>(null);
  const navigate = useNavigate();


  const onScan = useMemo<BarcodeCaptureListener>(
    () => ({
      didScan: async (_: BarcodeCapture, session: BarcodeCaptureSession) => {
        if (session.newlyRecognizedBarcodes.length > 0) {
          await sdk.enableScanning(false);
          await sdk.enableCamera(false);
          setBarcode(session.newlyRecognizedBarcodes[0]);
          updateSearchValue(session.newlyRecognizedBarcodes[0].data);
        }
      },
    }),
    [setBarcode, sdk, navigate]
  );

  useEffect(() => {
    if (loaded && host.current && showVideo) {
      sdk.connectToElement(host.current);
      sdk.enableCamera(true);
      sdk.enableScanning(true);
      sdk.addBarcodeCaptureListener(onScan);
    }
    return () => {
      if (loaded) {
        sdk.removeBarcodeCaptureListener(onScan);
        sdk.detachFromElement();
      }
    };
  }, [showVideo]);

  // useEffect(() => {
  //   // async function onMount(): Promise<void> {
  //   //   if (loaded && host.current) {
  //   //     sdk.connectToElement(host.current);
  //   //     await sdk.enableCamera(true);
  //   //     await sdk.enableScanning(true);
  //   //     sdk.addBarcodeCaptureListener(onScan);
  //   //   }
  //   // }

  //   // void onMount();
  //   return () => {
  //     if (loaded) {
  //       sdk.removeBarcodeCaptureListener(onScan);
  //       sdk.detachFromElement();
  //     }
  //   };
  // }, [loaded, sdk, onScan]);

  return <div ref={host} style={{ width: '100%', height: '100%' }} />;
}

import React, { FC } from 'react';
import classes from './vidget.module.css';
import { Trans, useTranslation } from 'react-i18next';
import questionIcon from './question-icon.svg';
import { FillBtn } from '../../ui';
import { Link } from 'react-router-dom';

const Vidget: FC = () => {
    const { t } = useTranslation(['vidget', 'ui']);
    return (
        <section className={classes.section}>
            <div className={classes.header}>{t('header')}</div>
            <div className={classes.greenLine}>
            </div>
            <div className={classes.card}>
                <div className={classes.vidgetIcon}>
                </div>
                <div className={classes.vidgetImg}></div>
            </div>
            <div className={classes.list}>
                <div className={classes.item}>
                    <div className={classes.check}></div>
                    <div className={classes.text}>{t('checkOne')}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.check}></div>
                    <div className={classes.text}>{t('checkTwo')}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.check}></div>
                    <div className={classes.text}>{t('checkThree')}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.check}></div>
                    <div className={classes.text}>{t('checkFour')}</div>
                </div>
            </div>
        </section>
    );
};

export default Vidget;

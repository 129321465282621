import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { logout } from '../../auth/auth.slice';
import classes from './menu.module.css';
import { truePriceAPI } from '../../../app/api/true-price-api';

export const Menu = () => {
    const { t } = useTranslation(['cabinetMenu']);
    const menuItems = [
        { title: t('messages'), path: '/cabinet/messages' },
        { title: t('settings'), path: '/cabinet/settings' },
        { title: t('notificationSettings'), path: '/cabinet/notification-settings' },
        { title: 'admin', path: '/admin/users', admin: true },
    ];
    const [menuItemReactive, setMenuItemReactive] = useState(menuItems);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAdmin = useAppSelector((state) => state.auth.user.isAdmin);
    const [active, setActive] = useState(false);
    const showMenu = () => {
        setActive(!active);
    };

    const isSubscribed = useAppSelector((state) => state.auth.user.isSubscribed);
    const isSubscribedExpired = useAppSelector((state) => state.auth.user.isSubscribedExpired);

    const token = useAppSelector((state) => state.auth.user.accessToken);
    const { data: subscriptionData, isFetching: isFetchingSubscriptionData, isLoading: isLoadingSubscriptionData, error: errorSubscriptionData } = truePriceAPI.useFetchCheckSubQuery({ token }, {
        skip: !token,
        pollingInterval: 60000,
      });
      
    const logoutHandler = () => {
        localStorage.removeItem('token');
        dispatch(logout());
        navigate('/');
    };

    const { pathname } = useLocation();
    const getTitle = useCallback(() => {
        const item = menuItems.find((item) => matchPath(pathname, item.path));
        if (item) {
            return item.title;
        }
        return '';
    }, [pathname]);

    useEffect(() => {
        if (isSubscribed === 1 || isSubscribedExpired === 0) {
            // add to menu { title: t('subscription'), path: '/cabinet/sub' },
            setMenuItemReactive([...menuItems, { title: t('subscription'), path: '/cabinet/sub' }]);
        }
    }, [isSubscribed, isSubscribedExpired]);

    const overlayClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            showMenu();
        }
    };
    return (
        <>
            <div className={[classes.showMenu, active ? classes.active : undefined].join(' ')} onClick={showMenu}>
                <span className={classes.categoryShowText}>{getTitle()}</span>
                <i className={[classes.arrow, classes.down].join(' ')}></i>
            </div>

            <ul className={[classes.menu, active ? classes.active : undefined].join(' ')}>
                {menuItemReactive.map(
                    (item) =>
                        (!item.admin || (item.admin && isAdmin)) && (
                            <li key={item.path} className={[classes.menuItem, item.title === getTitle() ? classes.selected : ''].join(' ')}>
                                <Link to={item.path} onClick={() => setActive(false)}>
                                    {item.title}
                                </Link>
                            </li>
                        ),
                )}
                <li onClick={logoutHandler} className={[classes.menuItem, classes.menuItem_logout].join(" ")}>
                    {t('logout')}
                </li>
            </ul>
            <div className={[classes.overlay, active ? classes.active : undefined].join(' ')} onClick={(e) => overlayClickHandler(e)}></div>
        </>
    );
};

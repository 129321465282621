import classes from './notifications.module.css'
import { Card } from '../../../ui/'
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import leftNavigatorIcon from './img/any/left-navigator-1.svg'

export const Notifications: FC = () => {
    const { t } = useTranslation(['capabilities', 'ui']);
    const { height, width } = useWindowDimensions();
    const isMobile = width < 600;
    const isTablet = (width >= 600 && width < 1024);
    const isDesktop = width >= 1024;
  
    const [step, setStep] = useState(isDesktop ? 0 : 1);
    useEffect(() => {
        if (!isDesktop) {
            return;
        }
        const interval = setInterval(() => {
            setStep((step) => {
                return step === 1 ? 0 : 1;
            });
        }, 4000);
        return () => clearInterval(interval);
    }, [])
    return (
        <section className={classes.cardWithScreenshotContainer}>
            <div className={classes.leftNavigatorIconWrapper1}>
                <img src={leftNavigatorIcon} alt="navigator-line" />
            </div>
            <div className={classes.screenShotWrapper}>
                <div className={[classes.image, step === 0 ? classes.chooseImg : classes.popupImg].join(" ")}></div>
            </div>
            <div className={classes.description}>
                <Card title={t('step1')} subtitle={t('step1_subTitle')} step='01' hovered={step === 0 ? true : false} />
                <Card title={t('step2')} subtitle={t('step2_subTitle')} step='02' hovered={step === 1 ? true : false} />
                <Card title={t('step3')} subtitle={t('step3_subTitle')} step='03' />
            </div>
        </section>
    )
}

import React, { FC } from 'react';
import classes from './install-guide.module.css';
import { useTranslation } from 'react-i18next';
import { Card } from '../../ui';
import forFree from './for-free.svg';
import screenshot from './img/big/screenshot.png';
import { FillBtn } from '../../ui';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Link } from 'react-router-dom';

const InstallGuide: FC = () => {
    const { t } = useTranslation(['installGuide', 'ui']);
    const { width } = useWindowDimensions();
    const isMobile = width < 768;
    const isTablet = width >= 768 && width < 1440;
    const isDesktop = width >= 1440;
    return (
        <section className={classes.section}>
            <h2 className={classes.header}>{t('header')}</h2>
            <p className={classes.subHeader}>{t('subHeader')}</p>
            <div className={classes.content}>
                <div className={classes.description}>
                    <Card title={t('step1')} subtitle={t('step1_subTitle')} step="01" />
                    <Card title={t('step2')} subtitle={t('step2_subTitle')} step="02" />
                    <div className={classes.textFreeAndTag}>
                        <span className={classes.textFree}>{t('textFree')}</span>
                        <div className={classes.tag}></div>
                    </div>
                    {isDesktop && (
                        <div className={classes.installBtnWrapper}>
                            <FillBtn>
                                <Link to="/install" className={classes.link}>
                                    {t('install', { ns: 'ui' })}
                                </Link>
                            </FillBtn>
                        </div>
                    )}
                </div>
                <div className={classes.screenshotWrapper}>
                    <div className={classes.screenshot}></div>
                </div>
            </div>
            {(isTablet || isMobile) && (
                <div className={classes.installBtnWrapper}>
                    <FillBtn>{t('install', { ns: 'ui' })}</FillBtn>
                </div>
            )}
        </section>
    );
};

export default InstallGuide;

import { useAppSelector } from '../store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { authorization } from '../../features/auth/auth.slice';

// import { selectAuth, selectVirificated } from '../../features/auth/authSlice';

// interface proptectProps {
//     children?: React.ReactNode;
// }

export function ProtectAdminRoute({ children }) {
    const admin = useAppSelector(state => state.auth.user.isAdmin);
    let location = useLocation();
    if (!admin) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
}

import React, { FC } from 'react'
import classes from './card.module.css'

interface CardProps {
  children?: React.ReactNode;
  title: string;
  subtitle: string;
  step: string;
  hovered?: boolean
}

const Card: FC<CardProps> = ({ children, title, subtitle, step, hovered = false }) => {
  return (
    <div className={[classes.card, hovered ? classes.hovered : ''].join(" ")}>
      <div className={classes.stepContainer}>
        <div className={classes.step}>{step}</div>
        <div className={classes.line}></div>
        <div className={classes.outlineDot}>
          <div className={classes.dot}>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.subtitle}>
          {subtitle}
        </div>
      </div>
    </div>
  )
}

export default Card
import { ChangeEvent, FC, LegacyRef, useEffect, useRef, useState } from 'react'
import { Description, Search, Category, Goods } from '../../features/discount'
import { truePriceAPI } from '../../app/api/true-price-api'
import classes from './discount.module.css'
import { IDiscount } from '../../app/api/interfaces/discount.interface'
import { useAppSelector } from '../../app/store'
import { Helmet } from 'react-helmet-async'
const DiscountPage: FC = () => {
    // const token = useAppSelector(state => state.auth.user.accessToken);
    const {
        data: categoriesData,
        isLoading: categoriesIsLoading,
        error: categoriesError,
    } = truePriceAPI.useFetchCategoryListQuery(
        {},
    );

    // категории 
    const [selectedCategories, setSelectedCategories] = useState<number[]>([])
    const updateSelectedCategory = (id: number) => {
        if (selectedCategories.includes(id)) {
            setSelectedCategories(selectedCategories.filter(item => item !== id))
        } else {
            setSelectedCategories([...selectedCategories, id])
        }
        setGoods([])
        setPage(0)
    }
    useEffect(() => {
        if (categoriesIsLoading === false && categoriesError === undefined && selectedCategories.length === 0 && categoriesData) {
            setSelectedCategories(categoriesData.map(item => item.id))
        }
    }, [categoriesIsLoading, categoriesData, categoriesError])

    // поиск
    const [searchValue, setSearchValue] = useState<string>('')
    const updateSearchValue = (value: string) => {
        setGoods([])
        setSearchValue(value)
        setPage(0)
    }

    // навигация
    const [page, setPage] = useState<number>(0)
    const needLoadingGoodsNode = useRef(null);

    useEffect(() => {
        if (needLoadingGoodsNode.current) {
            const intersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
                if (entries[0].isIntersecting && !goodsIsLoading && !goodsError && goodsData && goodsData.length > 0) {
                    setPage(page + 1)
                }
            }, { threshold: 0.5, rootMargin: '0px' })
            intersectionObserver.observe(needLoadingGoodsNode.current)
            return () => {
                if (needLoadingGoodsNode.current) {
                    intersectionObserver.unobserve(needLoadingGoodsNode.current)
                }
            }
        }
    }, [needLoadingGoodsNode.current, page])

    // товары
    const [goods, setGoods] = useState<IDiscount[]>([])
    const {
        data: goodsData,
        isFetching: goodsIsLoading,
        error: goodsError,
    } = truePriceAPI.useFetchGetGoodsQuery(
        {
            token: 'token',
            body: {
                category: selectedCategories,
                search: searchValue,
                page: page
            }
        },
        {
            skip: selectedCategories.length === 0
        }
    );

    useEffect(() => {
        if (!goodsIsLoading && !goodsError && goodsData && goodsData.length > 0) {
            setGoods([...goods, ...goodsData])
        }
    }, [goodsIsLoading, goodsData, goodsError])

    return (
        <>
            <Helmet>
                <title>True Price - Знижки</title>
                <link rel="canonical" href="https://true-price.top/discount" />
            </Helmet>
            <Description />
            <Search updateSearchValue={updateSearchValue} />
            <div className={classes.body}>
                <Category updateSelectedCategory={updateSelectedCategory} selectedCategories={selectedCategories} categoriesIsLoading={categoriesIsLoading} categoriesData={categoriesData} />
                <div className={classes.goodsWrapper}>
                    <Goods goodsData={goods} goodsIsLoading={goodsIsLoading} />
                    {(goodsIsLoading === false && !goodsError && goodsData && goodsData.length > 0) &&
                        <div ref={needLoadingGoodsNode} className={classes.needLoadingGoods}></div>
                    }
                </div>
            </div>

        </>
    )
}

export default DiscountPage
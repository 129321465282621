import { FC } from 'react'
import { Menu } from '../../features/cabinet';
import classes from './cabinet.module.css'

interface CabinetProps {
    children?: React.ReactNode;
}

export const CabinetPage: FC<CabinetProps> = ({ children }) => {
    return (
        <div className={classes.cabinetContainer}>
            <div className={classes.menuWrapper}>
                <Menu />
            </div>
            <div className={classes.pageContent}>
                {children}
            </div>
        </div>
    )
}

import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

interface FetchStreamQueryArgs {
    baseUrl: string;
}

interface FetchStreamQueryBody {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    body?: any;
}

const fetchStreamQuery = ({ baseUrl }: FetchStreamQueryArgs): BaseQueryFn<FetchStreamQueryBody, any, FetchBaseQueryError> => 
    async ({ url, method, body }: FetchStreamQueryBody) => {
    const response = await fetch(`${baseUrl}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.body) {
        throw new Error('Response body is null');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let result = '';
    let parsedData: any = {};

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });

        // Split the result by new line and parse each JSON object
        const parts = result.split('\n');
        for (let i = 0; i < parts.length - 1; i++) {
            try {
                const parsedPart = JSON.parse(parts[i]);
                parsedData = { ...parsedData, ...parsedPart };
            } catch (e) {
                console.error('Failed to parse JSON', e);
            }
        }
        result = parts[parts.length - 1]; // Keep the last partial line for the next chunk
    }

    // Parse the remaining part if any
    if (result.trim()) {
        try {
            const parsedPart = JSON.parse(result);
            parsedData = { ...parsedData, ...parsedPart };
        } catch (e) {
            console.error('Failed to parse final JSON', e);
        }
    }

    return { data: parsedData };
};

export default fetchStreamQuery;

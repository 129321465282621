import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import classes from './category.module.css'
import { truePriceAPI } from '../../../app/api/true-price-api'
import { Loader } from '../../ui'
import { IOwnCategoryList } from '../../../app/api/interfaces/category-list.interface';

interface CategoryProps {
    children?: React.ReactNode;
    updateSelectedCategory: (id: number) => void;
    selectedCategories: number[];
    categoriesIsLoading: boolean;
    categoriesData: IOwnCategoryList[] | undefined;
}


export const Category: FC<CategoryProps> = ({ updateSelectedCategory, selectedCategories, categoriesIsLoading, categoriesData }) => {
    const { t } = useTranslation(['discount', 'ui']);
    const [active, setActive] = useState(false);
    const showCategories = () => {
        setActive(!active)
    }

    const overlayClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            setActive(false);
        }
    };
    return (
        <div className={classes.categoryWrapper}>

            <div className={[classes.showCategoryList, active ? classes.active : undefined].join(' ')} onClick={showCategories}>
                <span className={classes.categoryShowText}>{t('categories')}</span>
                <i className={[classes.arrow, classes.down].join(' ')}></i>
            </div>


            <div className={[classes.list, active ? classes.active : undefined].join(' ')}>
                {categoriesIsLoading &&
                    <>
                        <div className={classes.item}>
                            <div className={classes.loader}></div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.loader}></div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.loader}></div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.loader}></div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.loader}></div>
                        </div>
                    </>
                }

                {categoriesData &&
                    categoriesData.map((category) => (
                        <div className={classes.item} key={category.id} onClick={() => updateSelectedCategory(category.id)}>
                            <div className={selectedCategories.includes(category.id) ? classes.minus : classes.plus}></div>
                            <div className={classes.title}>{category.name}</div>
                        </div>
                    ))
                }
            </div>

            <div className={[classes.overlay, active ? classes.active : undefined].join(' ')} onClick={e => overlayClickHandler(e)}>
            </div>

        </div>
    )
}

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMessage } from '../../../app/api/interfaces/messagess.interface';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { Loader, Pagination } from '../../ui';
import { updateNotificationsCount } from '../../../features/auth/auth.slice';
import classes from './messages.module.css'
import type {
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'

const Message: FC<{ message: IMessage }> = ({ message }) => {
    const { t } = useTranslation(['messages', 'ui']);
    const [messageText, setMessageText] = useState('');
    useEffect(() => {
        if (message.changePrice) {
            setMessageText(t('messageChangePrice', { price: message.price, goodName: message.subscribeId.name }));
        } else if (message.changeMonobank || message.changePrivatBank) {
            if (message.changeMonobank && message.changePrivatBank) {
                setMessageText(t('messageChangeCreditAll', { monobank: message.monobank, privatBank: message.privatBank, goodName: message.subscribeId.name }));
            } else if (message.changeMonobank) {
                setMessageText(t('messageChangeMonobank', { monobank: message.monobank, goodName: message.subscribeId.name }));
            } else if (message.changePrivatBank) {
                setMessageText(t('messageChangePrivatBank', { privatBank: message.privatBank, goodName: message.subscribeId.name }));
            }
        }
    }, [message])

    return (
        <div className={classes.message}>
            <div className={classes.iconsContainer}>
                {message.cabinet === false &&
                    <div className={classes.notViewed}></div>
                }
                <i className={classes.notificationIcon}>
                </i>

                <div className={classes.date}>{new Date(message.createdAt).toLocaleDateString("uk")}</div>
            </div>
            <a href={message.link}>
                <div className={classes.messageText} dangerouslySetInnerHTML={{ __html: messageText }} />
            </a>
        </div>
    )
}

export const Messages = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['messages']);
    const token = useAppSelector(state => state.auth.user.accessToken);
    const notificationsCount = useAppSelector(state => state.auth.user.notificationsCount);
    const { data: notificationsList, isFetching: isFetchingNotificationsList, isLoading: isLoadingNotificationsList, error: errorNotificationsList } = truePriceAPI.useFetchNotificationsFullListQuery({ token }, {
        skip: !token,
        pollingInterval: 60000,
    });

    const [renderMessages, setRenderMessages] = useState<any>([]);
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    useEffect(() => {
        if (notificationsList && notificationsList?.length > 0) {
            setTotalPages(Math.ceil(notificationsList.length / 5))
            setRenderMessages(notificationsList.slice(0, 5))
        }
    }, [notificationsList])

    const handlePageChange = (page: number) => {
        if (!notificationsList) {
            return
        }
        if (page > 0 && page <= totalPages && notificationsList?.length > 0) {
            setPage(page)
            setRenderMessages(notificationsList.slice((page - 1) * 5, page * 5))
        }
    }

    useEffect(() => {
        if (renderMessages?.length > 0) {
            const viewved = renderMessages.filter((message: IMessage) => message.cabinet === false)
            if (viewved.length > 0) {
                const messagesVieved = viewved.map((message: IMessage) => { return { id: message.id, cabinet: true } })
                dispatch(truePriceAPI.endpoints.fetchMarkAsViewed.initiate({ token, markViewedDto: messagesVieved }));
                dispatch(updateNotificationsCount(notificationsCount - viewved.length))
            }
        }
    }, [renderMessages])


    return (
        <section className={classes.section}>
            <div className={classes.title}>{t('title')}</div>
            <div className={classes.messsagesContainer}>
                {isLoadingNotificationsList && <Loader />}
                {notificationsList &&
                    <>
                        {renderMessages &&
                            <>
                                {renderMessages.map((message: IMessage) => (
                                    <Message key={message.id} message={message} />
                                ))}
                                {((page === 1 && renderMessages.length === 5) || page > 1) &&
                                    <div className={classes.paginationWrapper}>
                                        <Pagination totalPages={totalPages} page={page} handlePageChange={handlePageChange} />
                                    </div>
                                }
                            </>
                        }
                        {renderMessages?.length === 0 && <div className={classes.noMessages}>{t('noMessages')}</div>}
                    </>
                }
                {
                    (
                        errorNotificationsList &&
                        'status' in errorNotificationsList
                    ) &&
                    <div className={classes.noMessages}>{
                        JSON.parse(JSON.stringify(errorNotificationsList.data))?.message === "NOT_SUBSCRIBED" ? t('noSubscription', { ns: 'ui' }) : ''
                    }</div>
                }
            </div>
        </section>
    )
}

import { FC } from 'react'
import classes from './pagination.module.css'

interface PaginationProps {
    page: number
    totalPages: number
    handlePageChange: (page: number) => void
}

export const Pagination: FC<PaginationProps> = ({ page, totalPages, handlePageChange }) => {
    return (
        <div className={classes.pagination}>
            <button className={[classes.paginationItem, classes.arrow].join(" ")} type='button' disabled={page === 1} onClick={() => handlePageChange(page - 1)}></button>

            {page > 1 &&
                <>
                    <button className={[classes.paginationItem, classes.pageNumber].join(" ")} type='button' onClick={() => handlePageChange(1)}>{1}</button>
                    {page - 2 > 0 &&
                        <button className={[classes.paginationItem, classes.pageNumber].join(" ")} type='button' onClick={() => handlePageChange(page - 1)}>...</button>
                    }
                </>
            }
            <button className={[classes.paginationItem, classes.pageNumber, classes.selected].join(" ")} type='button' disabled>{page}</button>

            {page !== totalPages &&
                <>
                    {page + 2 <= totalPages &&
                        <button className={[classes.paginationItem, classes.pageNumber].join(" ")} type='button' onClick={() => handlePageChange(page + 1)}>...</button>
                    }
                    <button className={[classes.paginationItem, classes.pageNumber].join(" ")} type='button' onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                </>
            }

            <button className={[classes.paginationItem, classes.arrow, classes.arrowRight].join(" ")} type='button' disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>{'>'}</button>
        </div>
    )
}

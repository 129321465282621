import React, { FC, useCallback, useEffect, useRef } from 'react'
import classes from './auth.module.css'
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../app/store';
import { ReactComponent as MySvg } from './img/any/notifications-count.svg';
const Auth: FC = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation('menu');
    const { user, authorized } = useAppSelector(state => state.auth);
    const countNode = useRef<any>(null);

    const notificationsCount = useAppSelector(state => state.auth.user.notificationsCount);

    useEffect(() => {
        if (notificationsCount > 0) {
            countNode.current.textContent = notificationsCount;
        }
    }, [notificationsCount])

    const measuredRef = useCallback((node: SVGSVGElement | null) => {
        if (node !== null) {
            const cur = node.querySelector('text');
            if (cur) {
                countNode.current = cur;
            }
        }
    }, []);



    return (
        <div className={[classes.wrapper].join(" ")}>
            {authorized ? (
                <Link to="/cabinet/messages" className={classes.profileWrapper}>
                    <div className={classes.icon}>
                        <MySvg ref={measuredRef} style={{ height: '100%' }} />
                    </div>
                    <div className={[classes.email].join(" ")}>{user.email}</div>
                </Link>

            ) : (
                <>
                    <Link to="/login" className={classes.login}>{t('login')}</Link>
                    <div className={classes.separator}></div>
                    <Link to="/registration" className={classes.register}>{t('register')}</Link>
                </>
            )
            }
        </div >
    )
}

export default Auth
import classes from './reset.module.css'
import { useTranslation } from 'react-i18next';
import { FillBtn } from '../../ui';

export const Reset = () => {
    const { t } = useTranslation(['auth', "ui"]);
    return (
        <section className={classes.resetContainer}>
            <div className={classes.resetHeader}>{t('reset')}</div>
            <div className={classes.resetSubtitle}>{t('resetSubtitle')}</div>
            <label className={classes.inputWrap} >
                <input className={[classes.input__field, classes.mb].join(' ')} type="text" placeholder=" " />
                <span className={classes.input__label} >{t('email')}</span>
            </label>
            <div className={classes.btnWrapper}>
                <FillBtn>{t("resetBtn")}</FillBtn>
            </div>
        </section>
    )
}

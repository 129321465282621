// import {HeaderComponent} from '@features'
import { HeaderComponent } from './features/header';
import { FooterComponent } from './features/footer';
import './App.css';
import AppRouter from './app/router/app-router';
import { useEffect, useState } from 'react';
import { truePriceAPI } from './app/api/true-price-api';
import { useAppDispatch } from './app/store';
import { authorization } from './features/auth/auth.slice';
import { ToastContainer, toast } from 'react-toastify';
import { TelegramChats } from './features/telegramChats/telegramChats.component';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from './features/ui';

function App() {
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const [trigger, result, lastPromiseInfo] = truePriceAPI.useLazyFetchGetUserInfoQuery();
    const registrationIsLoading = result.isFetching;
    const registrationError: any = result.error;
    const userData = result.data;

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            trigger(token);
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (userData) {
            dispatch(authorization({ ...userData, accessToken: localStorage.getItem('token') }));
            setLoading(false);
        } else if (registrationError) {
            setLoading(false);
        }
    }, [userData, registrationError]);

    return (
        <div className="app">
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }}>
                    <div className='appLoader'>
                        <img src='/circle_logo.png' width='100vw'></img>
                        <div className='logoName'><b>True Price</b> <br /> знає ціни на всі товари</div>
                        <Loader />
                    </div>
                </div>
            ) : (
                <>
                    <HeaderComponent />
                    <div className="container">
                        <AppRouter />
                        <ToastContainer
                            position="bottom-left"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </div>
                    {/* <TelegramChats /> */}
                    <FooterComponent />
                </>
            )}
        </div>
    );
}

export default App;

import classes from './browser.module.css';
import { useTranslation } from 'react-i18next';
import Chrome from './img/any/chrome.svg';
import Edge from './img/any/edge.svg';
import * as MainScreens from '../main/index'

export const Browser = () => {
    const { t } = useTranslation('installPage');
    return (
        <section className={classes.section}>
            <div className={classes.title}>{t('title')}</div>
            <div className={classes.limitSize}>
                <MainScreens.Install />
            {/* <div className={classes.browsers}>
                <div className={classes.chrome}>
                    <a href="https://chrome.google.com/webstore/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-ro/obkfkpbaobcmhjmopldnakdgbiecjbbl?hl=uk">
                        <img src={Chrome} alt="" />
                        <div className={classes.chromeDescr}>Chrome</div>
                    </a>
                </div>
                <div className={classes.edge}>
                    <a href="https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-/cibppbhjepploffdgeblccilmmepkkjm">
                        <img src={Edge} alt="" />
                        <div className={classes.edgeDescr}>Edge</div>
                    </a>
                </div>
            </div>
            <blockquote className={classes.blockquote}>
                <p><b>True Price</b> – це додаток до браузера. Виберіть ваш браузер, щоб перейти на сторінку True Price в магазині вашого браузера.</p>
            </blockquote> */}
            </div>
        </section>
    );
};

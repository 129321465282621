import { truePriceAPI } from '../../../app/api/true-price-api';
import { useAppSelector } from '../../../app/store';
import { FillBtn } from '../../ui'
import classes from './parser.module.css'
export const Parser = () => {
    const token = useAppSelector(state => state.auth.user.accessToken);
    const [trigger, result, lastPromiseInfo] = truePriceAPI.useLazyFetchParsedQuery();
    const dataParsed = result.data;

    return (
        <section className={classes.section}>
            <h1>Парсинг</h1>

            <div className={classes.wrapper}>
                <div>Процесс парсинга: {dataParsed ? dataParsed.parsedPercent : 0}%</div>
                {token &&
                    <FillBtn onClick={() => { trigger({ token }) }}>Обновить</FillBtn>
                }
            </div>
        </section>
    )
}

import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '../store';

// import { selectAuth, selectVirificated } from '../../features/auth/authSlice';

// interface proptectProps {
//     children?: React.ReactNode;
// }

export function ProtectRoute({ children }) {
    const auth = useAppSelector((state) => state.auth.authorized);
    let location = useLocation();
    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    
    return children;
}

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { truePriceAPI } from '../../app/api/true-price-api';
import { useAppDispatch } from '../../app/store';

import { FillBtn } from '../ui';
import classes from './remove.module.css';

export const Remove = () => {
  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();
  const [created, setCreated] = useState(searchParams.get('token'));
  useEffect(() => {
    if (searchParams.has('token')) {
      setCreated(searchParams.get('token'));
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  }, [searchParams])

  useEffect(() => {
    if (created && created.length > 5) {
      dispatch(truePriceAPI.endpoints.fetchExtUninstall.initiate({
        token: created,
      }))
    }
  }, [created])

  const { t } = useTranslation(["remove", "ui"]);

  const menuItems = [
    { title: t("answer1"), id: "1" },
    { title: t("answer2"), id: "2" },
    { title: t("answer3"), id: "3" },
    { title: t("answer4"), id: "4" },
    { title: t("answer5"), id: "5" },
    { title: t("answer6"), id: "6" },
    { title: t("answer7"), id: "7" },
  ];

  const [active, setActive] = useState(false);
  const showMenu = () => {
    setActive(!active);
  };

  const [selected, setSelected] = useState("1");

  const getTitle = useCallback(() => {
    const item = menuItems.find((item) => item.id === selected);
    if (item) {
      return item.title;
    }
    return "";
  }, [selected]);

  const [textareaeValue, setTextareaeValue] = useState("");

  useEffect(() => { }, []);
  const overlayClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      showMenu();
    }
  };
  const [btnDisabled, setBtnDisabled] = useState(false);
  const sendRequest = async () => {
    setBtnDisabled(true);

    await toast.promise(
      dispatch(truePriceAPI.endpoints.fetchFeedbackUninstall.initiate({
        answerId: Number(selected),
        answerText: getTitle(),
        token: created ? created : "",
        answerCustomText: textareaeValue,
      })),
      {
        pending: t("pending", { ns: "ui" }) as string,
        success: t("success", { ns: "ui" }) as string,
        error: t("error", { ns: "ui" }) as string,
      }
    );

    setSelected("1");
    setTextareaeValue("");
    setBtnDisabled(false);
    window.close();
  }

  return (
    <section className={classes.section}>
      <div className={classes.title}>{t('title')}</div>
      <div className={classes.subtitle}>{t('subtitle')}</div>
      <div className={classes.wrapper}>
        <div className={[classes.showMenu, active ? classes.active : undefined].join(' ')} onClick={showMenu}>
          <span className={classes.categoryShowText}>{getTitle()}</span>
          <i className={[classes.arrow, classes.down].join(' ')}></i>
        </div>

        <ul className={[classes.menu, active ? classes.active : undefined].join(' ')}>
          {menuItems.map((item) =>
            <li onClick={(e) => { setSelected(item.id); showMenu(); }} key={item.id} className={[classes.menuItem, item.title === getTitle() ? classes.selected : ''].join(" ")}>
              {item.title}
            </li>
          )}
        </ul>
        <div className={[classes.overlay, active ? classes.active : undefined].join(' ')} onClick={e => overlayClickHandler(e)}>
        </div>
      </div>
      <textarea className={classes.textarea} value={textareaeValue} onChange={(e) => setTextareaeValue(e.target.value)} placeholder={t('placeholder')}></textarea>
      <div className={classes.btn}>
        <FillBtn disabled={btnDisabled} onClick={sendRequest}>{t('btn')}</FillBtn>
      </div>
    </section>
  );
};

import classes from './registration.module.css'
import { useTranslation } from 'react-i18next';
import { FillBtn, Input, Popup, Error } from '../../ui';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { RegistrationDto } from '../../../app/api/dto/registration.dto';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { authorization } from '../auth.slice';
import { useErrors } from '../../../hooks';
import { toast } from 'react-toastify';

export const Registration = () => {
    const { t } = useTranslation(['auth', "ui"]);
    const [modal, setModal] = useState(false);
    const [noErrors, errors, setErrors] = useErrors(['firstName', 'email', 'password', 'otherError'])

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [trigger, result, lastPromiseInfo] = truePriceAPI.useLazyFetchRegistrationGoogleQuery()
    const registrationIsLoading = result.isFetching;
    const registrationError: any = result.error;
    const userData = result.data;
    useEffect(() => {
        if (userData) {
            dispatch(authorization(userData));
            // save token to local storage
            localStorage.setItem('token', userData.accessToken);
            // @ts-ignore
            gtag('event', 'sign_up');
            navigate('/cabinet/messages');
        }
    }, [userData])
    const [toastId, setToastId] = useState<any>(null);
    useEffect(() => {
        if (result.isLoading || result.isFetching) {
          // toast.update(toastId, { render: t("pending", { ns: "ui" }) as String, type: "pending", isLoading: true })
          return;
        } else if (result.isSuccess) {
          toast.update(toastId, { render: t("success", { ns: "ui" }) as String, type: "success", isLoading: false, autoClose: 2000, closeButton: true  })
        } else if (result.isError) {
          toast.dismiss(toastId)
        }
      }, [result])

    useEffect(() => {
        // if (registrationError) {
        //     if (!('data' in registrationError) || !registrationError.data) {
        //         setErrors({
        //             ...noErrors,
        //             otherError: {
        //                 error: true,
        //                 msg: t('unknownError', { ns: 'ui' }),
        //             }
        //         });
        //         return;
        //     }
        //     const messages: any = registrationError.data.message ?? registrationError.data;
        //     const keys = Object.keys(messages);
        //     const newErrors: any = { ...noErrors };
        //     keys.forEach((key: string) => {
        //         if (key === 'statusCode') {
        //             return;
        //         }
        //         newErrors[key] = {
        //             error: true,
        //             msg: t(messages[key]) ?? t('unknownError', { ns: 'ui' }),
        //         }
        //     });
        //     setErrors(newErrors);
        //     return;
        // }
        if (registrationError) {
            //! Сделать вывод ошибки авторизации
        }
    }, [registrationError])

    // const handleSubmit = (event: any) => {
    //     event.preventDefault();
    //     setErrors({ ...noErrors });
    //     const data = new FormData(event.currentTarget);
    //     let user: RegistrationDto = {
    //         email: data.get('email') as string,
    //         password: data.get('password') as string,
    //         firstName: data.get('firstName') as string,
    //     };

    //     trigger(user);
    //     // validate
    //     // checkUserAlready
    //     // if (findUserByEmail(user.email)) {
    //     //     setErrors({ email: { error: true, msg: t('incorrectregister') } });
    //     //     return;
    //     // }

    //     // // генерирует уникальный идентификатор
    //     // user.id = uuid();
    //     // // сохраняет пользователя и настройки в localstore
    //     // saveUser(user);
    //     // saveSettings({ ...defaultSettings, userId: user.id });
    //     // saveFavorites({ ...defaultFavorites, userId: user.id });
    //     // // добавляет в глобальный стор пользователя и настройки
    //     // dispatch(authorization(user));
    //     // dispatch(inicializeSettings({ userId: user.id }));
    //     // dispatch(inicializeFavorites({ userId: user.id }));
    //     // // создает сессию в localstore
    //     // createSession(user.id);
    //     // // перенаправляет на главную страницу
    //     // navigate('/');
    // };

    function handleCredentialResponse(response: any) {
        // console.log(response);
        if (response.credential) {
            setToastId(toast.loading(t("pending", { ns: "ui" }) as string));
            trigger({ credential: response.credential });
        } else {
            //! Вывести ошибку
        }
    }

    const signUpGoogleBtn = useRef(null);

    // функция которая обрабатывает ввод пароля в гугл авторизации
    const nativeCallback = () => {
        // console.log('native callback');
    }

    useEffect(() => {
        if (!signUpGoogleBtn.current) {
            return;
        }
        const random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        // @ts-ignore
        google.accounts.id.initialize({
            client_id: "708574902388-9ori26ak75auf9djic94aui56cjb2i1j.apps.googleusercontent.com",
            callback: handleCredentialResponse,
            native_callback: nativeCallback,
            context: 'signup',
            ux_mode: "popup",
            auto_prompt: false,
            nonce: random,
        });
        // @ts-ignore
        google.accounts.id.renderButton(
            signUpGoogleBtn.current,
            {
                type: "standard",
                shape: "rectangular",
                theme: "outline",
                text: "signup_with",
                size: "large",
                locale: "uk",
                logo_alignment: "left"
            }  // customization attributes
        );
        // @ts-ignore
        // google.accounts.id.prompt(); // also display the One Tap dialog

    }, [signUpGoogleBtn])

    return (
        <section className={classes.registrationContainer}>
            <div className={classes.registrationHeader}>{t('registr')}</div>
            <div ref={signUpGoogleBtn}></div>
            {/* <form action="" onSubmit={handleSubmit}>
                <label className={classes.inputWrap} >
                    <Input autocomplete='given-name' minlength={2} maxlength={16} type="text" error={errors.firstName?.error} errorMsg={errors.firstName?.msg} name={'firstName'} labelText={t('name')} />
                </label>
                <label className={classes.inputWrap} >
                    <Input autocomplete='email' type="email" error={errors.email?.error} errorMsg={errors.email?.msg} name={'email'} labelText={t('email')} />
                </label>
                <label className={classes.inputWrap} >
                    <Input autocomplete='current-password' minlength={4} maxlength={16} type="password" error={errors.password?.error} errorMsg={errors.password?.msg} name={'password'} labelText={t('pass')} />
                </label>
                <Error error={errors.otherError?.error} errorMsg={errors.otherError?.msg} />
                <div className={classes.registrationBtns}>
                    <input required className={classes.truePricePopup__input} type="checkbox" id="policy" name="policy" />
                    <label htmlFor="policy">{t('polici')}</label>
                </div>
                <div className={classes.btnWrapper}>
                    <FillBtn disabled={registrationIsLoading}>{t("registerBtn")}</FillBtn>
                </div>
            </form>
            <Popup title={t("title", { ns: 'ui' })} body={t("body", { ns: 'ui' })} visible={modal} setVisible={setModal} /> */}
        </section>
    )
}

import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './catalog.module.css';
import { FindBySlugResult, IParentCategoryResult, IProductResult, ISubCategoryResult, ISuperCategory } from '../../../app/api/interfaces/find-by-slug.interface';
import { Loader } from '../../ui';

interface CatalogProps {
    slugData: FindBySlugResult | undefined;
    isLoading: boolean;
    isFetching: boolean;
}

export const Catalog: FC<CatalogProps> = ({ slugData, isLoading, isFetching }) => {
    const { t, i18n } = useTranslation(['check', 'ui']);
    const language = i18n.language;
    const languagePrefix = language === 'ua' ? 'uk' : 'ru';

    useEffect(() => {
        console.log(slugData);
    }, [slugData]);

    const getCategoryName = (category: { name: string; ruName: string }) => 
        language === 'ua' ? category.name : category.ruName;

    const breadcrumbs = useMemo(() => {
        if (!slugData) return [];

        const crumbs = [
            { name: t('home'), slug: `/check/${languagePrefix}` }
        ];

        if (slugData.isParentCategory && (slugData as IParentCategoryResult).parentCategory) {
            crumbs.push({
                name: getCategoryName((slugData as IParentCategoryResult).parentCategory),
                slug: `/check/${(slugData as IParentCategoryResult).parentCategory.slug}/${languagePrefix}}`
            });
        }

        if (slugData.isSubCategory && (slugData as ISubCategoryResult).subCategory) {
            const { subCategory } = slugData as ISubCategoryResult;
            if (subCategory.parentCategory) {
                const parentCategory = subCategory.parentCategory;
                crumbs.push({
                    name: getCategoryName(parentCategory),
                    slug: `/check/${parentCategory.slug}/${languagePrefix}`
                });
                crumbs.push({
                    name: getCategoryName(subCategory),
                    slug: `/check/${parentCategory.slug}/${subCategory.slug}/${languagePrefix}`
                });
            }
        }

        return crumbs;
    }, [slugData, t, language]);

    const renderLoader = () => (
        <div className={classes.loader}>
            <Loader />
        </div>
    );

    const renderBreadcrumbs = () => (
        <nav aria-label="Breadcrumb" className={classes.breadcrumbsNav}>
            <ol className={classes.breadcrumbs} itemScope itemType="https://schema.org/BreadcrumbList">
                {breadcrumbs.map((crumb, index) => (
                    <li key={index} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                        <a href={crumb.slug} itemProp="item">
                            <span className={classes.breadcrumbsText} itemProp="name">{crumb.name}</span>
                        </a>
                        <meta itemProp="position" content={(index + 1).toString()} />
                        {(index < breadcrumbs.length - 1) && (<span className={classes.greenArrow}>{'>'}</span>)}
                    </li>
                ))}
            </ol>
        </nav>
    );

    const renderCategories = (categories: { slug: string; name: string; ruName: string }[], prefix: string = '') => (
        <section className={classes.subCategories}>
            {categories.map((category, index) => (
                <article key={index} className={classes.subCategory} itemScope itemType="https://schema.org/Category">
                    <a href={`/check/${prefix}${category.slug}/${languagePrefix}`} className={classes.subCategoryLink} itemProp="url">
                        <span itemProp="name">{getCategoryName(category)}</span>
                    </a>
                </article>
            ))}
        </section>
    );

    const renderProducts = (products: { slug: string; productName: string }[], prefix: string) => (
        <section className={classes.products}>
            {products.map((product, index) => (
                <article key={index} className={classes.product} itemScope itemType="https://schema.org/Product">
                    <a href={`/check/${prefix}/${product.slug}/${languagePrefix}`} className={classes.productLink} itemProp="url">
                        <span itemProp="name">{product.productName}</span>
                    </a>
                </article>
            ))}
        </section>
    );

    if (isLoading || isFetching) {
        return renderLoader();
    }

    if (!slugData) {
        return <div>{t('noDataFound')}</div>;
    }

    return (
        <div className={classes.catalogWrapper}>
            {(slugData.isParentCategory || slugData.isSubCategory) && renderBreadcrumbs()}
            {slugData.isSuperCategory && renderCategories((slugData as ISuperCategory).parentCategories)}
            {slugData.isParentCategory && renderCategories((slugData as IParentCategoryResult).subCategories, `${slugData.parentCategory.slug}/`)}
            {slugData.isSubCategory && renderProducts((slugData as ISubCategoryResult).products, `${(slugData as ISubCategoryResult).subCategory.parentCategory.slug}/${(slugData as ISubCategoryResult).subCategory.slug}`)}
        </div>
    );
};

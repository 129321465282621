import { useState } from 'react';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { useAppSelector } from '../../../app/store';
import { ChartDate } from '../../ui';
import { SelectedDate } from '../../ui/chart-date/chart-date.component';
import classes from './notifications.module.css'
export const Notifications = () => {
    const token = useAppSelector(state => state.auth.user.accessToken);

    // Notifications
    const [selectedDateNotifications, setSelectedDateNotifications] = useState<SelectedDate>([false, false]);
    const changeSelectedDateNotifications = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateNotifications([start, end]);
    }

    const { data: dataNotifications } = truePriceAPI.useFetchCountNotificationsQuery({ token, start: selectedDateNotifications[0], end: selectedDateNotifications[1] }, {
        skip: !token,
    });

    // MultiNotifications
    const [selectedDateMultiNotifications, setSelectedDateMultiNotifications] = useState<SelectedDate>([false, false]);
    const changeSelectedDateMultiNotifications = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateMultiNotifications([start, end]);
    }

    const { data: dataMultiNotifications } = truePriceAPI.useFetchCountMultiNotificationsQuery({ token, start: selectedDateMultiNotifications[0], end: selectedDateMultiNotifications[1] }, {
        skip: !token,
    });

    
    // MultiNotificationsTrafffic
    const [selectedDateMultiNotificationsByTraffic, setSelectedDateMultiNotificationsByTraffic] = useState<SelectedDate>([false, false]);
    const changeSelectedDateMultiNotificationsByTraffic = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateMultiNotificationsByTraffic([start, end]);
    }

    const { data: dataMultiNotificationsByTraffic } = truePriceAPI.useFetchCountMultiNotificationsByTrafficQuery({ token, start: selectedDateMultiNotificationsByTraffic[0], end: selectedDateMultiNotificationsByTraffic[1] }, {
        skip: !token,
    });


    return (
        <section className={classes.section}>
            <h1>Уведомления</h1>

            <div className={classes.wrapper}>
                <div className={classes.title}>Сгенерировано уведомлений</div>
                {dataNotifications && (
                    <ChartDate chartData={dataNotifications} changeSelectedDate={changeSelectedDateNotifications} labelName='Количество' />
                )}
            </div>

            <div className={classes.wrapper}>
                <div className={classes.title}>Взаимодействие с уведомлениями</div>
                {dataMultiNotifications && (
                    <ChartDate chartData={dataMultiNotifications} changeSelectedDate={changeSelectedDateMultiNotifications} labelName='Количество' />
                )}
            </div>

            <div className={classes.wrapper}>
                <div className={classes.title}>Открытие уведомления по траффику</div>
                {dataMultiNotificationsByTraffic && (
                    <ChartDate chartData={dataMultiNotificationsByTraffic} changeSelectedDate={changeSelectedDateMultiNotificationsByTraffic} labelName='Количество' />
                )}
            </div>
        </section>
    )
}

import { FC, useEffect, useState } from 'react';
import classes from './chart.module.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../ui';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


interface ChartProps {
    // children?: React.ReactNode;
    // onClick?: () => void;
    chartData: {
        date: string[];
        price: number[];
    };
    chartIsLoading: boolean;
}

let options = {
    legend: {
        display: false,
    },
    plugins: {
        legend: {
            display: false
        }
    },
    elements: {
        point: {
            radius: 5,
            borderWidth: 0,
            hitRadius: 100,
            hoverRadius: 100,
            pointStyle: 'circle',
        },
    },
    hover: {
        mode: 'nearest',
        intersect: false,
    },
    interaction: {
        mode: 'nearest'
    },
    aspectRatio: 3,
    tooltips: {
        position: 'custom',
        mode: 'nearest',
        bodyAlign: 'center',
        titleAlign: 'center',
        caretPadding: 0,
        caretSize: 0,
        displayColors: false,
        backgroundColor: '#fff',
        bodyFontColor: 'rgba(0,0,0,0.87)',
        titleFontColor: 'rgba(0,0,0,0.87)',
        borderColor: 'rgba(0,0,0,0.4)',
        borderWidth: 1,
        intersect: false,
    },
    responsive: true,
};

const Chart: FC<ChartProps> = ({ chartData, chartIsLoading }) => {
    const { t } = useTranslation(['check', 'ui']);
    const [allData, setAllData] = useState<any>({});
    const [curOptions, setCurOptions] = useState<any>({ ...options })
    useEffect(() => {
        if (chartData && typeof chartData.price[0] === "number") {
            let max = Math.round(Math.max.apply(null, chartData.price) * 1.5);
            // console.log(max)
            setAllData({
                labels: chartData.date,
                datasets: [
                    {
                        data: chartData.price,
                        borderColor: "rgb(58, 202, 121)",
                        backgroundColor: "rgba(58, 202, 121, 0.5)",
                        label: t('price'),
                        fill: true,
                        // lineTension: 0,
                        pointHoverBorderColor: 'rgba(0,220,75,1)',
                        pointHoverBackgroundColor: 'rgba(19,21,33,1)',
                        pointHoverRadius: 7,
                        pointHoverBorderWidth: 4,
                        stepped: true,
                    },
                ],
            });
            setCurOptions({
                ...options,
                scales: [
                    {
                        axis: 'y' as 'y',
                        max: max,
                        ticks: {
                            stepSize: Math.round(max / 2)
                        }
                    },
                    {
                        axis: 'x' as 'x',
                        ticks: {
                            maxRotation: 0,
                            autoSkipPadding: 1000,
                            autoSkip: true,
                            display: false
                        },
                        grid: {
                            display: false
                        }
                    },
                ]
            })
        }
    }, [chartData]);

    return (
        <div className={classes.chartWrapper}>
            {chartIsLoading &&
                <div className={classes.loader}>
                    <Loader />
                </div>
            }
            {allData.labels && <Line data={allData} options={curOptions} />}
        </div>
    )
}

export default Chart;
import { FC } from 'react'
import { Background } from '../../features/background/background.component'
import * as MainScreens from '../../features/main/index'
import classes from './main.module.css'
import leftNavigatorIcon from './img/any/left-navigator-1.svg'
import rightNavigatorIcon from './img/any/right-navigator-1.svg'
import rightNavigatorIcon2 from './img/any/right-navigator-2.svg'
import { Helmet } from 'react-helmet-async'
const MainPage: FC = () => {
  return (
    <div className={classes.mainPage}>
      <Helmet>
        <title>True Price - помічник в інтернет-магазинах України</title>
        <link rel="canonical" href="https://true-price.top" />
      </Helmet>
      {/* <Background /> */}
      <MainScreens.MainScreen />
      <MainScreens.Vidget />
      {/* <MainScreens.InstallGuide /> */}
      <MainScreens.Capabilities.SubscribeCost />
      <MainScreens.Capabilities.Notifications />
      {/* <div className={classes.rightNavigatorIconWrapper1}>
        <img src={rightNavigatorIcon} alt="navigator-line" />
      </div> */}
      <MainScreens.Capabilities.UpgradeCard />
      {/* <div className={classes.leftNavigatorIconWrapper1}>
        <img src={leftNavigatorIcon} alt="navigator-line" />
      </div> */}
      <MainScreens.Capabilities.Filter />
      {/* <div className={classes.rightNavigatorIconWrapper2}>
        <img src={rightNavigatorIcon2} className={classes.rightNavigatorIcon2} alt="navigator-line" />
      </div> */}
      {/* <MainScreens.Capabilities.Discount /> */}
      <MainScreens.FAQ />
    </div>
  )
}

export default MainPage
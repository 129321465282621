import React, { FC, useEffect, useRef, useState } from 'react';
import classes from './../main-screen.module.css';
import { useTranslation } from 'react-i18next';
import { FillBtn, OutletBtn } from '../../../ui';
import img from './screenshots.png';
import { truePriceAPI } from '../../../../app/api/true-price-api';
import declOfNum from '../../../../utils/decl-of-num';
import { Slide, SlideshowRef, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import Edge from './../img/any/edge_white.svg';
import Chrome from './../img/any/chrome_white.svg';
import GooglePlay from './../img/any/google_play_white.svg';
import Firefox from './../img/any/firefox_white.png';
import OpenAI from './../img/any/openai-white.svg';
import { Link } from 'react-router-dom';

const InstallBrowsers = [
    {
        name: 'Edge',
        icon: Edge,
        stars: 5,
        reviews: 12,
        link: 'https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-/cibppbhjepploffdgeblccilmmepkkjm',
        reviewsLink: 'https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%B2-%D0%BC/cibppbhjepploffdgeblccilmmepkkjm',
        text: 'install'
    },
    {
        name: 'Chrome',
        icon: Chrome,
        stars: 4.8,
        reviews: 83,
        link: 'https://chrome.google.com/webstore/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-ro/obkfkpbaobcmhjmopldnakdgbiecjbbl',
        reviewsLink: 'https://chromewebstore.google.com/detail/true-price-%D0%BF%D0%BE%D0%BCi%D1%87%D0%BD%D0%B8%D0%BA-%D0%B2-%D0%BC%D0%B0%D0%B3/obkfkpbaobcmhjmopldnakdgbiecjbbl/reviews?hl=uk',
        text: 'install'
    },
    {
        name: 'Google Play',
        icon: GooglePlay,
        stars: 3.9,
        reviews: 7,
        link: 'https://play.google.com/store/apps/details?id=top.true_price_m.twa',
        reviewsLink: 'https://play.google.com/store/apps/details?id=top.true_price_m.twa',
        text: 'install'
    },
    {
        name: 'Mozilla Firefox',
        icon: Firefox,
        stars: 4,
        reviews: 4,
        link: 'https://addons.mozilla.org/ru/firefox/addon/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-rozetka/',
        reviewsLink: 'https://addons.mozilla.org/ru/firefox/addon/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-rozetka/reviews/',
        text: 'install'
    },
    {
        name: 'ChatGPT',
        icon: OpenAI,
        stars: 5,
        reviews: 0,
        link: 'https://chatgpt.com/g/g-frzJMEpma-true-price-pomichnik-v-magazinakh-ukraini',
        reviewsLink: 'https://chatgpt.com/g/g-frzJMEpma-true-price-pomichnik-v-magazinakh-ukraini',
        text: 'open'
    },
]

const Install: FC = () => {
    const { t } = useTranslation(['mainScreen', 'ui']);
    const toDiscountHandler = () => {
        const discountNode = document.querySelector('#discount');
        if (discountNode) {
            discountNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    const { data, isLoading, error } = truePriceAPI.useFetchGetMainStatisticsQuery();
    const slideRef = useRef<SlideshowRef>(null);

    const { height, width } = useWindowDimensions();
    const isMobile = width < 600;
    const isTablet = (width >= 600 && width < 1024);
    const isDesktop = width >= 1024;

    const [browsers, setBrowsers] = useState(InstallBrowsers.map((browser) => {
        return {
            ...browser,
        }
    }));

    const [selectedBrowser, setSelectedBrowser] = useState<any>();
    const updateSelectedBrowser = (name: string) => {
        const browser = browsers.find((browser) => browser.name === name);

        if (browser) {
            setSelectedBrowser(browser);
        }
    };

    useEffect(() => {
        if (browsers.length) {
            updateSelectedBrowser('Chrome');
        }
    }, [browsers]);

    const getStarWidth = (rating: any) => {
        return `${(rating / 5) * 100}%`;
    };

    return (
        <>
            {(browsers && selectedBrowser) &&
                <>
                    <div className={classes.installWrapper}>
                        <div className={classes.installButtons}>
                            {
                                browsers.map((browser) => (
                                    <div className={[classes.installButton, selectedBrowser.name === browser.name ? classes.installButtonActive : undefined].join(' ')} key={browser.name} onClick={() => updateSelectedBrowser(browser.name)}>
                                        {browser.name}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={classes.installAndReviewsLine}>
                        <div className={classes.buttons}>
                            <a href={selectedBrowser.link} target='_blank' className={classes.link}>
                                <button className={classes.installBtnAction}>
                                    {t(selectedBrowser.text, { ns: 'ui' })}
                                    <img src={selectedBrowser.icon} className={classes.installBtnIcon} alt='app market icon'></img>
                                </button>
                            </a>
                        </div>
                        <a href={selectedBrowser.reviewsLink} target='_blank'>
                            <div className={classes.reviewsWrapper}>
                                <div className={classes.reviewsHeadline}>
                                    <div className={classes.stars} aria-label={"Зірочок: " + selectedBrowser.stars} title={"Зірочок:" + selectedBrowser.stars}>
                                        <div className={classes.emptyStarts}>
                                            {[1, 2, 3, 4, 5].map((index) => (
                                                <div className={classes.star} key={index}>
                                                    <svg className={classes.starIcon} width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5489 0.927051C10.8483 0.0057407 12.1517 0.00573959 12.4511 0.92705L14.3064 6.63729C14.4403 7.04931 14.8243 7.32827 15.2575 7.32827H21.2616C22.2303 7.32827 22.6331 8.56789 21.8494 9.13729L16.9919 12.6664C16.6415 12.9211 16.4948 13.3724 16.6287 13.7844L18.484 19.4947C18.7834 20.416 17.7289 21.1821 16.9452 20.6127L12.0878 17.0836C11.7373 16.8289 11.2627 16.8289 10.9122 17.0836L6.0548 20.6127C5.27108 21.1821 4.2166 20.416 4.51596 19.4947L6.37132 13.7844C6.5052 13.3724 6.35854 12.9211 6.00805 12.6664L1.15064 9.13729C0.366923 8.56789 0.769697 7.32827 1.73842 7.32827H7.74252C8.17574 7.32827 8.5597 7.04931 8.69357 6.63729L10.5489 0.927051Z" fill="#CBCBCB" />
                                                    </svg>

                                                    {/* <div className={classes.starFilled} style={{ width: getStarWidth(index, selectedBrowser.stars) }}></div> */}
                                                </div>
                                            ))}
                                        </div>
                                        <div className={classes.fillStarts} style={{ width: getStarWidth(selectedBrowser.stars) }}>
                                            {[1, 2, 3, 4, 5].map((index) => (
                                                <div className={classes.star} key={index}>
                                                    <svg className={classes.starIcon} width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5489 0.927051C10.8483 0.00574064 12.1517 0.00573969 12.4511 0.92705L14.3064 6.63729C14.4403 7.04931 14.8243 7.32827 15.2575 7.32827H21.2616C22.2303 7.32827 22.6331 8.56789 21.8494 9.13729L16.9919 12.6664C16.6415 12.9211 16.4948 13.3724 16.6287 13.7844L18.484 19.4947C18.7834 20.416 17.7289 21.1821 16.9452 20.6127L12.0878 17.0836C11.7373 16.8289 11.2627 16.8289 10.9122 17.0836L6.0548 20.6127C5.27108 21.1821 4.2166 20.416 4.51596 19.4947L6.37132 13.7844C6.5052 13.3724 6.35854 12.9211 6.00805 12.6664L1.15064 9.13729C0.366923 8.56789 0.769696 7.32827 1.73842 7.32827H7.74252C8.17574 7.32827 8.5597 7.04931 8.69357 6.63729L10.5489 0.927051Z" fill="#3ACA79" />
                                                    </svg>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.reviewsCount}>{selectedBrowser.reviews} {t(declOfNum(selectedBrowser.reviews ? selectedBrowser.reviews : 999, ['reviewsOne', 'reviewsTwo', 'reviewsThree']))}</div>
                                </div>
                                <div className={classes.reviewsBottomLine}>{t('reviewsMarket', { ns: 'mainScreen' })} {selectedBrowser.name}</div>
                            </div>
                        </a>
                    </div>
                </>
            }
        </>
    );
};

export default Install;

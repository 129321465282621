import { FillBtn } from '../../ui';
import classes from '../news.module.css';
import { Trans, useTranslation } from 'react-i18next';

export const MobileFixArticle = () => {
    const { t } = useTranslation(['news']);
    return (
        <div className={classes.wrapper}>
            <div className={classes.news}>{t('title')}</div>
            <div className={classes.containerNews}>
                <div className={classes.blockNews}>
                    <div className={classes.textNews} itemScope itemType="http://schema.org/NewsArticle">
                        <meta itemProp="mainEntityOfPage" content="True" />
                        <div className={classes.titleNews}>
                            <span itemProp="headline">Покращення пошуку та новий розділ на cайті "Перевірити"</span>
                        </div>
                        {/* @ts-ignore */}
                        <div className={classes.dateNews} itemProp="datePublished" content="2024-07-11T00:00:00+08:00">11/07/2024</div>
                        <meta itemProp="dateModified" content="2024-07-11T00:00:00+08:00" />
                        <div className={classes.descriptionNews}>
                            <p itemProp="description"><b>Вітаємо!</b> У цій новині ми раді повідомити про значні покращення пошуку на телефонах та новий розділ на нашому сайті.</p>
                            <div itemProp="articleBody">
                                <h3 className={classes.articleHeadlines}>Зручний пошук товарів без браузерного розширення</h3>
                                <p className={classes.articleText}>
                                    Для тих, хто ще не знає, у нас є можливість шукати товари і порівнювати ціни без використання браузерного розширення. Окрім цього, ви можете скористатися функцією сканування штрих-кодів. Якщо ви знаходитесь в офлайн-магазині, просто наведіть камеру телефону на штрих-код, щоб знайти нижчу ціну або перевірити, чи вас не обдурили завищеною ціною. Перейдіть у <a className={classes.link} href="https://true-price.top/check" target='_blank'>новий розділ</a> на сайті або встановіть <a className={classes.link} href='https://play.google.com/store/apps/details?id=top.true_price_m.twa' target='_blank'>мобільний додаток</a> для Android з Google Play та натисніть іконку штрих-коду у вікні введення тексту.
                                </p>
                                <h3 className={classes.articleHeadlines}>Покращення алгоритму пошуку</h3>
                                <p className={classes.articleText}>
                                    Ми значно удосконалили алгоритм пошуку товарів. Тепер результати пошуку на вашому телефоні відповідають тим, що ви бачите у віджеті нашого браузерного розширення.
                                </p>
                                <h3 className={classes.articleHeadlines}>Новий сканер Штрих-кодів</h3>
                                <p className={classes.articleText}>
                                    Ми змінили постачальника програмного забезпечення для сканування штрих-кодів, що дозволило значно збільшити кількість підтримуваних пристроїв. Хоча пошук за штрих-кодами не завжди є найнадійнішим способом знайти потрібний товар, тепер він працює на всіх пристроях і, в парі з покращеним алгоритмом пошуку, частіше знаходить необхідні товари.
                                </p>
                                <h3 className={classes.articleHeadlines}>Новий розділ на сайті "Перевірити"</h3>
                                <p className={classes.articleText}>
                                    Ми додали новий розділ <a className={classes.link} href="https://true-price.top/check" target='_blank'>"Перевірити"</a> на нашому сайті. Цей розділ повторює функціонал мобільного додатка, дозволяючи користувачам використовувати його без встановлення додатка. Просто відкрийте посилання <a className={classes.link} href="https://true-price.top/check" target='_blank'>true-price.top/check</a>. Це зручно для тих, хто не може завантажити додаток з <a className={classes.link} href='https://play.google.com/store/apps/details?id=top.true_price_m.twa' target='_blank'>Google Play</a>.
                                </p>
                                <h3 className={classes.articleHeadlines}>Зворотний зв'язок</h3>
                                <p className={classes.articleText}>
                                    Дякуємо всім, хто чекав цього оновлення та не видаляв наш мобільний додаток. Зараз саме час зайти та протестувати новий розділ. Про будь-які помилки пишіть на електронну пошту support@true-price.top або в Telegram-каналі <a className={classes.link} href='https://t.me/true_price_chat' target='_blank'>true_price_chat</a>.
                                </p>
                                <h3 className={classes.articleHeadlines}>Будьте в курсі останніх новин</h3>
                                <p className={classes.articleText}>
                                    Підписуйтесь на наш Telegram-канал <a className={classes.link} href='https://t.me/true_price_ua' target='_blank'>@true_price_ua</a> або відвідуйте наш сайт <a className={classes.link} href='https://t.me/true_price_ua' target='_blank'>true-price.top/news</a>, щоб бути в курсі всіх останніх новин та оновлень.
                                </p>
                            </div>
                        </div>
                        <meta itemProp="author" content="Вадим Петров" />
                        <div itemProp="publisher" itemScope itemType="http://schema.org/Organization">
                            <meta itemProp="name" content="True Price" />
                            <div itemProp="logo" itemScope itemType="http://schema.org/ImageObject">
                                <meta itemProp="url" content="/video/poster-hor.webp" />
                                <meta itemProp="width" content="600" />
                                <meta itemProp="height" content="60" />
                            </div>
                        </div>
                        <meta itemProp="image" content="/news-assets/new-scan.png" />
                        <div className={classes.imgNews} style={{ width: '100%' }}>
                            <img src='/news-assets/new-scan.png' alt='ChatGPT та True Price' itemProp="image" />
                        </div>
                        <div className={classes.alignCenter} style={{ 'marginTop': '30px' }}>
                            <a href='https://true-price.top/news'><FillBtn>Назад до всіх новин</FillBtn></a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

import { Helmet } from "react-helmet-async";
import { ChatGPT } from "../../../features/news/articles/chatgpt.component";

export const TruePriceChatGPTPage = () => {
  return (
    <>
      <Helmet>
        <title>Новий революційний спосіб пошуку товарів - ChatGPT та True Price</title>
        <link rel="canonical" href="https://true-price.top/news/chatgpt" />
      </Helmet>
      <ChatGPT />
    </>);
};

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/store';
import { logout } from '../../auth/auth.slice';
import classes from './menu.module.css';

export const Menu = () => {
    const { t } = useTranslation(['cabinetMenu']);
    const menuItems = [
        { title: 'Пользователи', path: '/admin/users' },
        { title: 'Функции', path: '/admin/func' },
        { title: 'Отслеживаемые товары', path: '/admin/goods-subscriptions' },
        { title: 'Уведомления', path: '/admin/notifications' },
        { title: 'Парсинг', path: '/admin/parser' },
        { title: 'Кабинет', path: '/cabinet/messages' },
    ];
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const showMenu = () => {
        setActive(!active)
    }

    const logoutHandler = () => {
        localStorage.removeItem('token');
        dispatch(logout());
        navigate('/');
    }

    const { pathname } = useLocation();
    const getTitle = useCallback(() => {
        const item = menuItems.find(item => matchPath(pathname, item.path));
        if (item) {
            return item.title;
        }
        return '';
    }, [pathname]);
    return (
        <>
            <div className={[classes.showMenu, active ? classes.active : undefined].join(' ')} onClick={showMenu}>
                <span className={classes.categoryShowText}>{getTitle()}</span>
                <i className={[classes.arrow, classes.down].join(' ')}></i>
            </div>

            <ul className={[classes.menu, active ? classes.active : undefined].join(' ')}>
                {menuItems.map((item) =>
                    <li key={item.path} className={[classes.menuItem, item.title === getTitle() ? classes.selected : ''].join(" ")}>
                        <Link to={item.path}>{item.title}</Link>
                    </li>)
                }
                <li onClick={logoutHandler} className={classes.menuItem}>{t('logout')}</li>
            </ul>
        </>
    )
}

import { Helmet } from 'react-helmet-async';
import { Policy } from '../../features/policy/policy.component';

export const PolicyPage = () => {
    return (
        <>
            <Helmet>
                <title>True Price - ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</title>
                <link rel="canonical" href="https://true-price.top/policy" />
            </Helmet>
            <Policy />;
        </>);
};

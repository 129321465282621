import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { BarcodeCaptureListener } from "scandit-web-datacapture-barcode";
import {
  BarcodeCapture,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureSettings,
  Symbology,
} from "scandit-web-datacapture-barcode";
import {
  Camera,
  CameraSwitchControl,
  configure,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  LaserlineViewfinder,
  TorchState,
} from "scandit-web-datacapture-core";

export interface SDK {
  initialize: () => Promise<void>;
  cleanup: () => Promise<void>;
  connectToElement: (element: HTMLElement) => void;
  detachFromElement: () => void;
  enableCamera: (enabled: boolean) => Promise<void>;
  enableScanning: (enabled: boolean) => Promise<void>;
  enableSymbology: (symbology: Symbology, enabled: boolean) => Promise<void>;
  addBarcodeCaptureListener: (callback: BarcodeCaptureListener) => void;
  removeBarcodeCaptureListener: (callback: BarcodeCaptureListener) => void;
  getEnabledSymbologies: () => Symbology[] | undefined;
  setDesiredTorchState: (enabled: TorchState) => Promise<void>;
}

export interface SDKWithLoadingStatus {
  loading: boolean;
  loaded: boolean;
  sdk: SDK;
}

export function createSDKFacade(): SDK {
  let context: DataCaptureContext | undefined;
  let view: DataCaptureView | undefined;
  let laserLineViewFinder: LaserlineViewfinder | undefined;
  let settings: BarcodeCaptureSettings | undefined;
  let barcodeCapture: BarcodeCapture | undefined;
  let overlay: BarcodeCaptureOverlay | undefined;
  let host: HTMLElement | undefined;
  let cameraSwitchControl: CameraSwitchControl | undefined;
  let camera: Camera | undefined;
  let creatingContext = false;

  function createHostElementIfNeeded(): HTMLElement {
    if (!host) {
      // ============================================================================================================
      // NOTE:
      // The following is a workaround to keep the scanner working correctly with React.
      // The DataCaptureView requires the host element to remain the same throughout its lifecycle.
      // Unfortunately, between re-renders, React doesn't keep the same nodes alive, but creates new ones each time.
      // This means that, between re-renders, the DataCaptureView might stop rendering overlays, viewfinders etc...
      // To fix this, we connect the DataCaptureView to a hidden element, then append it to a React component.
      // This allows us to keep the node alive, and the DataCaptureView rendering correctly.
      // When mounting the scanner component, we show the hidden node, then hide it when unmounting the <ScannerComponent />.
      // See also the `connectToElement` and `detachFromElement` facade methods for further context.
      // ============================================================================================================
      host = document.createElement("div");
      host.style.display = "none";
      host.style.width = "100%";
      host.style.height = "100%";
      document.body.append(host);
    }
    return host;
  }

  return {
    async initialize() {
      // There is a Scandit sample license key set below here.
      // This license key is enabled for sample evaluation only.
      // If you want to build your own application, get your license key by signing up for a trial at https://ssl.scandit.com/dashboard/sign-up?p=test
      // The library location option represents the location of the wasm file, which will be fetched asynchronously.
      await configure({
        libraryLocation: 'libs',
        licenseKey: "AtI0MRj0PHUuMe47aDRGGnUQRdUcP6zCkzpTGHg1aIH3M1m+xkynwTpJyH3FMdms8EBzRLQGsw7mV7QPaS2l+l5nOWuBA98TEiuA76w6HkDmQSZa5RzFrMIYU/RTXEGCWQJLTiEBHQpkZl0WK0bcAFF9IZj+W/tFwnod6O5Z/Kxwfqa28GjRzXJAoRrkf9FrL1Q4XpRdrVrVfx+c9FId2jBjgecWYRVo2WQg6R5XXJTBadvvTxDGh29q1aXlFr7V93A1pyxhBPt1fk5q7H57PaFoju4UUKyYFG7uPAkXF4k+Q30f8Rf4pu1adlzdY4LVt2dmKxx1ytogZUXSOgkGqlx3UaFjb/yX2HOoZo4Q3K3uOyNur3OCMQVnBphRbbhsYAWCh6xfHiXWV4SU52b90/pHmLcrVhQffXf9XI1hHeH/XI3BtVomKQ9pKQMvZFoUVnZ5j2hUE5RJVpvSn2gKEhldTBwPa4jUKkFysKBgbaHrNPQ7LQwA4AUmV2zcTDnasU9mO7VuDfL7Wyos9W1zZdoZ10CNEMXkmmr9IUISaWcJci1oonF5ixqzOgoHnH4AffuDIe7oSPLIv+TOPeuMhD+HkX424Fl4Tc9ErUYeqSEgcOhieG/K8KSttQNziaEhF8olSUYJLxijgIeJXbWjS+THW7SCaoF1s1HtES8uJkf4HYzkdv7mNrnnIfzjs3zhIYWBByETz3D4czsvbpt15B9ZTv6DkiZGn0VulaQPUPhJeqI88Dp4HvljGxSsQq2aFvhRddHn9KMgwhGkgboczBf00NFjznHPk20zO7+hmmpIV2mXSuSP2QdtuMGvxdE/8a2SInSGVer+K0YaVyjpjXUMyolU9HOYjbO8HLmIbjgAssxQ/bqsKRDPmN8ulmI+p2teNJAQvUGR+d27/eOVEvXfFARuN+Z3y+bjo6dhJVVwJecDICPqS0sZkx1ResPuzA8EDsF43SA5cfOWLsdxZg4qduMmKXaXO2wRkSLvzHfNqxrkccdi3G/DTQQ5UWIWiRflhMEXnm5hwsJ2582Otsxvlrf3GSYtsRBW4ZQe+dGWBK1Ii8TrE4McVg5BEHzYrG2SJSK8ggmCFVBsbDTTCdlXjgQzt8qLIEVC3LxEUEITWofD+UX+s/25jvd7wplKCDcU2RSw6o7Elb7kMC63j9NTA4ktdq27I3WgqTc6JxlSlCgRhotGob0JYsRdEHeyn2FGOYaO",
        moduleLoaders: [barcodeCaptureLoader()],
      });
      context = await DataCaptureContext.create();
      settings = new BarcodeCaptureSettings();
      settings.enableSymbologies([
        Symbology.EAN13UPCA,
        Symbology.EAN8,
        Symbology.UPCE,
        Symbology.QR,
        Symbology.DataMatrix,
        Symbology.Code39,
        Symbology.Code128,
        Symbology.InterleavedTwoOfFive,
      ]);

      view = await DataCaptureView.forContext(context);
      view.connectToElement(createHostElementIfNeeded());

      cameraSwitchControl = new CameraSwitchControl();
      view.addControl(cameraSwitchControl);

      barcodeCapture = await BarcodeCapture.forContext(context, settings);
      await barcodeCapture.setEnabled(false);

      overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(barcodeCapture, view);
      laserLineViewFinder = new LaserlineViewfinder();
      await overlay.setViewfinder(laserLineViewFinder);
      await view.addOverlay(overlay);

      camera = Camera.default;
      await camera.applySettings(BarcodeCapture.recommendedCameraSettings);
      await context.setFrameSource(camera);
    },
    async cleanup() {
      await context?.frameSource?.switchToDesiredState(FrameSourceState.Off);
      await context?.dispose();
      await context?.removeAllModes();
      if (overlay) {
        await overlay.setViewfinder(null);
        await view?.removeOverlay(overlay);
      }
      if (cameraSwitchControl) {
        view?.removeControl(cameraSwitchControl);
        cameraSwitchControl = undefined;
      }
      view?.detachFromElement();
      laserLineViewFinder = undefined;
      barcodeCapture = undefined;
      context = undefined;
      view = undefined;
      settings = undefined;
      camera = undefined;
      host?.remove();
      host = undefined;
    },
    connectToElement(element: HTMLElement) {
      host = createHostElementIfNeeded();
      host.style.display = "block";
      element.append(host);
    },
    detachFromElement() {
      if (host) {
        host.style.display = "none";
        document.body.append(host);
      }
    },
    async enableCamera(enabled: boolean) {
      if (context?.frameSource) {
        await context.frameSource.switchToDesiredState(enabled ? FrameSourceState.On : FrameSourceState.Off);
      }
    },
    async enableScanning(enabled: boolean) {
      await barcodeCapture?.setEnabled(enabled);
    },
    async setDesiredTorchState(enabled: TorchState) {
      await camera?.setDesiredTorchState(enabled);
    },
    async enableSymbology(symbology: Symbology, enabled: boolean) {
      settings!.enableSymbology(symbology, enabled);
      await barcodeCapture?.applySettings(settings!);
    },
    addBarcodeCaptureListener(listener: BarcodeCaptureListener) {
      barcodeCapture?.addListener(listener);
    },
    removeBarcodeCaptureListener(listener: BarcodeCaptureListener) {
      barcodeCapture?.removeListener(listener);
    },
    getEnabledSymbologies() {
      return settings!.enabledSymbologies;
    },
  };
}

export const SDKContext = createContext({
  loaded: false,
  loading: false,
  sdk: null,
} as unknown as SDKWithLoadingStatus);

export interface SDKProviderProps {
  children: ReactNode;
}

export default function SDKProvider({ children }: SDKProviderProps): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const sdk = useMemo(() => createSDKFacade(), []);

  const providerValue = useMemo(() => ({ loading, loaded, sdk }), [loading, loaded, sdk]);

  useEffect(() => {
    async function start(): Promise<void> {
      setLoading(true);
      await sdk.initialize();
      setLoading(false);
      setLoaded(true);
      // // enable the camera on mount to speed up the access
      // await sdk.enableCamera(false);
    }
    void start();
    return () => {
      void sdk.cleanup();
    };
  }, [sdk]);

  return <SDKContext.Provider value={providerValue}>{children}</SDKContext.Provider>;
}

export function useSDK(): SDKWithLoadingStatus {
  const value = useContext(SDKContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value.sdk === null) {
    throw new Error("Sdk facade is null. Did you forget to wrap the component with SDKProvider?");
  }
  return value;
}

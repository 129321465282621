import React, { FC } from 'react';
import classes from './menu.module.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Menu: FC = () => {
    const { t } = useTranslation('menu');
    return (
        <div className={classes.wrapper}>
            <div className={classes.menu}>
                <Link to="/" className={[classes.item, classes.removeDot].join(' ')}>
                    {t('main')}
                </Link>
                <div className={classes.dot}></div>
                <Link to="/news" className={classes.item}>
                    {t('news')}
                </Link>
                {/* <div className={classes.dot}></div>
                <Link to="/discount" className={classes.item}>
                    {t('discounts')}
                </Link> */}
                <div className={classes.dot}></div>
                <Link to="/faq" className={classes.item}>
                    {t('faq')}
                </Link>
                <div className={classes.dot}></div>
                <Link to="/install" className={classes.item}>
                    {t('install')}
                </Link>
            </div>
        </div>
    );
};

export default Menu;

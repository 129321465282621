import { createSlice } from '@reduxjs/toolkit';

export const defaultUser = {
    user: {
        accessToken: '',
        email: '',
        givenName: '',
        familyName: '',
        notificationsCount: 0,
        isAdmin: false,
        isSubscribed: 0,
        isSubscribedExpired: 1
    },
    authorized: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: defaultUser,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        authorization: (state, action) => {
            return { user: { ...action.payload }, authorized: true };
        },
        updateUserData: (state, action) => {
            return { ...state, user: { ...action.payload } };
        },
        updateFirstName: (state, action) => {
            return { ...state, user: { ...state.user, firstName: action.payload } };
        },
        updateNotificationsCount: (state, action) => {
            return { ...state, user: { ...state.user, notificationsCount: action.payload } };
        },
        updateSubscription: (state, action) => {
            return { ...state, user: { ...state.user, isSubscribed: action.payload } };
        },
        logout: (state) => {
            return {
                ...defaultUser,
            };
        },
    },
});

export const { authorization, updateNotificationsCount, logout, updateUserData, updateFirstName, updateSubscription } = authSlice.actions;

export default authSlice.reducer;

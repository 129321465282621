import { Helmet } from 'react-helmet-async';
import { Redirect } from '../features/redirect/'

export const RedirectPage = () => {
    return (
        <>
            <Helmet>
                <title>True Price - Перенаправлення</title>
                <link rel="canonical" href="https://true-price.top/redirect" />
            </Helmet>
            <Redirect />
        </>);
}

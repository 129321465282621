import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './description.module.css'

export const Description = () => {
  const [isShow, setIsShow] = useState(false);
  const { t } = useTranslation(['discount', 'ui']);
  return (
    <section className={classes.section}>
      <div className={classes.title}>{t('title')}<img className={classes.questionIcon} onClick={() => setIsShow(!isShow)}></img></div>
      <div className={classes.description} style={{ display: isShow ? 'block' : 'none' }}>{t('description')}</div>
    </section>
  )
}

import React, { FC } from 'react'
import classes from './outlet-btn.module.css'

interface OutletBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode;
}

const OutletBtn: FC<OutletBtnProps> = ({ children, onClick }) => {
    return (
        <button onClick={onClick} className={classes.btn}><span>{children}</span> <span className={classes.arrow}></span></button>
    )
}
export default OutletBtn
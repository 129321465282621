import React, { FC, useEffect, useRef, useState } from 'react';
import classes from './main-screen.module.css';
import { useTranslation } from 'react-i18next';
import { FillBtn, OutletBtn } from '../../ui';
import img from './screenshots.png';
import { truePriceAPI } from '../../../app/api/true-price-api';
import declOfNum from '../../../utils/decl-of-num';
import { Slide, SlideshowRef, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import Edge from './img/any/edge_white.svg';
import Chrome from './img/any/chrome_white.svg';
import GooglePlay from './img/any/google_play_white.svg';
import { Link } from 'react-router-dom';
import Install from './install/install.component';


const InstallBrowsers = [
    {
        name: 'Edge',
        icon: Edge,
        stars: 5,
        reviews: 12,
        link: 'https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-/cibppbhjepploffdgeblccilmmepkkjm',
        reviewsLink: 'https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%B2-%D0%BC/cibppbhjepploffdgeblccilmmepkkjm'
    },
    {
        name: 'Chrome',
        icon: Chrome,
        stars: 4.7,
        reviews: 83,
        link: 'https://chrome.google.com/webstore/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-ro/obkfkpbaobcmhjmopldnakdgbiecjbbl',
        reviewsLink: 'https://chromewebstore.google.com/detail/true-price-%D0%BF%D0%BE%D0%BCi%D1%87%D0%BD%D0%B8%D0%BA-%D0%B2-%D0%BC%D0%B0%D0%B3/obkfkpbaobcmhjmopldnakdgbiecjbbl/reviews?hl=uk'
    },
    {
        name: 'Google Play',
        icon: GooglePlay,
        stars: 4.2,
        reviews: 6,
        link: 'https://play.google.com/store/apps/details?id=top.true_price_m.twa',
        reviewsLink: 'https://play.google.com/store/apps/details?id=top.true_price_m.twa'
    },
]

const MainScreen: FC = () => {
    const { t } = useTranslation(['mainScreen', 'ui']);
    const toDiscountHandler = () => {
        const discountNode = document.querySelector('#discount');
        if (discountNode) {
            discountNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    const { data, isLoading, error } = truePriceAPI.useFetchGetMainStatisticsQuery();
    const slideRef = useRef<SlideshowRef>(null);

    const { height, width } = useWindowDimensions();
    const isMobile = width < 600;
    const isTablet = (width >= 600 && width < 1024);
    const isDesktop = width >= 1024;

    const [browsers, setBrowsers] = useState(InstallBrowsers.map((browser) => {
        return {
            ...browser,
        }
    }));

    const [selectedBrowser, setSelectedBrowser] = useState<any>();
    const updateSelectedBrowser = (name: string) => {
        const browser = browsers.find((browser) => browser.name === name);

        if (browser) {
            setSelectedBrowser(browser);
        }
    };

    const [videoSize, setVideoSize] = useState(0);
    const videoRef = useRef<any>(null); // создаем ref для видео
    const videoWrapperRef = useRef<any>(null); // создаем ref для обертки видео
    const [initialTopOffset, setInitialTopOffset] = useState(0);

    useEffect(() => {
        // Установить начальное расстояние от видео до верха страницы
        if (videoRef.current) {
            const videoPosition = videoRef.current.getBoundingClientRect().top + window.scrollY;
            setInitialTopOffset(videoPosition);
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY * 2;
            const scrollScale = Math.min(1, scrolled / initialTopOffset);

            // Рассчитываем новый размер, учитывая соотношение сторон 16:9
            const aspectRatio = 16 / 9;
            let newWidth = Math.max(0, window.innerWidth * scrollScale);
            let newHeight = newWidth / aspectRatio;

            // Округляем размеры до ближайшего целого числа
            newWidth = Math.round(newWidth);
            newHeight = Math.round(newHeight);

            // Убедимся, что видео не выходит за рамки видимой области браузера
            if (newHeight > window.innerHeight) {
                newHeight = window.innerHeight;
                newWidth = newHeight * aspectRatio;

                // Округляем после повторного расчета размеров
                newWidth = Math.round(newWidth);
            }

            // Ограничиваем максимальный размер видео
            if (newWidth > 1920) {
                newWidth = 1920;
            }

            setVideoSize(newWidth);
        };


        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [initialTopOffset]);

    useEffect(() => {
        let isCentered = false;
        let lastScroll = window.scrollY;

        const handleScroll = () => {
            if (!isDesktop) {
                return;
            }
            const videoRect = videoWrapperRef.current.getBoundingClientRect();
            const videoTop = videoRect.top;
            const videoHeight = videoRect.height;
            const currentScroll = window.scrollY;
            if (currentScroll < 10) {
                isCentered = false;
            }

            // Активировать центрирование только при прокрутке вниз и если видео не центрировано
            if (currentScroll > lastScroll && !isCentered) {
                // Точка, при которой начнется центрирование
                const triggerPoint = window.innerHeight * 0.5;

                if (videoTop <= triggerPoint) {
                    let scrollOffset = videoTop + currentScroll - (window.innerHeight / 2) + (window.innerHeight / 2);
                    window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
                    isCentered = true;
                }
            }
            // } else if (currentScroll < lastScroll) {
            //     isCentered = false;
            // }

            lastScroll = currentScroll;
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [initialTopOffset]);


    useEffect(() => {
        if (browsers.length) {
            updateSelectedBrowser('Chrome');
        }
    }, [browsers]);

    const getStarWidth = (rating: any) => {
        return `${(rating / 5) * 100}%`;
    };

    const [isVideoPlaying, setIsVideoPlaying] = useState(true);
    const handlePlayPauseClick = () => {
        if (videoRef.current) {
            console.dir(videoRef.current.paused)
            if (videoRef.current.paused || videoRef.current.ended) {
                videoRef.current.play();
                setIsVideoPlaying(true)
            } else {
                videoRef.current.pause();
                setIsVideoPlaying(false)
            }
        }
    };

    const handleFullScreenClick = () => {
        if (videoRef.current) {
            if (!document.fullscreenElement) {
                videoRef.current.requestFullscreen().catch((err: any) => {
                    alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                });
            } else {
                document.exitFullscreen();
            }
        }
    };

    useEffect(() => {
        const handleOrientationChange = () => {
            if (window.screen.orientation.angle === 90 || window.screen.orientation.angle === -90) {
                // Код для обработки горизонтального положения устройства
                videoRef.current && videoRef.current.requestFullscreen();
            } else {
                // Код для вертикального положения
                if (document.fullscreenElement) {
                    document.exitFullscreen();
                }
            }
        };

        window.addEventListener('orientationchange', handleOrientationChange);

        return () => window.removeEventListener('orientationchange', handleOrientationChange);
    }, []);

    const widgetBtnRef = useRef<any>(null);
    const phoneBtnRef = useRef<any>(null);
    const rzBtnRef = useRef<any>(null);
    const widgetDuration = 58; // Продолжительность секции "Виджет"
    const phoneDuration = 50; // Продолжительность секции "Телефон"
    const rzDuration = 47; // Продолжительность секции "Виджет"

    const widgetMobileDuration = 40;
    const phoneMobileDuration = 60;
    const rzMobileDuration = 35;

    const updateButtonStyles = () => {
        const currentTime = videoRef.current.currentTime;
        if (!widgetBtnRef.current || !phoneBtnRef.current || !rzBtnRef.current) {
            return
        }
        let widgetBackgroundPosition = '100% 0';
        let phoneBackgroundPosition = '100% 0';
        let rzBackgroundPosition = '100% 0';

        if (isMobile) {
            if (currentTime <= phoneMobileDuration) {
                // console.log('110')
                let percentFill = 100 - ((currentTime) / phoneMobileDuration) * 100;
                phoneBackgroundPosition = `${percentFill}% 0`;
                widgetBtnRef.current.style.backgroundPosition = widgetBackgroundPosition;
                phoneBtnRef.current.style.backgroundPosition = phoneBackgroundPosition;
                rzBtnRef.current.style.backgroundPosition = rzBackgroundPosition;
            } else if (currentTime >= phoneMobileDuration && currentTime < (phoneMobileDuration + widgetMobileDuration)) {
                // console.log('111')
                let percentFill = 100 - ((currentTime - phoneMobileDuration) / widgetMobileDuration) * 100;
                widgetBackgroundPosition = `${percentFill}% 0`;
                widgetBtnRef.current.style.backgroundPosition = widgetBackgroundPosition;
                phoneBtnRef.current.style.backgroundPosition = `0% 0`;
                rzBtnRef.current.style.backgroundPosition = rzBackgroundPosition;
            } else if (currentTime >= (phoneMobileDuration + widgetMobileDuration) && currentTime <= (phoneMobileDuration + widgetMobileDuration + rzMobileDuration)) {
                // console.log('112')
                let percentFill = 100 - ((currentTime - phoneMobileDuration - widgetMobileDuration) / rzMobileDuration) * 100;
                rzBackgroundPosition = `${percentFill}% 0`;
                widgetBtnRef.current.style.backgroundPosition = `0% 0`;
                phoneBtnRef.current.style.backgroundPosition = `0% 0`;
                rzBtnRef.current.style.backgroundPosition = rzBackgroundPosition;
            } else {
                // console.log('113')
                phoneBackgroundPosition = '100% 0';
                widgetBackgroundPosition = '100% 0';
                rzBackgroundPosition = '100% 0';
                widgetBtnRef.current.style.transition = 'none';
                phoneBtnRef.current.style.transition = 'none';
                rzBtnRef.current.style.transition = 'none';
                widgetBtnRef.current.style.backgroundPosition = widgetBackgroundPosition;
                phoneBtnRef.current.style.backgroundPosition = phoneBackgroundPosition;
                rzBtnRef.current.style.backgroundPosition = rzBackgroundPosition;
                setTimeout(() => {
                    widgetBtnRef.current.style.transition = 'background-position 0.5s linear';
                    phoneBtnRef.current.style.transition = 'background-position 0.5s linear';
                    rzBtnRef.current.style.transition = 'background-position 0.5s linear';
                }, 100);
            }
        } else {
            if (currentTime <= widgetDuration) {
                // console.log('112')
                let percentFill = 100 - ((currentTime) / widgetDuration) * 100;
                widgetBackgroundPosition = `${percentFill}% 0`;
                widgetBtnRef.current.style.backgroundPosition = widgetBackgroundPosition;
                phoneBtnRef.current.style.backgroundPosition = phoneBackgroundPosition;
                rzBtnRef.current.style.backgroundPosition = rzBackgroundPosition;
            } else if (currentTime >= widgetDuration && currentTime < (widgetDuration + phoneDuration)) {
                // console.log('113')
                let percentFill = 100 - ((currentTime - widgetDuration) / phoneDuration) * 100;
                phoneBackgroundPosition = `${percentFill}% 0`;
                widgetBtnRef.current.style.backgroundPosition = `0% 0`;
                phoneBtnRef.current.style.backgroundPosition = phoneBackgroundPosition;
                rzBtnRef.current.style.backgroundPosition = rzBackgroundPosition;
            } else if (currentTime >= (widgetDuration + phoneDuration) && currentTime <= (widgetDuration + phoneDuration + rzDuration)) {
                // console.log('114')
                let percentFill = 100 - ((currentTime - widgetDuration - phoneDuration) / rzDuration) * 100;
                rzBackgroundPosition = `${percentFill}% 0`;
                widgetBtnRef.current.style.backgroundPosition = `0% 0`;
                phoneBtnRef.current.style.backgroundPosition = `0% 0`;
                rzBtnRef.current.style.backgroundPosition = rzBackgroundPosition;
            } else {
                // console.log('115')
                phoneBackgroundPosition = '100% 0';
                widgetBackgroundPosition = '100% 0';
                rzBackgroundPosition = '100% 0';
                widgetBtnRef.current.style.transition = 'none';
                phoneBtnRef.current.style.transition = 'none';
                rzBtnRef.current.style.transition = 'none';
                widgetBtnRef.current.style.backgroundPosition = widgetBackgroundPosition;
                phoneBtnRef.current.style.backgroundPosition = phoneBackgroundPosition;
                rzBtnRef.current.style.backgroundPosition = rzBackgroundPosition;
                setTimeout(() => {
                    widgetBtnRef.current.style.transition = 'background-position 0.5s linear';
                    phoneBtnRef.current.style.transition = 'background-position 0.5s linear';
                    rzBtnRef.current.style.transition = 'background-position 0.5s linear';
                }, 100);

            }
        }
    };

    const rewindVideo = (bytes: number) => {
        const url = !isMobile ? '/video/true-price-horizontal.webm' : '/video/true-price-vertical.webm';
        if (videoRef.current) {
            const headers = new Headers();
            headers.append('Range', `bytes=${bytes}-`);

            fetch(url, { headers })
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    videoRef.current.src = url;
                    videoRef.current.load();
                })
                .catch(error => console.error('Error fetching video:', error));
        }
    };

    useEffect(() => {
        rewindVideo(2139750);
        const videoElement = videoRef.current;
        videoElement.addEventListener('timeupdate', updateButtonStyles);
       
        return () => {
            videoElement.removeEventListener('timeupdate', updateButtonStyles);
        };
    }, []);


    return (
        <section className={classes.section}>
            <div className={classes.header}>{t('makePurchases')}</div>
            <div className={classes.description}>{t('extensionDescription')}</div>
            <Install />
            <div className={classes.imageMaxW}>
                <div className={classes.imageContainer}>
                    <div className={classes.imageWrap} style={{ minWidth: videoSize + 'px', transition: 'min-width 0.5s' }}>
                        <div ref={videoWrapperRef} className={classes.image}>
                            <div className={classes.vvPlaceholder}></div>
                            <div className={classes.vvWrapper}>
                                {videoRef.current &&
                                    <div className={classes.timelineButtons}>
                                        {isMobile ?
                                            <>
                                                <div ref={phoneBtnRef} className={classes.timelineBtn} onClick={() => videoRef.current.currentTime = 12}>
                                                    {t('mobileVideoBtn')}</div>
                                                <div ref={widgetBtnRef} className={classes.timelineBtn} onClick={() => videoRef.current.currentTime = 58}>{t('vidgetVideoBtn')}</div>
                                                <div ref={rzBtnRef} className={classes.timelineBtn} onClick={() => videoRef.current.currentTime = 100}>
                                                    {t('rzVideoBtn')}</div>
                                            </>
                                            :
                                            <>
                                                <div ref={widgetBtnRef} className={classes.timelineBtn} onClick={() => videoRef.current.currentTime = 20}>{t('vidgetVideoBtn')}</div>
                                                <div ref={phoneBtnRef} className={classes.timelineBtn} onClick={() => videoRef.current.currentTime = 58}>
                                                    {t('mobileVideoBtn')}</div>
                                                <div ref={rzBtnRef} className={classes.timelineBtn} onClick={() => videoRef.current.currentTime = 108}>
                                                    {t('rzVideoBtn')}</div>
                                            </>

                                        }
                                    </div>
                                }
                                <video ref={videoRef} poster={"/video/poster-" + (!isMobile ? "hor.webp" : "vertical.webp")} aria-hidden={true} muted={true} playsInline={false} preload="metadata" autoPlay={true} loop={true} aria-label="Video" className={[classes.vvAsset, classes.vvVideo].join(" ")}>
                                    {/* <source src={!isMobile ? '/video/true-price-horizontal.webm' : '/video/true-price-vertical.webm'} type="video/webm"></source> */}
                                </video>
                                {videoRef.current &&
                                    <>
                                        <div className={classes.controlButtonWrapper}>
                                            {!isDesktop ? (
                                                <div className={classes.controlButtonWrapperMobile}>
                                                    <button className={[classes.controlButton, isVideoPlaying ? classes.controlButtonPause : classes.controlButtonPlay].join(" ")} onClick={handlePlayPauseClick}>
                                                        {/* <div className={classes.btnBackground}></div> */}
                                                    </button>
                                                    <button className={classes.fullscreenButton} onClick={handleFullScreenClick}>Fullscreen</button>
                                                </div>
                                            ) : (
                                                <>
                                                    <button className={[classes.controlButton, isVideoPlaying ? classes.controlButtonPause : classes.controlButtonPlay].join(" ")} onClick={handlePlayPauseClick}>
                                                        {/* <div className={classes.btnBackground}></div> */}
                                                    </button>
                                                    <button className={classes.fullscreenButton} onClick={handleFullScreenClick}></button>
                                                </>
                                            )
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={classes.screenshots}>
                {isMobile ? (
                    <iframe
                        width="360"
                        height="200"
                        src="https://www.youtube.com/embed/uKBwZ8zu_vY?autoplay=1&showinfo=0&rel=0"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <a className={classes.linkToYoutube} href="https://youtu.be/uKBwZ8zu_vY" target="_blank">
                        <img src="/head.png" style={{ width: '100%' }} alt="header" />
                        <img src="/demo.gif" alt="demo gif"></img>
                    </a>
                )}
            </div> */}

            {/* <div className={classes.statistics}>
                <div className={classes.container}>
                    <div className={classes.value}>
                        {data ? (Number(data.disocuntsCount) > 100000 ? `${String(data.disocuntsCount).slice(0, -3)}k` : data.disocuntsCount) : 999}
                    </div>
                    <div className={classes.text}>{t(declOfNum(data ? data.disocuntsCount : 999, ['disocuntsOne', 'disocuntsTwo', 'disocuntsThree']))}</div>
                </div>
                <div className={classes.dot}></div>
                <div className={classes.container}>
                    <div className={classes.value}>{data ? data.usersCount : 999}</div>
                    <div className={classes.text}>{t(declOfNum(data ? data.usersCount : 999, ['usersOne', 'usersTwo', 'usersThree']))}</div>
                </div>
                <div className={[classes.dot, classes.bigScreenDot].join(' ')}></div>
                <div className={classes.container}>
                    <div className={classes.value}>{data ? data.subscribedCount : 999}</div>
                    <div className={classes.text}>{t(declOfNum(data ? data.subscribedCount : 999, ['subscribeOne', 'subscribeTwo', 'subscribeThree']))}</div>
                </div>
                <div className={classes.dot}></div>
                <div className={classes.container}>
                    <div className={classes.value}>{data ? data.notificationsCount : 999}</div>
                    <div className={classes.text}>{t(declOfNum(data ? data.notificationsCount : 999, ['notificationsOne', 'notificationsTwo', 'notificationsThree']))}</div>
                </div>
            </div> */}
        </section>
    );
};

export default MainScreen;

import { t, use } from 'i18next';
import { useEffect, useState, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { truePriceAPI } from '../../app/api/true-price-api';
import { streamAPI } from '../../app/api/stream-api';
import { Check, Description, Search, Good } from '../../features/check';
import { Loader } from '../../features/ui';
import classes from './check.module.css';
import { Helmet } from 'react-helmet-async';
import { RozetkaGood } from '../../features/check/rozetkaGood/rozetkaGood.component';
import { IProductAllWithName } from '../../app/api/interfaces/product-all.interface';
import { ICheckGood } from '../../app/api/interfaces/check-good.interface';
import SDKProvider from '../../features/check/search/sdk';
import { AllSiteSearchDto } from '../../app/api/dto/all-site-search.dto';
import { Catalog } from '../../features/check/catalog/catalog.component';
import { IProductDetails, ISourceNone } from '../../app/api/interfaces/all-site-search.interface';

export const CheckPage = () => {
    const { t } = useTranslation(['check', 'ui']);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState('');
    const [showError, setShowError] = useState(false);
    const [requestData, setRequestData] = useState<AllSiteSearchDto>({});
    const [slug, setSlug] = useState<{ slug: string }>({ slug: '' });
    const [fullSearchData, setFullSearchData] = useState<IProductDetails | ISourceNone | undefined>(undefined);

    const updateSearchValue = (value: string) => {
        setSearchValue(value);
        setRequestData({ productName: value });
    };

    const isEmptyObject = (obj: any) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    useEffect(() => {
        const pathname = location.pathname;
        const pathSegments = pathname.split('/').filter(segment => segment);
    
        if (pathSegments.length > 1) {
            // Удаляем последний сегмент (язык)
            const language = pathSegments.pop();
            
            if (pathSegments.length === 2) {
                // URL имеет формат true-price.top/check/some-slug/language
                setSlug({ slug: pathSegments[1] });
            } else if (pathSegments.length > 2) {
                // URL имеет формат true-price.top/check/some/path/segments/language
                setSlug({ slug: pathSegments[pathSegments.length - 1] });
            } else {
                // URL имеет формат true-price.top/check или localhost:3000/check/language, ничего не делаем
                setSlug({ slug: 'all-parent' });
            }
        }
    }, [location.pathname]);    

    const [siteSearchData, setSiteSearchData] = useState({});
    const {
        data: newSiteSearchData,
        isLoading: siteSearchIsLoading,
        error: siteSearchError,
        isFetching: siteSearchIsFetching,
    } = streamAPI.useFetchAllSiteSearchDataQuery(requestData, { skip: isEmptyObject(requestData) });

    useEffect(() => {
        if (newSiteSearchData) {
            setSiteSearchData(prevData => ({
                ...prevData,
                ...newSiteSearchData
            }));
        }
    }, [newSiteSearchData]);

    useEffect(() => {
        console.log('siteSearchData', siteSearchData);
    }, [siteSearchData]);


    const {
        data: slugData,
        isLoading: slugIsLoading,
        error: slugError,
        isFetching: slugIsFetching,
    } = truePriceAPI.useFetchFindBySlugDataQuery(slug, { skip: !slug.slug });

    useEffect(() => {
        if (slugData && slugData.isProduct) {
            setRequestData({ slug: slugData.slug });
        }
    }, [slugData])

    return (
        <>
            {/* <Helmet>
                <title>True Price - Перевірка ціни</title>
                <link rel="canonical" href="https://true-price.top/check" />
            </Helmet> */}
            <Description />
            <SDKProvider>
                <Search updateSearchValue={updateSearchValue} showError={showError} />
            </SDKProvider>
            <div className={classes.body}>
                {/* {(siteSearchdIsLoading || siteSearchIsFetching || siteSearchData) && (
                    <Good
                        siteSearchData={siteSearchData}
                        siteSearchIsLoading={siteSearchdIsLoading}
                        isFetching={siteSearchIsFetching}
                    />
                )} */}
                {(!siteSearchIsLoading && !siteSearchIsFetching && !siteSearchData) && (
                    <Catalog
                        slugData={slugData}
                        isLoading={slugIsLoading}
                        isFetching={slugIsFetching}
                    />
                )}
            </div>
        </>
    );
};
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';

export interface LanguageState {
    language: 'UA' | 'RU';
    status: 'idle' | 'loading' | 'failed';
}

const initialState: LanguageState = {
    language: 'UA',
    status: 'idle',
};

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setLanguage: (state, action: PayloadAction<'UA' | 'RU'>) => {
            state.language = action.payload;
        },
    },
});

export const { setLanguage } = languageSlice.actions;

export const selectLanguage = (state: RootState) => state.language.language;

export default languageSlice.reducer;
import classes from './popup.module.css';
import ClosePopup from './img/any/close-popup.svg';
import forFree from './img/any/for-free-tag.svg';
import { useTranslation } from 'react-i18next';
import { FillBtn } from '../../../ui';
import { FC } from 'react';
interface PopupProps {
    visible: boolean;
    setVisible: Function;
}

export const Popup: FC<PopupProps> = ({
    visible,
    setVisible,
}) => {
    const { t } = useTranslation('install-popup');
    const rootClasses = [classes.popup_overlay];
    if (visible) {
      rootClasses.push(classes.popup_overlay_active);
    }
    return (
        <div className={rootClasses.join(" ")} onClick={() => setVisible(false)}>
            <div className={classes.popup_container}>
                <div className={classes.popup_background}></div>
                <div className={classes.popup_close}>
                    <img src={ClosePopup} alt="close" />
                </div>
                <div className={classes.popup_title}>{t('title')}</div>
                <div className={classes.popup_prices}>
                    <div className={classes.popup_description}>
                        {t('description')}<span> </span>
                        <img className={classes.popup_forFree} src={forFree} alt="" /> {t('description2')}
                    </div>
                    <div className={classes.popup_image}>
                        <div className={classes.image_chart}></div>
                    </div>
                </div>
                <div className={classes.popup_MoreTitle}>{t('MoreTitle')}</div>
                <div className={classes.popup_FuncPrices}>
                    <div className={classes.popup_FuncDescription}>
                        {t('FuncDescription')}
                        <span className={classes.popup_span}> {t('FuncDescriptionPrice')}</span>
                    </div>
                    <div className={classes.popup_FuncImage}>
                        <div className={classes.image}></div>
                    </div>
                </div>
                <div className={classes.btn_wrapper}>
                    <FillBtn>
                        {t('close')}
                    </FillBtn>
                </div>
            </div>
        </div>
    );
};

import React from 'react'
import { FAQ } from '../features/faq/'
import { Helmet } from 'react-helmet-async';

export const FAQPage = () => {
    return (
        <>
            <Helmet>
                <title>True Price - Питання та відповіді</title>
                <link rel="canonical" href="https://true-price.top/faq" />
            </Helmet>
            <FAQ />
        </>);
}

import { FillBtn } from '../../ui';
import classes from '../news.module.css';
import { Trans, useTranslation } from 'react-i18next';

export const Update505 = () => {
    const { t } = useTranslation(['news']);
    return (
        <div className={classes.wrapper}>
            <div className={classes.news}>{t('title')}</div>
            <div className={classes.containerNews}>
                <div className={classes.blockNews}>
                    <div className={classes.textNews} itemScope itemType="http://schema.org/NewsArticle">
                        <meta itemProp="mainEntityOfPage" content="True" />
                        <div className={classes.titleNews}>
                            <span itemProp="headline">Нові покращення у розширенні: ефективніший пошук, зручні функції та додаткові можливості</span>
                        </div>
                        {/* @ts-ignore */}
                        <div className={classes.dateNews} itemProp="datePublished" content="2024-07-14T00:00:00+08:00">14/07/2024</div>
                        <meta itemProp="dateModified" content="2024-07-14T00:00:00+08:00" />
                        <div className={classes.descriptionNews}>
                            <p itemProp="description">
                                Ми раді повідомити про нові покращення розширення, які зроблять функцію порівняння цін у магазинах ще зручнішою та ефективнішою.</p>
                            <div itemProp="articleBody">
                                <h3 className={classes.articleHeadlines}>Покращення алгоритму пошуку</h3>
                                <p className={classes.articleText}>
                                    Ми вдосконалили алгоритм пошуку, завдяки чому ви побачите більше пропозицій та частіше зможете знайти потрібний товар.<br /> Магазини іноді дублюються, оскільки ми об'єднуємо результати пошуку з кількох джерел.
                                </p>
                                <h3 className={classes.articleHeadlines}>Нове поле "Схожість найменувань"</h3>
                                <p className={classes.articleText}>
                                    Ми додали нове поле "Схожість найменувань", яке показує від 0 до 100 відсотків відповідність знайденої назви товару вашому запиту. Це поле призначене для того, щоб попередити вас про можливі відмінності знайденого товару і нагадати перевірити важливі для вас характеристики.
                                </p>
                                <h3 className={classes.articleHeadlines}>Нове поле "Рейтинг"</h3>
                                <p className={classes.articleText}>
                                    Додано поле "Рейтинг", яке відображає рейтинг товару від 0 до 5 на основі відгуків користувачів. <br /><b>Важливо!</b> Це поле є <b>клікабельним</b>: натиснувши на нього, ви перейдете на сторінку з відгуками про цей товар. Так ви зможете швидко перевірити можливі недоліки чи слабкі місця товару.<br />Крім того, ви можете відфільтрувати відгуки, наприклад, тільки з 3 зірками, щоб одразу побачити проблеми товару.
                                </p>
                                <h3 className={classes.articleHeadlines}>Перетягування та зміна розміру вікна</h3>
                                <p className={classes.articleText}>
                                    Тепер вікно віджета можна перетягувати та змінювати його розмір.<br />Для перетягування утримуйте ліву кнопку миші на написі "ПОРІВНЯННЯ ЦІН" і перемістіть вікно.<br />Для зміни розміру вікна наведіть мишку на потрібну сторону або кут вікна, натисніть ліву кнопку миші і потягніть в потрібну сторону.<br />Вікно запам'ятовує своє положення та розмір до наступного вашого переміщення.
                                </p>
                                <h3 className={classes.articleHeadlines}>Новий дозвіл: Cookies</h3>
                                <p className={classes.articleText}>
                                    Ми впровадили новий дозвіл, який дозволяє True Price маніпулювати cookies для захисту вашої конфіденційності.<br />Тепер ви можете шукати товари через вікно віджета і приховувати свій інтерес до певних товарів від алгоритмів Google, уникаючи таргетингової реклами та відстеження.<br />
                                    Це також вирішить проблему заспамлювання вікна пошуку та історії пошуку запитами розширення.
                                </p>
                                <h3 className={classes.articleHeadlines}>Інші покращення</h3>
                                <ul>
                                    <li>Додано шкалу завантаження, яка відображає процес пошуку.</li>
                                    <li>Додано анімацію завантаження на іконку віджета, яка працює під час пошуку товару.</li>
                                    <li>Введено обмеження на кількість одночасних пошуків, щоб уникнути перевантаження серверу.</li>
                                    <li>Додано підтвердження "Я не робот" у випадку підозрілої активності користувача.</li>
                                </ul>
                                <p className={classes.articleText}>
                                    <b>Дякуємо, що ділитесь нашим розширенням з друзями, це допомагає нам розвиватися!</b><br />
                                    Ми вдячні, що ви обираєте наше розширення, і будемо раді вашим відгукам у магазині розширень. Розкажіть про нас друзям, це допоможе нам у розробці нових покращень та оновлень.
                                </p>
                            </div>
                        </div>
                        <meta itemProp="author" content="Вадим Петров" />
                        <div itemProp="publisher" itemScope itemType="http://schema.org/Organization">
                            <meta itemProp="name" content="True Price" />
                            <div itemProp="logo" itemScope itemType="http://schema.org/ImageObject">
                                <meta itemProp="url" content="/video/poster-hor.webp" />
                                <meta itemProp="width" content="600" />
                                <meta itemProp="height" content="60" />
                            </div>
                        </div>
                        <meta itemProp="image" content="/news-assets/5-0-5.webp" />
                        <div className={classes.imgNews} style={{ width: '100%' }}>
                            <img src='/news-assets/5-0-5.webp' alt='Оновлення 5.0.5' itemProp="image" />
                        </div>
                        <div className={classes.alignCenter} style={{ 'marginTop': '30px' }}>
                            <a href='https://true-price.top/news'><FillBtn>Назад до всіх новин</FillBtn></a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

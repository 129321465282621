import React, { FC } from 'react'
import classes from './fill-btn.module.css'

interface FillBtnProps {
    children?: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
}

const FillBtn: FC<FillBtnProps> = ({ children, onClick, disabled = false }) => {
    return (
        <button disabled={disabled} className={classes.btn} onClick={onClick}>{children}</button>
    )
}
export default FillBtn
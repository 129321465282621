import { Route, Routes } from 'react-router-dom';

import { routes } from '.';
import { useEffect } from 'react';

const AppRouter = () => {
  useEffect(() => {
    // find #product-static and hide it
    const productStatic = document.getElementById('product-static');
    if (productStatic) {
      productStatic.style.display = 'none';
    }

    const catalogStatic = document.getElementById('catalog-static');
    if (catalogStatic) {
      catalogStatic.style.display = 'none';
    }
    
    return () => {
      console.log('AppRouter unmounted');
    };
  }, []);
  return (
    <Routes>
      {routes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}
    </Routes>
  );
};

AppRouter.propTypes = {};

export default AppRouter;
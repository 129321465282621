import { Helmet } from "react-helmet-async";
import { Subscription } from "../../features/cabinet"

export const SubscriptionPage = () => {
    return (
        <>
          <Helmet>
            <title>True Price - Підписка</title>
            <link rel="canonical" href="https://true-price.top/cabinet/sub" />
          </Helmet>
          <Subscription />
        </>);
}

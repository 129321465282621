import classes from './login.module.css';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useErrors } from '../../../hooks';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { authorization } from '../auth.slice';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { Popup } from './popup/';
import { toast } from 'react-toastify';
export const Login = () => {
    const navigate = useNavigate();
    const auth = useAppSelector((state) => state.auth.authorized);
    useEffect(() => {
        if (auth && navigate) {
            navigate('/cabinet/messages');
        }
    }, [auth, navigate])

    const dispatch = useAppDispatch();
    const { t } = useTranslation(['auth', 'ui']);
    const [noErrors, errors, setErrors] = useErrors(['email', 'password', 'otherError']);

    const [trigger, result] = truePriceAPI.useLazyFetchLoginGoogleQuery();
    const loginIsLoading = result.isFetching;
    const loginError: any = result.error;
    const userData = result.data;
    const [toastId, setToastId] = useState<any>(null);
    useEffect(() => {
        if (result.isLoading || result.isFetching) {
            // toast.update(toastId, { render: t("pending", { ns: "ui" }) as String, type: "pending", isLoading: true })
            return;
        } else if (result.isSuccess) {
            toast.update(toastId, { render: t('success', { ns: 'ui' }) as String, type: 'success', isLoading: false, autoClose: 2000, closeButton: true });
        } else if (result.isError) {
            toast.update(toastId, { render: t('error', { ns: 'ui' }) as String, type: 'error', isLoading: false, autoClose: 5000, closeButton: true });
        }
    }, [result]);



    // const handleSubmit = (event: any) => {
    //   event.preventDefault();
    //   setErrors({ ...noErrors });
    //   const data = new FormData(event.currentTarget);
    //   let user: LoginDto = {
    //     email: data.get('email') as string,
    //     password: data.get('password') as string,
    //   };

    //   trigger(user);
    // };


    useEffect(() => {
        if (userData) {
            dispatch(authorization(userData));
            // save token to local storage
            localStorage.setItem('token', userData.accessToken);
            // @ts-ignore
            gtag('event', 'login');
            navigate('/cabinet/messages');
        }
    }, [userData]);

    useEffect(() => {
        // if (loginError) {
        //   if (!('data' in loginError) || !loginError.data) {
        //     setErrors({
        //       ...noErrors,
        //       otherError: {
        //         error: true,
        //         msg: t('unknownError', { ns: 'ui' }),
        //       }
        //     });
        //     return;
        //   }
        //   const messages: any = loginError.data.message ?? loginError.data;
        //   const keys = Object.keys(messages);
        //   const newErrors: any = { ...noErrors };
        //   keys.forEach((key: string) => {
        //     if (key === 'statusCode') {
        //       return;
        //     }
        //     newErrors[key] = {
        //       error: true,
        //       msg: t(messages[key]) ?? t('unknownError', { ns: 'ui' }),
        //     }
        //   });
        //   setErrors(newErrors);
        //   return;
        // }
        if (loginError) {
            //! Сделать вывод ошибки авторизации
        }
    }, [loginError]);



    function handleCredentialResponse(response: any) {
        if (response.credential) {
            setToastId(toast.loading(t('pending', { ns: 'ui' }) as string));
            trigger({ credential: response.credential });
        } else {
            //! Вывести ошибку
        }
    }

    const signInGoogleBtn = useRef(null);

    // функция которая обрабатывает ввод пароля в гугл авторизации
    const nativeCallback = () => {
        // console.log('native callback');
    };

    useEffect(() => {
        if (!signInGoogleBtn.current) {
            return;
        }
        const random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        // @ts-ignore
        google.accounts.id.initialize({
            client_id: '708574902388-9ori26ak75auf9djic94aui56cjb2i1j.apps.googleusercontent.com',
            callback: handleCredentialResponse,
            native_callback: nativeCallback,
            context: 'signin',
            ux_mode: 'popup',
            auto_prompt: true,
            nonce: random,
        });
        // @ts-ignore
        google.accounts.id.renderButton(
            signInGoogleBtn.current,
            {
                type: 'standard',
                shape: 'rectangular',
                theme: 'outline',
                text: 'signin_with',
                size: 'large',
                locale: 'uk',
                logo_alignment: 'left',
            }, // customization attributes

            // @ts-ignore
            // google.accounts.id.prompt(); // also display the One Tap dialog
        );
    }, [signInGoogleBtn]);

    let [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState(false);
    // const [modal, setModal] = useState(true);
    useEffect(() => {
        if (searchParams.has('created') && !auth) {
            setModal(searchParams.has('created'));
            searchParams.delete('created');
            setSearchParams(searchParams);
            // @ts-ignore
            gtag('event', 'installedPopup');
        }
    }, [searchParams, auth])

    return (
        <section className={classes.loginContainer}>
            <div className={classes.loginHeader}>{t('login')}</div>

            <div ref={signInGoogleBtn}></div>

            {/* <form action="" onSubmit={handleSubmit}>
        <label className={classes.inputWrap} >
          <Input autocomplete='email' type="email" error={errors.email?.error} errorMsg={errors.email?.msg} name={'email'} labelText={t('email')} />
        </label>
        <label className={classes.inputWrap} >
          <Input autocomplete='current-password' minlength={4} maxlength={16} type="password" error={errors.password?.error} errorMsg={errors.password?.msg} name={'password'} labelText={t('pass')} />
        </label>
        <div className={classes.loginBtns}>
          <Link to="/reset" >{t("fogotPass")}</Link>
        </div>
        <div className={classes.btnWrapper}>
          <FillBtn disabled={loginIsLoading}>{t("loginBtn")}</FillBtn>
        </div>
      </form> */}
            <Popup visible={modal} setVisible={setModal} />
        </section>
    );
};

import classes from "./chart-date.module.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { FC, useEffect, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

// export const options = {
//     responsive: true,
//     plugins: {
//         legend: {
//             position: 'top' as const,
//         },
//         title: {
//             display: true,
//             text: 'Chart.js Line Chart',
//         },
//     },
// };
export type SelectedDate = [false | Date, false | Date];
const COLORS = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#00FF00",
  "#FF0000",
  "#0000FF",
  "#FFFF00",
  "#00FFFF",
  "#FF00FF",
  "#800000",
  "#808000",
  "#008000",
  "#800080",
  "#008080",
  "#000080",
  "#C0C0C0",
  "#808080",
  "#FFFFFF",
  "#000000",
];
interface ChartDateProps {
  // children?: React.ReactNode;
  // onClick?: () => void;
  chartData: {
    date: string[];
    value:
      | number[]
      | {
          value: (string | number)[];
          label: string;
        }[];
  };
  labelName: string;
  changeSelectedDate: (start: SelectedDate[0], end: SelectedDate[1]) => void;
}

export const ChartDate: FC<ChartDateProps> = ({
  chartData,
  labelName,
  changeSelectedDate,
}) => {
  const [allData, setAllData] = useState<any>({});
  useEffect(() => {
    // const monthsData = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    if (typeof chartData.value[0] === "object") {
      const datas = chartData.value.map((item: any, index) => {
        return {
          label: item.label,
          data: item.value,
          fill: false,
          borderColor: COLORS[index],
          tension: 1,
        };
      });
      setAllData({
        labels: chartData.date,
        datasets: datas,
      });
    } else if (typeof chartData.value[0] === "number") {
      setAllData({
        labels: chartData.date,
        datasets: [
          {
            fill: true,
            // data: monthsData.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            data: chartData.value,
            borderColor: "rgb(58, 202, 121)",
            backgroundColor: "rgba(58, 202, 121, 0.5)",
            label: labelName,
          },
        ],
      });
    }
  }, [chartData]);

  const [dates, setDates] = useState<[null | Date, null | Date]>([null, null]);
  const [datesLib, setDatesLib] = useState<Date | Date[] | undefined>(
    undefined
  );
  useEffect(() => {
    // const dateStart = dates[0] ? dates[0].toISOString().split('T')[0]: '';
    // const dateEnd = dates[1] ? dates[1].toISOString().split('T')[0] : '';
    changeSelectedDate(
      dates[0] ? dates[0] : false,
      dates[1] ? dates[1] : false
    );
  }, [dates]);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const ranges = [
    { name: "Вчера", code: "1" },
    { name: "7 дней", code: "7" },
    { name: "30 дней", code: "30" },
    { name: "3 месяца", code: "90" },
    { name: "Все время", code: "all" },
  ];

  const onSelectedDate = (e: any) => {
    switch (e.value.code) {
      case "all":
        setDates([null, null]);
        break;
      default:
        const date = new Date();
        date.setDate(date.getDate() - Number(e.value.code));
        date.setHours(0, 0, 0, 0);
        setDates([date, null]);
        break;
    }
    setSelectedDate(e.value);
  };

  useEffect(() => {
    if (
      datesLib !== undefined &&
      Array.isArray(datesLib) &&
      datesLib.length === 2
    ) {
      setDates([datesLib[0] as Date, datesLib[1] as Date]);
    }
  }, [datesLib]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {" "}
        <Calendar
          id="range"
          value={datesLib}
          onChange={(e) => setDatesLib(e.value)}
          selectionMode="range"
          readOnlyInput
        />
        <Dropdown
          value={selectedDate}
          options={ranges}
          onChange={onSelectedDate}
          optionLabel="name"
          placeholder="Select a Range"
        />
      </div>

      {allData.labels && <Line data={allData} options={{ responsive: true }} />}
    </div>
  );
};

import React, { FC } from 'react'
import classes from './header.module.css'
import Language from './language/language.component'
import Menu from './menu/menu.component'
import Auth from './auth/auth.component'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { Link } from 'react-router-dom'


const HeaderComponent: FC = () => {
  const { height, width } = useWindowDimensions();
  const isMobile = width < 600;
  const isTablet = (width >= 600 && width < 1024);
  const isDesktop = width >= 1024;

  return (
    <header className={classes.header}>
      <div className={classes.container}>
        <Link to="/" className={classes.logoLink}><div className={classes.logo}>
        </div></Link>
        {isDesktop &&
          <>
            <Menu />
            <Language />
            <Auth />
          </>
        }
        {(isTablet || isMobile) && <Menu />}
      </div>
    </header>
  )
}
export default HeaderComponent
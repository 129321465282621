import { TFunction, useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import classes from './telegram.module.css';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { truePriceAPI } from '../../../../app/api/true-price-api';

interface TelegramProps {
    t: TFunction<'settings'[], undefined>;
    onLoaded: (status: boolean) => void;
}

export const Telegram: FC<TelegramProps> = ({ t, onLoaded }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.auth.user.accessToken);

    const {
        data: haveSubscribe,
        isLoading: haveSubscribeisLoading,
        error: haveSubscribeError,
    } = truePriceAPI.useFetchLinkStatusTelegramQuery(
        { token },
        {
            skip: !token,
        },
    );

    useEffect(() => {
        if (haveSubscribeisLoading === false && haveSubscribe && haveSubscribe['link']) {
            onLoaded(true);
        }
    }, [haveSubscribeisLoading]);

    const toggleTelegram = async (event: ChangeEvent) => {
        event.target.setAttribute('disabled', 'disabled');
        await dispatch(truePriceAPI.endpoints.fetchToggleTelegram.initiate({ token }));
        event.target.removeAttribute('disabled');
    };

    const conntectTelegram = () => {
        if (haveSubscribe && haveSubscribe['link']) {
            const tab = window.open(`https://t.me/TruePriceUaBot?start=${haveSubscribe['link']}`, '_blank', 'noopener,noreferrer');
            if (tab) {
                tab.focus();
            }
        }
    };

    return (
        <>
            {haveSubscribeisLoading === false && haveSubscribe && haveSubscribe['link'] && (
                <div className={classes.settingsContainer}>
                    <div className={classes.settingsBtnWrap}>
                        <div className={classes.settingsTelegramTitle}>{t('title')}</div>

                        <div className={classes.settingsTogleContainer}>
                            <div className={classes.settingsButtonCover}>
                                <input className={classes.chekbox__input} onChange={toggleTelegram} checked={haveSubscribe.status} type="checkbox" id="telegramm" name="telegramm" />
                                <label className={classes.chekbox__lable} htmlFor="telegramm">
                                    {t('notifications')}
                                </label>
                            </div>
                        </div>
                        <div onClick={conntectTelegram} className={[classes.settingsButton, haveSubscribe.linkedTelegram ? classes.orangeBtn : ''].join(' ')}>
                            <div className={classes.settingsImg}></div>
                            <div className={[classes.settingsText].join(' ')}>{t(haveSubscribe.linkedTelegram ? 'telegramChange' : 'telegramSubscribe')}</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

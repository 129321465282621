import React, { FC } from 'react';
import classes from './faq.module.css';
import { Trans, useTranslation } from 'react-i18next';
import questionIcon from './question-icon.svg';
import { FillBtn } from '../../ui';
import { Link } from 'react-router-dom';
const FAQ: FC = () => {
    const { t } = useTranslation(['mainPageFAQ', 'ui']);
    const questionList = [
        {
            question: t('question1'),
            answer: t('answer1'),
            link: 'https://youtu.be/4cP90ramMVQ',
        },
        {
            question: t('question2'),
            answer: t('answer2'),
        },
        {
            question: t('question3'),
            answer: t('answer3'),
        },
        {
            question: t('question4'),
            answer: t('answer4'),
        },
    ];
    return (
        <section className={classes.section}>
            {/* <div className={classes.sectionDivine}></div> */}
            <div className={classes.header}>{t('header')}</div>
            <div className={classes.subHeader}>{t('subHeader')}</div>
            <div className={classes.questionsContainer}>
                {questionList.map((question, index) => (
                    <div className={classes.question} key={index}>
                        <div className={classes.questionHeaderWrapper}>
                            <div className={classes.questionIcon}></div>
                            <div className={classes.questionHeader}>{question.question}</div>
                        </div>
                        <div className={classes.answer}>
                            <Trans i18nKey={question.answer} values={{ link: question['link'] ?? '' }} components={{ a: <a></a> }}></Trans>
                        </div>
                    </div>
                ))}
            </div>
            <div className={classes.btnWrapper}>
                <Link to="/faq">
                    <FillBtn>{t('button')}</FillBtn>
                </Link>
            </div>
        </section>
    );
};

export default FAQ;

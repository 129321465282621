import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IDiscount } from '../../../app/api/interfaces/discount.interface';
import classes from './goods.module.css'
import { Loader } from '../../../features/ui'

interface GoodsProps {
    children?: React.ReactNode;
    goodsData: IDiscount[] | undefined;
    goodsIsLoading: boolean;
}


export const Goods: FC<GoodsProps> = ({ goodsData, goodsIsLoading }) => {
    const { t } = useTranslation(['discount', 'ui']);
    return (
        <div className={classes.section}>
            {goodsIsLoading &&
                <div className={classes.loader}>
                    <Loader />
                </div>
            }
            {(goodsData && goodsData.length > 0) &&
                <div className={classes.goodsWrapper}>
                    {goodsData && goodsData.map((good) => (
                        <a href={good.link} key={good.link} target="_blank">
                            <div className={classes.containerCard} >
                                <div className={classes.containerImg}>
                                    <img className={classes.img} src={good.bigImg ?? good.img} alt="" />
                                    {/* @ts-ignore */}
                                    <div className={classes.lowPrice} content={t("lowPriceHelp")}>
                                        <span>{good.discount}%</span>
                                    </div>
                                </div>
                                <p data-tooltip={good.name} className={classes.title}>{good.name}</p>
                                <div className={classes.price}>{good.price} грн</div>
                            </div>
                        </a>
                    ))}
                </div>
            }

            {(!goodsIsLoading && goodsData && goodsData.length === 0) &&
                <div className={classes.noGoods}>{t("noGoods")}</div>
            }
        </div>

    )
}

import { Helmet } from 'react-helmet-async'
import { Browser } from '../../features/browser'


export const BrowserPage = () => {
    return (
        <>
            <Helmet>
                <title>True Price - Інсталювати</title>
                <link rel="canonical" href="https://true-price.top/install" />
            </Helmet>
            <Browser />
            
        </>
    )
}

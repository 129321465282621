import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './upgrade-card.module.css'
import rightNavigatorIcon2 from './img/any/right-navigator-2.svg'

export const UpgradeCard: FC = () => {
  const { t } = useTranslation(['capabilities', 'ui']);
  return (
    <section className={classes.goodCardContainer}>
      <div className={classes.featureDescription}>
        <div className={classes.featureDescriptionTitle}>{t('featureDescriptionTitle')}</div>
        <div className={classes.featureDescriptionText}>{t('featureDescriptionText')}</div>
      </div>
      <div className={classes.goodCardWrapper}>
        <div className={classes.goodCardImage}></div>
      </div>
      <div className={classes.goodCardDescription}>
        <div className={classes.goodCardDescriptionItem}>
          <div className={classes.arrowIcon}></div>
          <span className={classes.goodCardDescriptionText}>{t('goodCardDescriptionText1')}</span>
        </div>
        <div className={classes.goodCardDescriptionItem}>
          <div className={classes.arrowIcon}></div>
          <span className={classes.goodCardDescriptionText}>{t('goodCardDescriptionText2')}</span>
        </div>
        {/* <div className={classes.goodCardDescriptionItem}>
          <div className={classes.arrowIcon}></div>
          <span className={classes.goodCardDescriptionText}>{t('goodCardDescriptionText3')}</span>
        </div>
        <div className={classes.goodCardDescriptionItem}>
          <div className={classes.arrowIcon}></div>
          <span className={classes.goodCardDescriptionText}>{t('goodCardDescriptionText4')}</span>
        </div> */}
      </div>
      <div className={classes.rightNavigatorIconWrapper2}>
        <img src={rightNavigatorIcon2} className={classes.rightNavigatorIcon2} alt="navigator-line" />
      </div>
    </section>
  )
}


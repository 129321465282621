import { isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { authorization, logout } from '../../features/auth/auth.slice';
import { truePriceAPI } from '../api/true-price-api';
import { toast } from 'react-toastify';
import i18n from 'i18next';

/**
 * Log a warning and show a toast!
 */
export const authCheck: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (
            action['payload'] &&
            action.payload['data'] &&
            action.payload.data['message'] &&
            action.payload.data.message === 'TOKEN_EXPIRED'
        ) {
            const expiredAt = new Date(action.payload.data.message.expiredAt);
            expiredAt.setDate(expiredAt.getDate() + 30);
            if (expiredAt < new Date()) {
                api.dispatch(logout());
                return next(action);
            } else {
                try {
                    // @ts-ignore
                    api.dispatch(truePriceAPI.endpoints.fetchRefreshToken.initiate())
                        .unwrap()
                        .then((originalPromiseResult: any) => {
                            localStorage.setItem('token', originalPromiseResult.accessToken);
                            // @ts-ignore
                            api.dispatch(truePriceAPI.endpoints.fetchGetUserInfo.initiate(originalPromiseResult.accessToken),
                            )
                                .unwrap()
                                .then((result: any) => {
                                    api.dispatch(authorization(result));
                                    return next(action);
                                })
                                .catch((error: any) => {
                                    api.dispatch(logout());
                                    return next(action);
                                });
                        })
                        .catch((rejectedValueOrSerializedError: any) => {
                            api.dispatch(logout());
                            return next(action);
                        });
                } catch (error) {
                    return next(action);
                }
            }
        } else if (
            action['payload'] &&
            action.payload['data'] &&
            action.payload.data['message'] &&
            action.payload.data.message === 'NOT_SUBSCRIBED'
        ) {
            return next(action);
        } else {
            const errorCode = action.payload?.data?.message ? action.payload.data.message : 'UNKNOWN';
            if (errorCode === 'INVALID_TOKEN') {
                return next(action);
            }
            const translated = i18n.t(errorCode, { ns: 'errors' });
            toast.error(translated);
            return next(action);
        }
    } else {
        return next(action);
    }
};

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { updateSubscription } from '../../auth/auth.slice';
import { FillBtn } from '../../ui';
import classes from './subscription.module.css';

export const Subscription = () => {
  const dispatch = useAppDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [created, setCreated] = useState(searchParams.has('created'));
  const token = useAppSelector((state) => state.auth.user.accessToken);
  const isSubscribed = useAppSelector((state) => state.auth.user.isSubscribed);
  const isSubscribedExpired = useAppSelector((state) => state.auth.user.isSubscribedExpired);

  const { data: subscriptionData, isFetching: isFetchingSubscriptionData, isLoading: isLoadingSubscriptionData, error: errorSubscriptionData } = truePriceAPI.useFetchCheckSubQuery({ token }, {
    skip: !token || created,
    pollingInterval: 60000,
  });

  const { data: paymentData, isFetching: isFetchingPaymentData, isLoading: isLoadingPaymentData, error: errorPaymentData } = truePriceAPI.useFetchCheckSubPaymentQuery({ token }, {
    skip: !created,
    pollingInterval: 5000,
  });

  useEffect(() => {
    if (subscriptionData?.status === 1 && created) {
      setCreated(false);
    }
  }, [subscriptionData])

  useEffect(() => {
    if (paymentData?.status !== 0 && created) {
      if (paymentData?.status === 1) {
        // @ts-ignore
        gtag('event', 'purchase', {
          value: 1
        });
      }
      setCreated(false);
    }
  }, [paymentData])

  useEffect(() => {
    if (searchParams.has('created')) {
      setCreated(searchParams.has('created'));
      searchParams.delete('created');
      setSearchParams(searchParams);
      // @ts-ignore
      gtag('event', 'checkout_progress');
    }
  }, [searchParams])

  const { t } = useTranslation(['subscription']);
  const Stats: FC<{ title: string, value: string }> = ({ title, value }) => (
    <div className={[classes.stats, isSubscribedExpired === 1 ? classes.redText : ''].join(" ")}>
      <div className={classes.statsTitle}>{title}:</div>
      <div className={classes.statsValue}>{value}</div>
    </div>
  )

  const createInvoiceHandler = async () => {
    const result = await dispatch(truePriceAPI.endpoints.fetchCreateInvoice.initiate({ token }));
    if (result.data) {
      const link = `https://www.liqpay.ua/api/3/checkout?data=${result.data.data}&signature=${result.data.signature}`;
      // @ts-ignore
      gtag('event', 'begin_checkout');
      window.location.href = link;
    }
  }

  const cancelSubscriptionHandler = async () => {
    await dispatch(truePriceAPI.endpoints.fetchCancelSub.initiate({ token }));
    dispatch(updateSubscription(0))
    // @ts-ignore
    gtag('event', 'refund');
  }

  return (
    <>
      {created ?
        <>
          <div className={classes.title}>{t('createdTitle')}</div>
          <div className={classes.createdDesc}>{t('createdDesc')}</div>
        </>
        :
        <>
          <section className={classes.section}>
            <div className={classes.title}>{t('status')}</div>
            <div className={classes.subscriptionStatsWrapper}>
              <Stats title={t('subsctiption')} value={isSubscribedExpired ? t('notActive') : t('active')} />
              <Stats title={t('notifications')} value={isSubscribedExpired ? t('notActiv') : t('activ')} />
              <Stats title={t('upgradeGoodsGard')} value={isSubscribedExpired ? t('notActive') : t('active')} />
              <Stats title={t('upgradeMenu')} value={isSubscribedExpired ? t('notActiv') : t('activ')} />
            </div>
            {isSubscribed === 0 &&
              <div className={classes.btnWrapper}>
                <FillBtn onClick={createInvoiceHandler}>{t('subscribe')}</FillBtn>
              </div>
            }

          </section >
          {(isSubscribed === 1 && subscriptionData && subscriptionData.card && subscriptionData.expired && subscriptionData.expired.length > 0) &&
            <section className={[classes.section, classes.manageSection].join(" ")}>
              <div className={classes.title}>{t('manage')}
              </div>
              <div className={classes.subscriptionStatsWrapper}>
                <div className={[classes.stats, classes.manage].join(" ")}>
                  <div className={classes.statsTitle}>{t('nextPayment')}:</div>
                  <div className={classes.manageValue}>{new Date(subscriptionData.expired).toLocaleString('uk-UK')} </div>
                </div>
                <div className={[classes.stats, classes.manage].join(" ")}>
                  <div className={classes.statsTitle}>{t('paymentCard')}:</div>
                  <div className={classes.manageValue}>{subscriptionData.card} </div>
                </div>
              </div>
              <div className={[classes.btnWrapper, classes.unsubscribeBtnWrapper].join(" ")}>
                <FillBtn onClick={cancelSubscriptionHandler}>{t('cancelSubscription')}</FillBtn>
              </div>
            </section >
          }
        </>
      }
    </>
  )
}

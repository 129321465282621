import React, { FC, useEffect, useState } from 'react';
import classes from './menu.module.css';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import burgerIcon from './img/burger.svg';
import closeIcon from './img/close.svg';
import Language from '../language/language.component';
import Auth from '../auth/auth.component';
import { Link } from 'react-router-dom';
const Menu: FC = () => {
    const { t, i18n} = useTranslation('menu');
    const { height, width } = useWindowDimensions();
    const isMobile = width < 600;
    const isTablet = width >= 600 && width < 1024;
    const [isOpen, setIsOpen] = useState(false);
    const language = i18n.language;
    const languagePrefix = language === 'ua' ? 'uk' : 'ru';
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    const overlayClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            setIsOpen(false);
        }
    };
    const notebook = () => (
        <div className={classes.wrapper}>
            <div className={classes.menu}>
                <Link to="/" className={[classes.item, classes.removeDot].join(' ')}>
                    {t('main')}
                </Link>
                <div className={classes.dot}></div>
                <Link to={`/check/${languagePrefix}`} className={classes.item}>
                    {t('check')}
                </Link>
                <div className={classes.dot}></div>
                <Link to="/news" className={classes.item}>
                    {t('news')}
                </Link>
                {/* <div className={classes.dot}></div>
                <Link to="/discount" className={classes.item}>
                    {t('discounts')}
                </Link> */}
                <div className={classes.dot}></div>
                <Link to="/faq" className={classes.item}>
                    {t('faq')}
                </Link>
                <div className={classes.dot}></div>
                <Link to="/install" className={classes.item}>
                    {t('install')}
                </Link>
            </div>
        </div>
    );
    const mobile = () => (
        <>
            <button className={classes.burger}>
                <img src={burgerIcon} alt="burger" onClick={handleClick} />
            </button>

            {isOpen && (
                <div className={classes.overlay} onClick={(e) => overlayClickHandler(e)}>
                    <div className={classes.wrapper}>
                        <div className={classes.contain}>
                            <div className={classes.languageAndAuthWrapper}>
                                <Language />
                                {isTablet && <Auth />}

                                <button className={classes.burger}>
                                    <img src={closeIcon} alt="close" onClick={handleClick} />
                                </button>
                            </div>
                            <div className={classes.menu}>
                                <Link to="/" className={[classes.item, classes.removeDot].join(' ')} onClick={() => setIsOpen(false)}>
                                    {t('main')}
                                </Link>
                                <div className={classes.dot}></div>
                                <Link to={`/check/${languagePrefix}`} className={classes.item} onClick={() => setIsOpen(false)}>
                                    {t('check')}
                                </Link>
                                <div className={classes.dot}></div>
                                <Link to="/news" className={classes.item} onClick={() => setIsOpen(false)}>
                                    {t('news')}
                                </Link>
                                {/* <div className={classes.dot}></div>
                                <Link to="/discount" className={classes.item} onClick={() => setIsOpen(false)}>
                                    {t('discounts')}
                                </Link> */}
                                <div className={classes.dot}></div>
                                <Link to="/faq" className={classes.item} onClick={() => setIsOpen(false)}>
                                    {t('faq')}
                                </Link>
                                <div className={classes.dot}></div>
                                <Link to="/install" className={classes.item}>
                                    {t('install')}
                                </Link>
                            </div>
                            {isMobile && <Auth />}
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    return width < 1024 ? mobile() : notebook();
};

export default Menu;

import classes from './offer.module.css';

export const Offer = () => {
    return (
        <section>
            <div className={classes.title}>ПУБЛІЧНА ОФЕРТА</div>
            <div className={classes.text}>
                Договір Публічної Оферти вступив в силу: <b>21.11.2022</b>
                <br />
                Фізична особа-підприємець <span>«Петров В.В»</span> РНОКПП:
                <span> 3620004957</span>, іменована в подальшому <span>"Компанія"</span>
                , з одного боку та фізична чи юридична особа, що іменується в подальшому "Користувач", з іншого боку, кожна спільно іменовані "Сторони", а кожна окремо - “Сторона”,
                уклали цей Договір Публічної Оферти, адресований необмеженому колу осіб, який є офіційною публічною пропозицією Компанії укласти цей Договір Публічної Оферти з
                будь-якою фізичною особою, яка тимчасово або постійно проживає на території України, має повну та нічим не обмежену правоздатність та дієздатність, а також
                усвідомлює юридичне значення своїх дій та їхні правові наслідки, чи юридичною особою, яка є резидентом або нерезидентом України, (надалі “Договір”) про наступне:
                <br />
            </div>
            <div className={classes.title}>ТЕРМІНИ</div>
            <div className={classes.text}>
                — <span>Акцепт</span> - це повне прийняття та виконання умов Договору, шляхом замовлення Послуг на Сайті Компанії та заповнення позначки у формі згоди.
                <br />— <span>Додаток “True Price” (надалі “Додаток”)</span> - це комп’ютерна програма, що дає встановлюється в браузері Користувача та дозволяє здійснювати
                відстеження вартості Товарів на сайті маркетплейсу “Rozetka”.
                <br />— <span>Компанія “True Price” (надалі “Компанія”)</span> - це Фізична особа-підприємець <span>«Петров В.В»</span> РНОКПП:
                <span> 3620004957</span>, яка надає Послуги Користувачу.
                <br />— <span>Контент</span> - це будь-які матеріали, що розміщені на Сайті та/або Додатку, в тому числі, але не обмежуючись: зображеннями, текстом, ілюстраціями,
                посиланням, програмним кодом, відео та фото матеріалами, аудіофайлами.
                <br />— <span>Користувач</span> - це дієздатна фізична особа, що отримує Послуги та користується функціями Додатку і Сайту.
                <br />— <span>Особистий Кабінет</span> - це додаткова функція Сайту, за допомогою якої Користувач має можливість придбати Підписку, отримувати повідомлення та
                здійснювати налаштування.
                <br />— <span>Персональні дані</span> - інформація, яка дозволяє ідентифікувати Користувача та необхідна для надання Послуг.
                <br />— <span>Підписка</span> - це здійснення оплати за певний період для отримання доступу до додаткової кількості переглядів та порівняння Товарів.
                <br />— <span>Послуги</span> - це вчинення Компанією алгоритму дій, направлений на здійснення порівняння Товару, що розміщений на сайті маркетплейсу “Rozetka”.
                <br />— <span>Сайт “True Price” (надалі “Сайт”)</span> - означає веб-сторінку або групу веб-сторінок в мережі Інтернет, які розміщені в мережі Інтернет за такою
                адресою: <a href="https://true-price.top/">https://true-price.top/</a> та за допомогою яких Користувач отримує Послуги та має можливість порівняти Товар.
                <br />— <span>Товар</span> - продукт людської праці у матеріальній формі, який завдяки своїм властивостям задовольняє потреби Користувача.
            </div>
            <div className={classes.title}>УКЛАДАННЯ ДОГОВОРУ</div>
            <div className={classes.text}>
                — Публічний Договір має юридичну силу відповідно до ст.ст. 633, 641, 642 Цивільного кодексу України та є рівносильним договору, укладеному Сторонами. Користувач не
                має права змінювати умови укладання Публічної Оферти.
                <br />
                — Договір укладається шляхом надання повної та безумовної згоди (Акцепту) Користувачем, без підпису письмового примірника Договору Сторонами. У випадку якщо
                Користувач не згодний з умовами Договору, то він не має права укладати її, а також не вправі користуватись Послугами.
                <br />
                — Укладаючи Публічну Оферту Користувач автоматично погоджується з повним та безумовним прийняттям положень Публічної Оферти.
                <br />
                — Здійснення Користувачем наступним дій вважається Акцептом цієї Публічної Оферти:
                <br />
                <i className={classes.mr100}>— реєстрація на Сайті;</i>
                <br />
                <i className={classes.mr100}>— встановлення Додатку;</i>
                <br />
                <i className={classes.mr100}>— використання Послуг Компанії.</i>
                <br />
                <br />
                <b>Місцем укладання Публічної Оферти є місто Київ.</b>
            </div>
            <div className={classes.title}>ПРЕДМЕТ ДОГОВОРУ</div>
            <div className={classes.text}>
                — Компанія зобов’язується на умовах та в порядку, визначених цим Договором, надати Користувачу Послуги, а Користувач зобов’язується на умовах та в порядку,
                визначених цим Договором, прийняти та оплатити Послуги.
                <br />— Компанія зобов’язується на умовах та в порядку, визначених цим Договором, надати Користувачу Послуги, а Користувач зобов’язується на умовах та в порядку,
                визначених цим Договором, прийняти та оплатити Послуги.
            </div>
            <div className={classes.title}>ПОСЛУГИ</div>
            <div className={classes.text}>
                — Компанія надає Користувачу Послугу перегляду графіку коливань вартості Товару на сайті маркетплейсу “Rozetka” без підключення Підписки.
                <br />
                — Компанія надає Користувачу наступні Послуги у разі підключення Підписки:
                <br />
                <br />
                <b>
                    <i>— Покращене меню. Дозволяє більш якісно здійснювати пошук перегляду Товару на маркетплейсі “Rozetka”;</i>
                </b>
                <br />
                <br />
                <b>
                    <i>— Сповіщення. Отримати повідомлення щодо зміни вартості Товару або зміни кредитної пропозиції;</i>
                </b>
                <br />
                <b>
                    <i>— Сортування за реальною знижкою. Надає можливість Користувачу здійснити розташування Товару за реальною знижкою на сайті маркетплейсу "Rozetka";</i>
                </b>
                <br />
                <br />
                <b>
                    <i>— Позначку на "картці" Товару. Користувач має можливість дізнатися розмір знижки або розмір збільшення вартості Товару у відсотковому відношенні.</i>
                </b>
                <br />
                <br />
                <i>— Компанія надає Послуги аналізу Товару, представленому виключно на сайті маркетплейсу “Rozetka”.</i>
                <br />
                <br />
                <b>
                    <i>— Компанія надає Додаток для встановлення в наступних браузерах:</i>
                </b>
                <br />
                <i>
                    <a href="https://chrome.google.com/webstore/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-ro/obkfkpbaobcmhjmopldnakdgbiecjbbl">
                        — Google Chrome;
                    </a>
                </i>
                <br />
                <i>
                    <a href="https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-/cibppbhjepploffdgeblccilmmepkkjm">
                        — Microsoft Edge;
                    </a>
                </i>
                <br />
                — Після встановлення Додатку, Користувач має можливість відстежити ціни будь-якого Товару на сайті маркетплейсу “Rozetka”.
                <br />
                — Для отримання Послуг Користувача вчиняє наступний алгоритм дій:
                <br />
                <i className={classes.mr100}>— реєструється на Сайті;</i>
                <br />
                <i className={classes.mr100}>— встановлює Додаток;</i>
                <br />
                <i className={classes.mr100}>— перейде на сайт “Rozetka”, де з’явиться графік відстеження ціни обраного Товару.</i>
                <br />
                — Користувач має право отримати весь перелік Послуг, що надаються Компанією, активував Підписку в своєму Особистому Кабінеті. Без активації Підписки, Користувач
                отримує лише доступ до перегляду графіку коливань вартості Товару на сайті марктеплейсу “Rozetka”.
                <br />
                — Вартість Підписки становить 1 (один) долар США, що встановлюється в гривневому еквіваленті, відповідно до курсу валют НБУ, на день придбання Підписки.
                <br />
                — Користувач має можливість придбати Підписку в своєму Особистому Кабінеті. Оплата Підписки здійснюється в автоматичному режимі кожного місяця, а дата платежу
                встановлюється платіжною системою LiqPay. Оплата підписки здійснюється за допомогою платіжного сервісу LiqPay.
                <br />
                — Оплата за Підписку здійснюється за місячний період, в незалежності від дати придбання Підписки. Тобто, Користувач сплачує вартість Підписки за повний місяць,
                навіть якщо Користувач придбав Підписку не першого числа.
                <br />
                <b>
                    <i>
                        — Підписуючи цей Договір та купуючи Підписку, Користувач погоджується з умовами оплати та варстістю Підписки. Якщо Користувач не згодний з вартістю та
                        умовами оплати Підписки, то Користувач не може купувати Підписку.
                    </i>
                </b>
            </div>
            <div className={classes.title}>ПОВЕРНЕННЯ КОШТІВ</div>
            <div className={classes.text}>
                — Купуючи віртуальний цифровий продукт, який є підвищенням рівня доступу до Додатку, а саме що знімає ліміт на отримання Послуг, Ви усвідомлюєте, що він є
                невідшкодованим
                <br />
                — Компанія не повертає кошти сплачені за Підписку. Підписка діє до моменту закінчення оплаченого періоду.
                <br />
                — Якщо Користувач здійснює спробу повернення платежів або відкриття суперечки, в тому числі і в судовому порядку, Компанія має право припинити надавати Послуги
                Користувачу, видалити його Особистий Кабінет та припинити надання доступу до Сайту та Додатку.
                <br />— Користувач не має право на повернення коштів за Підписку, але має право деактивувати Підписку.
            </div>
            <div className={classes.title}>ДЕАКТИВАЦІЯ ПІДПИСКИ</div>
            <div className={classes.text}>
                — Деактивувати Підписку Користувач може або у відповідному розділі Особистого Кабінету або надіславши повідомлення на електронну пошту:
                <a href="mailto:support@true-price.top">support@true-price.top</a>
                <br />— Користувач має право в будь-який повторно придбати нову підписку в своєму Особистому Кабінеті.
            </div>
            <div className={classes.title}>ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН</div>
            <div className={classes.text}>
                <p>— Користувач має право:</p>
                — отримати Послуги від Компанії в повному обсязі;
                <br />
                — придбати та деактивувати Підписку;
                <br />
                — встановити Додаток;
                <br />
                — видалити Додаток в своєму браузері та вказати причини видалення за допомогою форми зворотнього зв’язку;
                <br />
                — ознайомитись з умовами обробки його Персональних данних, скориставшись внутрішнім посиланням на документ - Політика Конфіденційності.
                <br />
                <p>— Користувач зобов’язаний:</p>
                — дотримуватись умов Договору;
                <br />
                — оплачувати Підписку, відповідно до умов Договору;
                <br />
                — надати Компанію точну інформацію, що необхідна для надання Послуг.
                <br />
                <p>— Компанія має право:</p>
                — отримати оплату за надання Підписки;
                <br />
                — змінювати вартість Підписки;
                <br />
                — змінювати умови Договору;
                <br />
                — здійснювати надсилання рекламних та спеціальних пропозицій телекомунікаційними засобами зв’язку (електронною поштою, мобільним зв’язком, за допомогою месенджерів
                та соціальних мереж тощо);
                <br />
                — видалити Особистий Кабінет та припинити доступ до Сайту та Додатку, в разі порушення Користувачем умов Договору;
                <br />
                — здійснювати рекламування на веб-сторінках Сайта товару та продукції третьої особи;
                <br />
                — відмовити Користувачу в наданні Послуг, без повернення оплати, в разі порушення ним умов цього Договору.
                <br />
                <p>— Компанія зобов’язана:</p>
                — надати Користувачу Послуги в повному обсязі;
                <br />
                — надати Користувачу доступ до Додатку та Сайту;
                <br />— надати Користувачу доступ до Підписки.
            </div>
            <div className={classes.title}>ІНТЕЛЕКТУАЛЬНІ ПРАВА</div>
            <div className={classes.text}>
                — Всі виключні права інтелектуальної власності на програмне забезпечення та Контент Компанії, в тому числі: елементи дизайну, назва, графічні зображення,
                ілюстрації, текст та опис, та інші об'єкти, а також складові частини та їх елементи належать ФОП <span>«Петров В.В»</span>.
                <br />— Всі виключні права інтелектуальної власності на результати надання Послуг та на Додаток належать Користувачу.
            </div>
            <div className={classes.title}>ВІДПОВІДАЛЬНІСТЬ СТОРІН</div>
            <div className={classes.text}>
                — Сторони несуть відповідальність відповідно до чинного законодавства України, за невиконання або неналежне виконання своїх зобов’язань за цим Договором.
                <br />
                — Компанія не несе відповідальності за якість надання Послуг:
                <br />
                <i className={classes.mr100}>— якщо Користувач порушує умови цього Договору;</i>
                <br />
                <i className={classes.mr100}>— у разі виникнення технічних проблем, неполадок та хакерської атаки;</i>
                <br />
                <i className={classes.mr100}>— у разі пошкодження Сайту чи Додатку;</i>
                <br />
                <i className={classes.mr100}>— у разі виникнення проблем у роботі маркетплейсу “Rozetka”;</i>
                <br />
                <i className={classes.mr100}>— у разі передачі Користувачем доступу до Особистого Кабінету третій стороні;</i>
                <br />
                <i className={classes.mr100}>— за вміст Контенту, що з’являється на Сайті чи надається Користувачу;</i>
                <br />
                <i className={classes.mr100}>— за будь-які дії Користувача або взаємодію з третіми особами;</i>
                <br />
                <i className={classes.mr100}>— за будь-які помилки та упущення в роботі Додатку або Сайту.</i>
                <br />
                — Компанія не несе жодної відповідальності за своєчасність, видалення, недотримання, неточність або неналежне надання яких-небудь даних або інформації, що
                стосується Товару.
                <br />
                — Компанія не несе відповідальності за прийняття оплати за Підписку платіжним сервісом LiqPay.
                <br />
                <b>
                    <i>
                        — Користувач погоджуєтеся з тим, що Компанія залишає за собою право та може у будь-який час, без повідомлення та не несучи жодної відповідальності перед
                        Користувачем, змінити або припинити роботу Сайту, Додатку та надання Послуг, або видалити Персональні дані та Контент на тимчасовій або на постійній основі.
                    </i>
                </b>
                <br />
                <b>
                    <i>
                        — Користувач усвідомлює та погоджуєтеся з тим, що використання Сайту, Додатку та отримання Послуг або інформації, надається Користувачеві на його страх та
                        ризик. Дана інформація, щодо Товару надається Користувачу на умовах «як є» без яких-небудь гарантій, в тому числі гарантій, що відносяться до товарного
                        стану, придатності, якості та вартості Товару.
                    </i>
                </b>
                <br />
                — КОРИСТУВАЧ РОЗУМІЄ І ПОГОДЖУЄТЬСЯ З ТИМ, ЩО КОМПАНІЯ НЕ НЕСЕМО ВІДПОВІДАЛЬНОСТІ ЗА ЯКІ-НЕБУДЬ ПРЯМІ, НЕПРЯМІ, СПЕЦІАЛЬНІ, ВИПАДКОВІ, НЕПРЯМІ АБО ШТРАФНІ ЗБИТКИ,
                ВКЛЮЧАЮЧИ, АЛЕ НЕ ОБМЕЖУЮЧИСЬ, ЗБИТОК ЗА ВТРАТУ ПРИБУТКУ, РЕПУТАЦІЇ, ДАНИХ АБО ІНШИХ НЕМАТЕРІАЛЬНИХ ЗБИТКІВ (НАВІТЬ ЯКЩО КОМПАНІЯ БУЛА ПОПЕРЕДЖЕНА ПРО МОЖЛИВІСТЬ
                ТАКИХ ЗБИТКІВ), У РЕЗУЛЬТАТІ АБО ВИНИКАЮЧИХ ІЗ (I) ВИКОРИСТАННЯ АБО НЕМОЖЛИВОСТІ ВИКОРИСТАННЯ САЙТУ ТА ДОДАТКУ, (II) ВИТРАТ НА ОТРИМАННЯ ЗАМІНЕНОГО ТОВАРУ ТА / АБО
                ПОСЛУГ В РЕЗУЛЬТАТІ ВИКОРИСТАННЯ ПОСЛУГ, (III) НЕСАНКЦІОНОВАНОГО ДОСТУПУ АБО НЕПРАВОМОЧНОЮ ЗМІНОЮ КОНТРОЛЬОВАНОЇ НАМИ ІНФОРМАЦІЇ ТА/АБО ПЕРСОНАЛЬНИХ ДАНИХ, (IV)
                ЗАЯВ АБО ПОВЕДІНКИ БУДЬ-ЯКИХ ТРЕТІХ ОСІБ (V) БУДЬ-ЯКИХ ІНШИХ ПИТАНЬ, ЩО СТОСУЮТЬСЯ ОБСЛУГОВУВАННЯ.
                <br />
                — Користувач не має права:
                <br />
                <i className={classes.mr100}>— збирати та/або зберігати дані про інших Користувачів;</i>
                <br />
                <i className={classes.mr100}>
                    — використовувати Сайт та Додаток, будь-який Контент з нього, в будь-яких комерційних цілях або для інших цілей, пов'язаних розсилкою спаму, ланцюгових листів,
                    створенням фінансових пірамід та інших схем за участю Сайту та Додатку, його Контенту та результату надання Послуг, або будь-якою іншою формою несанкціонованої
                    реклами;
                </i>
                <br />
                <i className={classes.mr100}>— передавати доступ до Особистого Кабінету третій особі.</i>
            </div>
            <div className={classes.title}>ВИРІШЕННЯ СПОРІВ</div>
            <div className={classes.text}>
                — Усі спори, що виникають за цим Договором та/або пов'язані з ним, вирішуються шляхом переговорів між Сторонами.
                <br />— Якщо відповідний спір неможливо вирішити шляхом переговорів, він вирішується в судовому порядку відповідно до чинного законодавства України.
            </div>
            <div className={classes.title}>КОНФІДЕНЦІЙНА ІНФОРМАЦІЯ</div>
            <div className={classes.text}>
                — Для цілей Договору “Конфіденційна інформація” означає інформацію що стосується умов надання Послуг. До Конфіденційної інформації відносяться:
                <br />
                <i className={classes.mr100}>— Персональні дані;</i>
                <br />
                <i className={classes.mr100}>— умови надання Послуг;</i>
                <br />
                <i className={classes.mr100}>— умови Підписки та взаємовідносин між сторонами.</i>
                <br />
                — Інформація, що не вважається конфіденційною:
                <br />
                <i className={classes.mr100}>— була розроблена Стороною самостійно;</i>
                <br />
                <i className={classes.mr100}>— знаходиться в вільному доступі;</i>
                <br />
                <i className={classes.mr100}>— набула статус загальновідомої інформації, без порушення Стороною умов цього Договору;</i>
                <br />
                <i className={classes.mr100}>— за умови отримання дозволу від Сторони про розкриття такої інформації</i>
                <br />
                <i className={classes.mr100}>— повинна бути розкрита відповідно до закону або будь-яким нормативно-правовим актом органу виконавчої влади України.</i>
                <br />
                — Компанія гарантує, що Конфіденційна інформація:
                <br />
                <i className={classes.mr100}>— зберігається як конфіденційна та не відтворюється та/або не копіюється (понад розумного періоду для цілей Договору);</i>
                <br />
                <i className={classes.mr100}>
                    — не буде передана, розкрита або іншим чином не стане доступною будь-який інший третій стороні (за винятком посадових осіб, працівників, довірених осіб
                    Компанії, яким необхідно знати та використовувати Конфіденційну інформацію для цілей Договору);
                </i>
                <br />
                <i className={classes.mr100}>— не використовується для якої іншої мети, за винятком цілей цього Договору;</i>
                <br />
                <i className={classes.mr100}>— зберігається з дотриманням заходів безпеки.</i>
                <br />
                — Власним Акцентуванням Договору, Користувач надає згоду на збір та обробку власних Персональних даних з наступною метою: дані, що стають відомі,
                використовуватимуться в комерційних цілях, в тому числі отримання та обробки інформації про замовлення, надсилання рекламних та спеціальних пропозицій
                телекомунікаційними засобами зв’язку (електронною поштою, мобільним зв’язком, за допомогою месенджерів тощо). — Не будуть вважатись порушенням наступні дії
                Компанії:
                <br />
                <i className={classes.mr100}>
                    — надання Персональних даних та відповідної інформації третім сторонам, що діють на підставі Договору з Компанії для виконання договірних зобов'язань перед
                    Користувачем;
                </i>
                <br />
                <i className={classes.mr100}>— надання інформації, відповідно до обґрунтованих та застосованих вимог чинного законодавства України.</i>
                <br />— Користувач зобов'язується не розголошувати отримані Персональні дані та дотримуватись вимог закону України “Про захист персональних даних”.
            </div>
            <div className={classes.title}>ПІДТРИМКА КОРИСТУВАЧА</div>
            <div className={classes.text}>
                — Користувач має право звернутись до підтримки Компанії в наступних випадках:
                <br />
                <i className={classes.mr100}>— у разі виникнення збоїв у роботі Сайту та Додатку:</i>
                <br />
                <i className={classes.mr100}>— у разі виникнення питань щодо надання Послуг та роботи Сайту та Додатку;</i>
                <br />
                <i className={classes.mr100}>— щодо замовлення та деактивації Підписки;</i>
                <br />
                <i className={classes.mr100}>— для аналізу та пошуку Товару.</i>
                <br />
                — Для отримання підтримки Компанії Користувач повинен написати електронного листа на:
                <br />
                <i className={classes.mr100}>
                    — пошту <a href="mailto:support@true-price.top">support@true-price.top.</a>
                </i>
                <br />
                <i className={classes.mr100}>
                    — фахівцям за адресою: <a href="dev@true-price.top">dev@true-price.top</a>, в разі виникнення проблем у роботі Сайту та Додатку.
                </i>
                <br />— Якщо Користувач має на меті замовити розширення, або розробку сайту, то він повинен написати електронного листа за електронною адресою:{' '}
                <a href="mailto:nextnextdev@gmail.com">nextnextdev@gmail.com</a>.
            </div>
            <div className={classes.title}>ФОРС-МАЖОР</div>
            <div className={classes.text}>
                — они звільняються від відповідальності за невиконання або неналежне виконання зобов'язань, що передбачені Договором, що виникло внаслідок форс-мажорних обставин.
                <br />
                — Під форс-мажорними обставинами у Договорі розуміються будь-які обставини, що виникли поза волею або всупереч волі чи бажанню Сторін, та яких не можна передбачити
                чи уникнути, включаючи: військові дії, громадські заворушення, епідемії, блокади, землетруси, повені, локдауни, пандемії, пожежі, а також рішення чи приписи органів
                державної влади та управління держави, внаслідок яких на Сторони (або одну із Сторін) покладатимуться додаткові обов'язки чи встановлюватимуться додаткові
                обмеження, та які роблять неможливим подальше повне або часткове виконання Договору, а також інші дії чи події, що існують поза волею Сторін.
                <br />— Якщо форс-мажорні обставини триватимуть більше <span>3-х (трьох)</span> місяців поспіль, то кожна із Сторін матиме право відмовитись від подальшого
                виконання зобов'язань за Договору і, в такому разі, жодна із Сторін не матиме права на відшкодування іншою Стороною можливих збитків.
            </div>
            <div className={classes.title}>РЕКВІЗИТИ КОМПАНІЇ</div>
            <div className={classes.text}>
                ФОП <span>«Петров В.В»</span>
                <br />
                РНОКПП: <span> 3620004957</span>
                <br />
                Адреса: м. Запорiжжя, вул. Чумаченко, буд. 30, кв. 99.
                <br />
                Контактний телефон: <span> +380980431206</span>
                <br />
                E-mail: <a href="mailto:support@true-price.top">support@true-price.top.</a>
                <br />
                Веб-сайт: <a href="true-price.top">true-price.top</a>
                <br />
                Банківські реквізити: <span>UA943133990000026008040206041</span>
                <br />
                ПІБ <span>«Петров В.В.»</span>
                <br />
                <div className={classes.signature}>
                    <img src="/signature.svg" alt="signature" />
                </div>
            </div>
        </section>
    );
};

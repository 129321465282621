import { FC } from 'react'
import { useTranslation } from 'react-i18next';
import classes from './filter.module.css'

export const Filter: FC = () => {
  const { t } = useTranslation(['capabilities', 'ui']);
  return (
    <section className={classes.filterContainer}>
      <div className={classes.filterScreenshotWrapper}>
        <div className={classes.filterScreenshot}></div>
      </div>
      <div className={classes.filterDescription}>
        <div className={classes.filterDescriptionTitle}>{t('filterDescriptionTitle')}</div>
        <div className={classes.filterDescriptionText}>{t('filterDescriptionText')}</div>
      </div>
    </section>
  )
}

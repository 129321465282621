import classes from './faq.module.css';
import { Trans, useTranslation } from 'react-i18next';
import questionIcon from './question-icon.svg';
import { Link } from 'react-router-dom';
export const FAQ = () => {
    const { t } = useTranslation(['PageFAQ']);
    const questionList = [
        {
            question: t('question1'),
            answer: t('answer1'),
            link: 'https://chrome.google.com/webstore/detail/true-price-%D0%BF%D0%BE%D0%BC%D1%96%D1%87%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-ro/obkfkpbaobcmhjmopldnakdgbiecjbbl?hl=uk',
            link1: 'https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-/cibppbhjepploffdgeblccilmmepkkjm',
            link2: 'https://youtu.be/4cP90ramMVQ',
        },
        {
            question: t('question2'),
            answer: t('answer2'),
        },
        {
            question: t('question15'),
            answer: t('answer15'),
        },
        {
            question: t('question3'),
            answer: t('answer3'),
        },
        {
            question: t('question4'),
            answer: t('answer4'),
        },
        {
            question: t('question5'),
            answer: t('answer5'),
        },
        {
            question: t('question6'),
            answer: t('answer6'),
        },
        {
            question: t('question7'),
            answer: t('answer7'),
        },
        {
            question: t('question8'),
            answer: t('answer8'),
        },
        {
            question: t('question9'),
            answer: t('answer9'),
        },
        {
            question: t('question10'),
            answer: t('answer10'),
        },
        {
            question: t('question11'),
            answer: t('answer11'),
            link: 'https://youtu.be/4cP90ramMVQ',
        },
        {
            question: t('question12'),
            answer: t('answer12'),
            link: 'https://microsoftedge.microsoft.com/addons/detail/true-price-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%BD%D0%B0-/cibppbhjepploffdgeblccilmmepkkjm',
        },
        {
            question: t('question13'),
            answer: t('answer13'),
            link: true,
        },
        {
            question: t('question14'),
            answer: t('answer14'),
        },
        {
            question: t('question15'),
            answer: t('answer15'),
        },
    ];
    return (
        <section className={classes.section}>
            <div className={classes.header}>{t('header')}</div>
            <div className={classes.questionsContainer}>
                {questionList.map((question, index) => (
                    <div className={classes.question} key={index}>
                        <div className={classes.questionHeaderWrapper}>
                            <div className={classes.questionIcon}></div>
                            <div className={classes.questionHeader}>{question.question}</div>
                        </div>
                        <div className={classes.answer}>
                            {question['link'] ? (
                                <Trans
                                    i18nKey={question.answer}
                                    values={{ link: question['link'] ?? '', link1: question['link1'] ?? '', link2: question['link2'] ?? '' }}
                                    components={{ a: <a></a> }}
                                />
                            ) : (
                                <Trans i18nKey={question.answer} />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

import { Helmet } from "react-helmet-async";
import { Remove } from "../../features/remove/remove.component";

export const RemovePage = () => {
  return (
    <>
        <Helmet>
            <title>True Price - Видалено</title>
            <link rel="canonical" href="https://true-price.top/remove" />
        </Helmet>
        <Remove />;
    </>);
};

import { Helmet } from "react-helmet-async";
import { MobileFixArticle } from "../../../features/news/articles/mobilefix.component";

export const MobileFixPage = () => {
  return (
    <>
      <Helmet>
        <title>Покращення Пошуку та Новий Розділ на Сайті True Price</title>
        <link rel="canonical" href="https://true-price.top/news/mobile-fix" />
      </Helmet>
      <MobileFixArticle />
    </>);
};

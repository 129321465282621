import { FillBtn } from '../../ui';
import classes from '../news.module.css';
import { Trans, useTranslation } from 'react-i18next';

export const ChatGPT = () => {
    const { t } = useTranslation(['news']);
    return (
        <div className={classes.wrapper}>
            <div className={classes.news}>{t('title')}</div>
            <div className={classes.containerNews}>
                <div className={classes.blockNews}>
                    <div className={classes.textNews} itemScope itemType="http://schema.org/NewsArticle">
                        <meta itemProp="mainEntityOfPage" content="True" />
                        <div className={classes.titleNews}>
                            <span itemProp="headline">Як ChatGPT та True Price змінюють онлайн-шопінг в Україні</span>
                        </div>
                        {/* @ts-ignore */}
                        <div className={classes.dateNews} itemProp="datePublished" content="2024-07-11T20:00:00+08:00">11/07/2024</div>
                        <meta itemProp="dateModified" content="2024-07-11T00:00:00+08:00" />
                        <div className={classes.descriptionNews}>
                            <p itemProp="description">Вітаємо! У цій новині ми хочемо детальніше розповісти про новий спосіб пошуку товарів - <b><a className={classes.link} href='https://chatgpt.com/g/g-frzJMEpma-true-price-pomichnik-v-magazinakh-ukraini' target='_blank'>ChatGPT та True Price</a></b>.</p>
                            <div itemProp="articleBody">
                                <p className={classes.articleText}>
                                    Ви можете знайти його на <a className={classes.link} href='https://true-price.top/' target='_blank'>головній сторінці</a>, у розділі <a className={classes.link} href='https://true-price.top/install' target='_blank'>"Інсталювати"</a>, або просто перейшовши за посиланням: <a className={classes.link} href='https://chatgpt.com/g/g-frzJMEpma-true-price-pomichnik-v-magazinakh-ukraini.' target='_blank'>https://chatgpt.com/../true-price</a>
                                </p>
                                <p className={classes.articleText}>
                                    <a className={classes.link} href='https://chatgpt.com/g/g-frzJMEpma-true-price-pomichnik-v-magazinakh-ukraini.' target='_blank'><b>ChatGPT та True Price</b></a> - це безкоштовний онлайн-консультант, який допоможе вам підібрати товари, зробити розрахунки або відповісти на запитання про будь-які товари. Завдяки інтеграції з <a className={classes.link} href='https://true-price.top' target='_blank'>True Price</a>, ви зможете одразу перевірити вартість вибраного товару в Україні та перейти до магазину з найвигіднішою ціною.
                                </p>
                                <p className={classes.articleText}>Кілька базових прикладів використання ChatGPT та True Price:</p>
                                <ol>
                                    <li><b>Оновлення комп'ютера:</b> Ви хочете оновити свій комп'ютер, але не впевнені, який блок живлення вам потрібен. Просто вкажіть список комплектуючих, які плануєте придбати, і <a className={classes.link} href='https://chatgpt.com/g/g-frzJMEpma-true-price-pomichnik-v-magazinakh-ukraini.' target='_blank'>ChatGPT та True Price</a> допоможуть знайти відповідний за потужністю блок живлення.</li>
                                    <li><b>Ремонт та оздоблення:</b> Ви плануєте зробити невеликий ремонт, наприклад, переклеїти шпалери. Припустимо, ви вже обрали шпалери, але залишаються питання щодо вибору клею, його кількості та інших матеріалів. Зверніться до <a className={classes.link} href='https://chatgpt.com/g/g-frzJMEpma-true-price-помічник-в-магазинах-України.' target='_blank'>ChatGPT та True Price</a>, вкажіть характеристики шпалер та розмір приміщення, і вони підберуть найкращі матеріали та ціни в Україні.</li>
                                    <li><b>Порівняння товарів:</b> Ви знайшли кілька товарів і хочете порівняти їх простими словами з чіткими описами характеристик, а не просто набором цифр і позначень. Напишіть назви товарів у <a className={classes.link} href='https://chatgpt.com/g/g-frzJMEpma-true-price-помічник-в-магазинах-України.' target='_blank'>ChatGPT та True Price</a>, і вони допоможуть обрати найкращий варіант.</li>
                                </ol>
                                <p className={classes.articleText}>Це лише кілька прикладів того, як можна використовувати ChatGPT та True Price. Ви можете вигадати ще більше ситуацій, де штучний інтелект-консультант стане у пригоді.</p>
                                <p className={classes.articleText}>Завдяки <a className={classes.link} href='https://chatgpt.com/g/g-frzJMEpma-true-price-помічник-в-магазинах-України.' target='_blank'>ChatGPT та True Price</a>, пошук і вибір товарів стають набагато зручнішими та швидшими. Спробуйте вже сьогодні і переконайтеся у перевагах цього інноваційного сервісу!</p>
                            </div>
                        </div>
                        <meta itemProp="author" content="Вадим Петров" />
                        <div itemProp="publisher" itemScope itemType="http://schema.org/Organization">
                            <meta itemProp="name" content="True Price" />
                            <div itemProp="logo" itemScope itemType="http://schema.org/ImageObject">
                                <meta itemProp="url" content="/video/poster-hor.webp" />
                                <meta itemProp="width" content="600" />
                                <meta itemProp="height" content="60" />
                            </div>
                        </div>
                        <meta itemProp="image" content="/news-assets/true-price-chatgpt.webp" />
                        <div className={classes.imgNews} style={{ width: '100%' }}>
                            <img src='/news-assets/true-price-chatgpt.webp' alt='ChatGPT та True Price' itemProp="image" />
                        </div>
                        <div className={classes.alignCenter} style={{'marginTop': '30px'}}>
                            <a href='https://true-price.top/news'><FillBtn>Назад до всіх новин</FillBtn></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

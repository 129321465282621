import { useState } from 'react';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { useAppSelector } from '../../../app/store';
import { ChartDate } from '../../ui';
import { SelectedDate } from '../../ui/chart-date/chart-date.component';
import classes from './goods-subscriptions.module.css'
export const GoodsSubscriptions = () => {
    const token = useAppSelector(state => state.auth.user.accessToken);
    // Subscriptions
    const [selectedDateSubscriptions, setSelectedDateSubscriptions] = useState<SelectedDate>([false, false]);
    const changeSelectedDateSubscriptions = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateSubscriptions([start, end]);
    }

    const { data: dataSubscriptions } = truePriceAPI.useFetchCountSubscriptionsQuery({ token, start: selectedDateSubscriptions[0], end: selectedDateSubscriptions[1] }, {
        skip: !token,
    });

    // SubscriptionsByTraffic
    const [selectedDateSubscriptionsByTraffic, setSelectedDateSubscriptionsByTraffic] = useState<SelectedDate>([false, false]);
    const changeSelectedDateSubscriptionsByTraffic = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateSubscriptionsByTraffic([start, end]);
    }

    const { data: dataSubscriptionsByTraffic } = truePriceAPI.useFetchCountMultiSubscriptionsByTrafficQuery({ token, start: selectedDateSubscriptionsByTraffic[0], end: selectedDateSubscriptionsByTraffic[1] }, {
        skip: !token,
    });

    // SubscriptionsByType
    const [selectedDateSubscriptionsByType, setSelectedDateSubscriptionsByType] = useState<SelectedDate>([false, false]);
    const changeSelectedDateSubscriptionsByType = (start: SelectedDate[0], end: SelectedDate[1]) => {
        setSelectedDateSubscriptionsByType([start, end]);
    }

    const { data: dataSubscriptionsByType } = truePriceAPI.useFetchCountMultiSubscriptionsByTypeQuery({ token, start: selectedDateSubscriptionsByType[0], end: selectedDateSubscriptionsByType[1] }, {
        skip: !token,
    });

    return (
        <section className={classes.section}>
            <h1>Отслеживаемые товары</h1>

            <div className={classes.wrapper}>
                <div className={classes.title}>Количество отслеживаемых товаров</div>
                {dataSubscriptions && (
                    <ChartDate chartData={dataSubscriptions} changeSelectedDate={changeSelectedDateSubscriptions} labelName='Количество' />
                )}
            </div>


            <div className={classes.wrapper}>
                <div className={classes.title}>Отслеживаемые товары по траффику</div>
                {dataSubscriptionsByTraffic && (
                    <ChartDate chartData={dataSubscriptionsByTraffic} changeSelectedDate={changeSelectedDateSubscriptionsByTraffic} labelName='Количество' />
                )}
            </div>

            <div className={classes.wrapper}>
                <div className={classes.title}>Отслеживаемые товары по типу</div>
                {dataSubscriptionsByType && (
                    <ChartDate chartData={dataSubscriptionsByType} changeSelectedDate={changeSelectedDateSubscriptionsByType} labelName='Количество' />
                )}
            </div>
        </section>
    )
}

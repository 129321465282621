import classes from './policy.module.css';

export const Policy = () => {
    return (
        <section>
            <div className={classes.title}>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</div>
            <div className={classes.text}>
                Політика Конфіденційності опублікована та набула чинності: <b>21.11.2022</b>
                <br />
                Будь ласка, уважно ознайомтеся з даним документом для того, щоб зрозуміти нашу позицію та політику щодо ваших Персональних даних та те, яким чином ми будемо їх
                використовувати. <br />
                Політика Конфіденційності <span>ФОП “True Price”</span> (далі - "Політика Конфіденційності") описує нашу політику щодо конфіденційності Персональних даних, які
                обробляються Компанією.
                <br />
                Посилання на слова "ми", "наш" або "нас" (або аналогічні слова за змістом) означають <span>ФОП “True Price”</span>, в залежності від контексту Політики
                Конфіденційності.
                <br />
                <br />
                Політика Конфіденційності застосовується тільки до Персональних даних, зібраних під час надання Послуг.
                <br />
                Політика Конфіденційності не застосовується до Персональних даних, зібраних в автономному режимі або по способах, відмінних від способів які використовує Компанія.
                <br />
                <br />
                Ми вдячні вам за використання Сайту та Додатку <span>«True Price»</span>.
            </div>
            <div className={classes.title}>ТЕРМІНИ</div>
            <div className={classes.text}>
                — <span>Додаток “True Price” (надалі “Додаток”)</span> - це комп’ютерна програма, що дає встановлюється в браузері Користувача та дозволяє здійснювати відстеження
                вартості Товарів на сайті маркетплейсу “Rozetka”.
                <br />— <span>Компанія “True Price” (надалі “Компанія”)</span> - це Фізична особа-підприємець <span>«Петров В.В»</span> РНОКПП:
                <span>3620004957</span>, яка надає Послуги Користувачу та здійснює порівняння цін Товарів.
                <br />— <span>Контент</span> - це будь-які матеріали, що розміщені на Сайті та/або Додатку, в тому числі, але не обмежуючись: зображеннями, текстом, ілюстраціями,
                посиланням, програмним кодом, відео та фото матеріалами, аудіофайлами.
                <br />— <span>Користувач</span> - це дієздатна фізична особа, що отримує Послуги та користується функціями Додатку і Сайту.
                <br />— <span>Особистий Кабінет</span> - це додаткова функція Сайту, за допомогою якої Користувач має можливість придбати Підписку, отримувати повідомлення та
                здійснювати налаштування.
                <br />— <span>Персональні дані</span> - інформація, яка дозволяє ідентифікувати Користувача та необхідна для надання Послуг.
                <br />— <span>Підписка</span> - це здійснення оплати за певний період для отримання доступу до додаткової кількості переглядів та порівняння Товарів.
                <br />— <span>Послуги</span> - це вчинення Компанією алгоритму дій, направлений на здійснення порівняння Товару, що розміщений на сайті маркетплейсу “Rozetka”.
                <br />— <span>Сайт “True Price” (надалі “Сайт”)</span> - означає веб-сторінку або групу веб-сторінок в мережі Інтернет, які розміщені в мережі Інтернет за такою
                адресою: <a href="https://true-price.top/">https://true-price.top/</a> та за допомогою яких Користувач отримує Послуги та має можливість порівняти Товар.
                <br />— <span>Товар</span> - продукт людської праці у матеріальній формі, який завдяки своїм властивостям задовольняє потреби Користувача.
            </div>
            <div className={classes.title}>ЗАГАЛЬНІ ПОЛОЖЕННЯ</div>
            <div className={classes.text}>
                Політика Конфіденційності поширюється на Персональні дані, які Користувач надає Компанії під час надання Послуг та/або взаємодії з працівниками Компанії.
                <br />
                Компанія повідомляє, що Персональні дані Користувача, використовуються з метою надання Послуг, у разі незгоди зі збором та обробкою Персональних даних, Користувач
                не має право замовляти Послуги
                <br />
            </div>
            <div className={classes.title}>ПЕРСОНАЛЬНІ ДАНІ</div>
            <div className={classes.text}>
                Під час надання Послуг Користувачу та/або взаємодії із технічною підтримкою Сайта, Користувач має право збирати наступні Персональні дані:
                <br />
                <br />
                <u className={classes.mr50}>
                    ⯈ Компанія повідомляє, що Персональні дані Користувача, використовуються з метою надання Послуг, у разі незгоди зі збором та обробкою Персональних даних,
                    Користувач не має право замовляти Послуги
                </u>
                <br />
                <br />
                <i className={classes.mr100}>— повне ім’я та прізвище; </i>
                <br />
                <i className={classes.mr100}> — e-mail;</i>
                <br />
                <i className={classes.mr100}>— пароль;</i>
                <br />
                <i className={classes.mr100}>— реквізити банківської карти;</i>
                <br />
                <i className={classes.mr100}>— адреса реєстрації;</i>
                <br />
                <i className={classes.mr100}>— IP-адресу, пристрої, операційну систему та браузер.</i>
                <br />
            </div>
            <div className={classes.title}>СПОСОБИ ОТРИМАННЯ ПЕРСОНАЛЬНИХ ДАНИХ</div>
            <div className={classes.text}>
                Компанія має право збирати та обробляти Персональні дані Користувача наступним чином:
                <br />
                <i className={classes.mr100}>— під час надання Послуг;</i>
                <br />
                <i className={classes.mr100}>— під час взаємодії з працівниками Компанії;</i>
                <br />
                <i className={classes.mr100}>— за допомогою лог-файлів, файлів Cookie та інших технологій відстеження;</i>
                <br />
                <i className={classes.mr100}>— за допомогою використання форми зворотнього зв’язку;</i>
                <br />
                <i className={classes.mr100}>— під придбання Підписки;</i>
                <br />
                <i className={classes.mr100}>— під час реєстрації та авторизації на Сайті;</i>
                <br />
                <i className={classes.mr100}>— під час використання сервісу веб-аналітики Google Analytics;</i>
                <br />
                <i className={classes.mr100}>— за допомогою використання месенджерів та соціальних мереж;</i>
                <br />
                <i className={classes.mr100}>— під час встановлення Додатку.</i>
                <br />
            </div>
            <div className={classes.title}>ЦІЛІ ВИКОРИСТАННЯ ПЕРСОНАЛЬНИХ ДАНИХ</div>
            <div className={classes.text}>
                Компанія збирає, обробляє та використовує Персональні дані Користувача в наступних цілях:
                <br />
                <i className={classes.mr100}>— для надання Послуг;</i>
                <br />
                <i className={classes.mr100}>— для забезпечення безпеки Сайту;</i>
                <br />
                <i className={classes.mr100}>
                    — для адміністрування Сайту та проведення внутрішніх операцій, включаючи усунення несправностей, аналізу даних, тестування та проведення опитувань;
                </i>
                <br />
                <i className={classes.mr100}>— для інформування Користувача про нові види Послуг, зміну вартості Послуг, новини тощо;</i>
                <br />
                <i className={classes.mr100}>— для покращення навігації Сайту, виходячи з Ваших уподобань;</i>
                <br />
                <i className={classes.mr100}>
                    — для здійснення маркетингової діяльності, яка може полягати у: відображенні Вам маркетингового вмісту, який відповідає Вашим інтересам, а саме, поведінкова
                    реклама- яка відповідає Вашим вподобанням, вчиненню дій, пов’язаних із прямим маркетингом Послуг, а саме, надсилання комерційної інформації електронним шляхом;
                </i>
                <br />
                <i className={classes.mr100}>— для розміщення реклами на веб-сторінках Сайту;</i>
                <br />
                <i className={classes.mr100}>— для розміщення реклами за допомогою сервісу Google Ads;</i>
                <br />
                <i className={classes.mr100}>— для поліпшення сервісу Додатку;</i>
                <br />
                <i className={classes.mr100}>— для надання Підписки;</i>
                <br />
                <i className={classes.mr100}>— для спілкування з Користувачем за допомогою соціальних мереж, включаючи, але не обмежуючись соціальною мережею Facebook;</i>
                <br />
                <i className={classes.mr100}>— для спілкування з Користувачем за допомогою месенджерів, включаючи, але не обмежуючись месенджерами Telegram та Viber;</i>
                <br />
                <i className={classes.mr100}>— для аналізу поведінки та уподобань Користувача;</i>
                <br />
                <i className={classes.mr100}>— для аналізу Товару, що перевіряє Користувач;</i>
                <br />
                <i className={classes.mr100}>— для взаємодії з платіжним сервісом LiqPay;</i>
                <br />
                <i className={classes.mr100}>— для відповідей на запити правоохоронних органів;</i>
                <br />
                <i className={classes.mr100}>— для ініціювання відповідей на судові позови, розслідування або вирішення спорів з Вами.</i>
                <br />
            </div>
            <div className={classes.title}>ПЕРЕДАЧА ПЕРСОНАЛЬНИХ ДАНИХ</div>
            <div className={classes.text}>
                Компанія має право передавати Персональні дані суб’єктам для реалізації та надання Послуг, та/або захисту своїх правових інтересів:
                <br />— <span>Підрядникам та працівникам.</span> Компанія також може передавати Персональні дані іншим суб’єктам, з якими розпочне співпрацю, включаючи
                консультантів з юридичних питань та питань оподаткування, а також суб’єктам, які надають бухгалтерські, логістичні, маркетингові та ІТ послуги.
                <br />— <span>Судам, правоохоронним та державним органам.</span> Компанія має право передати Персональні дані, в разі виконання будь-яких юридичних зобов'язань, в
                тому числі в зв'язку з постановою суду.
                <br />— <span>Третій стороні.</span> Компанія має право розкривати або передавати Персональні дані, під час наступних операцій: придбання або злиття, фінансування,
                корпоративна реорганізація, спільне підприємство, включаючи продаж активів або в разі банкрутства.
                <br />— <span>Платіжній системі LiqPay.</span> Компанія має право розкривати або передавати Персональні дані, в разі вирішення спорів з платіжною системою LiqPay
                або надання відповідей на запити платіжної системи LiqPay.
                <br />
            </div>
            <div className={classes.title}>ЗБЕРІГАННЯ ПЕРСОНАЛЬНИХ ДАНИХ</div>
            <div className={classes.text}>
                — Компанія використовує всі необхідні заходи безпеки та захисту Персональних даних для забезпечення їх конфіденційності та запобігання втрати чи неправомірного
                розкриття.
                <br />
                — Компанія захищає та зберігає Персональні дані від:
                <br />
                <i className={classes.mr100}>— втрати;</i>
                <br />
                <i className={classes.mr100}>— незаконної використання, передачі, розкриття, зміни, видалення та/або знищення.</i>
                <br />
                — Компанія має право надавати доступ до Персональних даних суб’єктам, відповідно до 6 розділу Політики Конфіденційності.
                <br />
                — Компанія має право зберігати мінімальну кількість статистичних даних для ведення статистики та відслідковування надання Послуг.
                <br />
                — Компанія зберігає Персональні дані на серверу, що знаходиться за адресою: Україна, Львів. ДЦ «Кінескоп».
                <br />
                — Повідомляючи нам Персональні дані, Ви даєте згоду на передачу, зберігання та обробку Персональних. У свою чергу, ми зробимо всі необхідні заходи для того, щоб
                Ваші Персональні дані оброблялися з дотриманням правил безпеки та відповідно до положень даної Політики Конфіденційності.
                <br />
            </div>
            <div className={classes.title}>ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН</div>
            <div className={classes.text}>
                <p>— Користувач має право:</p>
                — знати про джерела збирання, місцезнаходження Персональних даних, мету їх обробки та місцезнаходження;
                <br />
                — отримувати інформацію про умови надання доступу до Персональних даних, зокрема інформацію про третіх осіб, яким вони передаються;
                <br />
                — отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи обробляються Персональні
                дані, а також отримувати зміст таких Персональних даних;
                <br />
                — надіслати вмотивовану вимогу, щодо заперечення обробки Персональних даних;
                <br />
                — надіслати вмотивовану вимогу, щодо зміни або видалення Персональних даних;
                <br />
                — на захист Персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх
                наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію Користувача;
                <br />
                — звертатися із скаргами на обробку Персональних даних до суду;
                <br />
                — застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;
                <br />
                — вносити застереження стосовно обмеження права на обробку Персональних даних під час надання згоди;
                <br />
                — відкликати згоду на обробку Персональних даних;
                <br />
                — знати механізм автоматичної обробки Персональних даних;
                <br />
                — на захист від автоматизованого рішення, яке має для нього правові наслідки.
                <br />
                <p>— Користувач зобов’язаний:</p>
                — надавати достовірну інформацію та Персональні дані, що необхідні для надання Послуг;
                <br />
                — не порушувати інтелектуальні права Компанії;
                <br />
                — не збирати та зберігати Персональні дані іншого Користувача;
                <br />
                — не передавати пароль доступу до Особистого Кабінету третій стороні;
                <br />
                — дотримуватись умов цієї Політики Конфіденційності.
                <br />
                <p>— Компанія має право:</p>
                — розірвати відносини з Користувачем та видалити його Персональні дані, якщо Користувач надає Компанії недостовірні Персональні дані та/або порушує інтелектуальні
                права;
                <br />
                — здійснювати маркетингову діяльність, що полягає в розсиланні Користувачам повідомлень рекламного характеру, за допомогою використання соціальних мереж,
                електронної пошти, месенджерів та SMS;
                <br />
                — перевіряти достовірність Персональних даних.
                <br />
                <p>— Компанія зобов’язана:</p>
                — надати Користувачу доступ до Персональних даних;
                <br />
                — видалит, змінити або обмежити використання Персональних даних, відповідно до його запиту;
                <br />
                — не передавати Персональні дані Користувача, окрім умов вказаних в Політиці Конфіденційності;
                <br />
                — надати Користувачу інформацію про використання його Персональних т даних, в разі отримання запиту від Користувача.
                <br />
            </div>
            <div className={classes.title}>ВІДПОВІДАЛЬНІСТЬ СТОРІН</div>
            <div className={classes.text}>
                — Передача інформації за допомогою використання мережі інтернет не може бути повністю безпечним, і Компанія зберігає та передає Ваші Персональні дані “як є”.
                Компанія прикладає максимум зусиль для збереження Персональних даних та запобігання їх втрати.
                <br />
                — Компанія не несе відповідальності за оброблення та зберігання Персональних даних, які здійснюються третіми особами, яким Компанія передає Персональні дані. —
                Компанія не обробляє Персональні дані Користувача під час придбання ним Підписки.
                <br />
                <br />
                <u className={classes.mr50}>⯈ Компанія не несе відповідальності за:</u>
                <br />
                — втрату Персональних в разі хакерської атаки, взлому програмного забезпечення Сайту, незаконних дій третіх сторін, неправомірних дій Користувача та дій, що
                порушують умови Політики Конфіденційності;
                <br />
                — надання Користувачем недостовірної інформації та Персональних даних;
                <br />
                — розміщення Користувачем Контента в Особистому Кабінету, що містить неправдиву інформацію та який порушує інтелектуальні права третіх осіб; що порушує гідність,
                релігійні вірування, сексуальну орієнтацію та/або містить ознаки расової дискримінації та/або порушення інших прав третьої особи;
                <br />
                — за зміст відгуків, що Користувач залишає на веб-сторінках маркетплейсу Rozetka;
                <br />
                — за дії зберігання та оброблення Персональних даних маркетплейсом Rozetka та платіжною системою LiqPay;
                <br />
                — за інформацію, щодо Товарів, що розміщені на веб-сторінках маркетплейсу Rozetka.
                <br />
                — Користувач несе відповідальність за:
                <br />
                — надання достовірних Персональних даних;
                <br />
                — зміст Контенту, що розміщується ним в Особистому Кабінеті та відгуках веб-сторінках маркетплейсу Rozetka;
                <br />
                — за аналіз інформації щодо Товару.
                <br />
            </div>
            <div className={classes.title}>COOKIE-ФАЙЛИ</div>
            <div className={classes.text}>
                — Компанія має право використовувати Cookie-файлів для наступних цілей: — аутентифікація Користувача;
                <br />
                — Компанія не обробляє Персональні дані Користувача під час придбання ним Підписки.
                <br />
                — відстеження поведінки Користувача;
                <br />
                — відстеження сеансу доступу Користувача;
                <br />
                — зберігання відомостей статистики про Користувача;
                <br />
                — для здійснення маркетингової діяльності;
                <br />
                — для аналізу Товарів, що переглядає Користувач;
                <br />
                — для аналізу популярності Товарів;
                <br />
                — для розміщення реклами на веб-сторінках Сайту та за допомогою сервісу Google Ads.
                <br />
                — Cookie-файли не передають віруси та/або шкідливе програмне забезпечення на Ваш комп'ютер, оскільки дані в Cookie-файлах не змінюються при переміщенні, та вони
                ніяк не впливають на роботу Вашого комп'ютера. Замість цього, вони діють більшою мірою як журнали та оновлюються кожного разу, коли ви відвідуєте Сайт.
                <br />
                — Ви можете в будь-який час відкликати свою згоду на використання файлів Cookie та/або змінити дозволені вами категорії файлів Cookie. Ви також можете вимкнути
                файли Cookie, змінивши налаштування вашого веб-браузера. Інформація, яку збирають файли Cookie, знаходиться в анонімній формі.
                <br />
                — Компанія використовує такі види Cookie-файлів веб-аналітики під час аналізу поведінки Користувача:
                <br />
                — Google Analytics, службу веб-аналізу компанії Google Inc. ("Google");
                <br />
                — Google Ads, онлайн-рекламний сервіс компанії Google Inc. ("Google");
                <br />
                — Facebook реклама, онлайн-рекламний сервіс компанії Facebook, Inc. ("Facebook").
                <br />
            </div>
            <div className={classes.title}>ПОЛІТИКА ПОВНОЛІТНІХ</div>
            <div className={classes.text}>
                — Послуги Компанії призначені для Користувачів які досягли шістнадцятилітнього віку. Компанія навмисно не збирає та не обробляє Персональні дані осіб молодше
                шістнадцяти років.
                <br />
                — Якщо Вам не виповнилось шістнадцять років, не користуйтесь Послугами Компанії.
                <br />
                — У разі, якщо нам стане відомо про використання Послуг такою особою, ми видалимо зібрану інформацію про цю особу, припинено доступ до Сайту і Додатку та матимемо
                право відмінити надання Послуг.
                <br />
                — Компанія зберігає Персональні дані на серверу, що знаходиться за адресою: Україна, Львів. ДЦ «Кінескоп».
                <br />— Якщо у вас є інформація, що такий факт мав місце, зверніться в службу підтримки Компанії, відправивши листа електронною поштою за адресою:{' '}
                <a href="mailto:support@true-price.top">support@true-price.top</a>.
                <br />
            </div>
            <div className={classes.title}>ЗМІНА ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</div>
            <div className={classes.text}>
                — Ми маємо право періодично вносити зміни в Політику Конфіденційності, для безпеки Персональних даних та відповідності вимогам законодавства України.
                <br />
                — Наші електронні або іншим чином збереженні екземпляри Політики Конфіденційності вважаються справжніми, повними, дійсними та такими версіями, що підлягають
                виконанню та діють в момент Вашого відвідування Сайту.
                <br />
            </div>
            <div className={classes.title}>КОНТАКТИ</div>
            <div className={classes.text}>
                — Якщо ви бажаєте задати питання, залишити відгук або ви стурбовані питаннями конфіденційності, зверніться в службу підтримки за адресою:{' '}
                <a href="mailto:support@true-price.top">support@true-price.top.</a>
                <br />— Ви також можете зв'язатися з нами за наступною поштовою адресою: <a href="mailto:nextnextdev@gmail.com"> nextnextdev@gmail.com</a>.
                <br />
            </div>
        </section>
    );
};

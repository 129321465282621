import { Helmet } from "react-helmet-async";
import { Update505 } from "../../../features/news/articles/update-5-0-5.component";

export const Update505Page = () => {
  return (
    <>
      <Helmet>
        <title>Нові покращення у True Price 5.0.5: ефективніший пошук, зручні функції та додаткові можливості</title>
        <link rel="canonical" href="https://true-price.top/news/update-5-0-5" />
      </Helmet>
      <Update505 />
    </>);
};

import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './search.module.css'


interface CategoryProps {
    children?: React.ReactNode;
    updateSearchValue: (value: string) => void;
}


export const Search: FC<CategoryProps> = ({ updateSearchValue }) => {
    const { t } = useTranslation(['discount', 'ui']);

    const [searchValue, setSearchValue] = useState<string>('')

    const searchBtnClickHandler = () => {
        // console.log(searchValue)
        updateSearchValue(searchValue)
    }

    const handleKeyDown = (key: string) => {
        if (key === 'Enter') {
            searchBtnClickHandler();
        }
    }

    return (
        <section className={classes.section}>
            <input type="text" className={classes.input} onKeyDown={(e) => handleKeyDown(e.key)} placeholder={t('inputGoodName')} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <button className={classes.button} onClick={searchBtnClickHandler}>{t('search')}</button>
        </section>
    )
}

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useErrors(
    fields: string[],
): [
    { [type: string]: { error: boolean; msg: string } },
    { [type: string]: { error: boolean; msg: string } },
    Dispatch<SetStateAction<{}>>,
] {
    const [noErrors, setNoErrors] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const newNoErrors: any = {};
        fields.forEach((field) => {
            newNoErrors[field] = { error: false, msg: '' };
        });
        setNoErrors({...newNoErrors});
        setErrors({...newNoErrors});
    }, []);

    return [noErrors, errors, setErrors];
}

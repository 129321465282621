import { Helmet } from "react-helmet-async";
import { News } from "../../features/news/news.component";

export const NewsPage = () => {
  return (
    <>
      <Helmet>
        <title>True Price - Новини</title>
        <link rel="canonical" href="https://true-price.top/news" />
      </Helmet>
      <News />
    </>);
};

import React, { useState, useMemo } from 'react';
import classes from './users.module.css';
import { ChartDate } from '../../ui';
import { truePriceAPI } from '../../../app/api/true-price-api';
import { useAppSelector } from '../../../app/store';
import { SelectedDate } from '../../ui/chart-date/chart-date.component';
import { IStatisticsArray } from '../../../app/api/interfaces/admin/statistics.interface';


export const Users = () => {
  const chartData = {
    date: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    value: [500, 200, 800, 400, 500, 600, 200],
  };
  const [selectedDate, setSelectedDate] = useState<SelectedDate>([false, false]);
  const changeSelectedDate = (start: SelectedDate[0], end: SelectedDate[1]) => {
    setSelectedDate([start, end]);
  };

  const token = useAppSelector(state => state.auth.user.accessToken);

  // Fetch online data
  const { data: onlineData, isLoading: isLoadingOnline, error: errorOnline } = truePriceAPI.useFetchOnlineQuery({ token }, {
    skip: !token,
    pollingInterval: 60000,
  });

  // Fetch new users data
  const { data: newUsersData, isLoading: isLoadingNewUsers, error: errorNewUsers } = truePriceAPI.useFetchNewUsersQuery(
    { token, start: selectedDate[0], end: selectedDate[1] },
    { skip: !token }
  );

  // Fetch online users data
  const [selectedDateOnlineUsers, setSelectedDateOnlineUsers] = useState<SelectedDate>([false, false]);
  const changeSelectedDateOnlineUsers = (start: SelectedDate[0], end: SelectedDate[1]) => {
    setSelectedDateOnlineUsers([start, end]);
  };
  const { data: onlineUsersData, isLoading: isLoadingOnlineUsers, error: errorOnlineUsers } = truePriceAPI.useFetchOnlineUsersQuery(
    { token, start: selectedDateOnlineUsers[0], end: selectedDateOnlineUsers[1] },
    { skip: !token }
  );

  // Fetch removed users data
  const [selectedDateRemoved, setSelectedDateRemoved] = useState<SelectedDate>([false, false]);
  const changeSelectedDateRemoved = (start: SelectedDate[0], end: SelectedDate[1]) => {
    setSelectedDateRemoved([start, end]);
  };
  const { data: removedData, isLoading: isLoadingRemoved, error: errorRemoved } = truePriceAPI.useFetchRemovedQuery(
    { token, start: selectedDateRemoved[0], end: selectedDateRemoved[1] },
    { skip: !token }
  );

  // Fetch churn rate data
  const [selectedDateChurnRate, setSelectedDateChurnRate] = useState<SelectedDate>([false, false]);
  const changeSelectedDateChurnRate = (start: SelectedDate[0], end: SelectedDate[1]) => {
    setSelectedDateChurnRate([start, end]);
  };
  const { data: churnRateData, isLoading: isLoadingChurnRate, error: errorChurnRate } = truePriceAPI.useFetchChurnRateQuery(
    { token, start: selectedDateChurnRate[0], end: selectedDateChurnRate[1] },
    { skip: !token }
  );

  // Fetch data for the table
  const [selectedDateTable, setSelectedDateTable] = useState<SelectedDate>([false, false]);

  const changeSelectedDateTable =  (start: SelectedDate[0], end: SelectedDate[1]) => {
    console.log('start', start);
    console.log('end', end);
    if (end !== false) {
      end.setHours(23, 59, 59, 999); // Включаем весь последний день
    }
    setSelectedDateTable([start, end]);
  }

  const resetDateSelection = () => {
    setSelectedDateTable([false, false]);
  };

  const toLocalISOString = (date: any) => {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(date - tzoffset)).toISOString().slice(0, -1); // without the 'Z'
  };

  const { data: tableData, isLoading: isLoadingTableData, error: errorTableData } = truePriceAPI.useFetchTableStatisticsQuery(
    { token, start: selectedDateTable[0] ? toLocalISOString(selectedDateTable[0]) : false, end: selectedDateTable[1] ? toLocalISOString(selectedDateTable[1]) : false},
    { skip: !token }
  );

  const [sortConfig, setSortConfig] = useState<{ key: keyof IStatisticsArray, direction: 'ascending' | 'descending' } | null>(null);

  const sortedTableData = useMemo(() => {
    let sortableData = tableData?.history ? [...tableData.history] : [];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        // @ts-ignore
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        // @ts-ignore
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [tableData, sortConfig]);

  const filteredTableData = useMemo(() => {
    if (selectedDateTable[0] && selectedDateTable[1]) {
      const startDate = new Date(selectedDateTable[0]);
      const endDate = new Date(selectedDateTable[1]);
      endDate.setHours(23, 59, 59, 999); // Включаем весь последний день
      return sortedTableData.filter(row => {
        const rowDate = new Date(row.date);
        return rowDate >= startDate && rowDate <= endDate;
      });
    }
    return sortedTableData;
  }, [sortedTableData, selectedDateTable]);

  const requestSort = (key: keyof IStatisticsArray) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const calculateTotal = (key: keyof Omit<IStatisticsArray, 'date'>) => {
    // @ts-ignore
    return filteredTableData ? filteredTableData.reduce((acc: number, item: IStatisticsArray) => acc + item[key], 0) : 0;
  };

  const calculateAverage = (key: keyof Omit<IStatisticsArray, 'date'>) => {
    // @ts-ignore
    return filteredTableData ? Math.round(calculateTotal(key) / filteredTableData.length) : 0;
  };

  return (
    <section className={classes.section}>
      <h1>Пользователи</h1>

      <div className={classes.onlineWrapper}>
        {onlineData && (
          <div className={classes.online}>Сейчас в сети: {onlineData.online}</div>
        )}
      </div>

      <div className={classes.wrapper}>
        <div className={classes.title}>Всего пользователей:</div>
        {newUsersData && newUsersData !== undefined && (
          <ChartDate chartData={newUsersData} changeSelectedDate={changeSelectedDate} labelName='Пользователи' />
        )}
      </div>

      <div className={classes.wrapper}>
        <div className={classes.title}>Онлайн за сутки:</div>
        {onlineUsersData && onlineUsersData !== undefined && (
          <ChartDate chartData={onlineUsersData} changeSelectedDate={changeSelectedDateOnlineUsers} labelName='Количество пользователей' />
        )}
      </div>

      <div className={classes.tableWrapper}>
        <div className={classes.title}>Общая статистика:</div>
        <ChartDate chartData={{ date: [], value: [] }} changeSelectedDate={changeSelectedDateTable} labelName='Выбрать дату' />
        <button className={classes.resetButton} onClick={resetDateSelection}>Сбросить</button>
        <table className={classes.table}>
          <thead>
            <tr>
              <th
                onClick={() => requestSort('date')}
                className={sortConfig?.key === 'date' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Дата
              </th>
              <th
                onClick={() => requestSort('usersCount')}
                className={sortConfig?.key === 'usersCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Всего пользователей
              </th>
              <th
                onClick={() => requestSort('onlineUsers')}
                className={sortConfig?.key === 'onlineUsers' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Онлайн
              </th>
              <th
                onClick={() => requestSort('newUsers')}
                className={sortConfig?.key === 'newUsers' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Новые пользователи
              </th>
              <th
                onClick={() => requestSort('searchCount')}
                className={sortConfig?.key === 'searchCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Поисковые запросы
              </th>
              <th
                onClick={() => requestSort('partnerProductsCount')}
                className={sortConfig?.key === 'partnerProductsCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Поиски с партнерской ссылкой
              </th>
              <th
                onClick={() => requestSort('popupUsageCount')}
                className={sortConfig?.key === 'popupUsageCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Открытия виджета
              </th>
              <th
                onClick={() => requestSort('redirectSearchCount')}
                className={sortConfig?.key === 'redirectSearchCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Переходы по поиску
              </th>
              <th
                onClick={() => requestSort('partnerProductsClicksCount')}
                className={sortConfig?.key === 'partnerProductsClicksCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Клики по партнерским продуктам
              </th>
              {/* <th
                onClick={() => requestSort('menuUsageCount')}
                className={sortConfig?.key === 'menuUsageCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Использований улучшенного меню
              </th>
              <th
                onClick={() => requestSort('sortUsageCount')}
                className={sortConfig?.key === 'sortUsageCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Использований сортировки
              </th>
              <th
                onClick={() => requestSort('discountUsageCount')}
                className={sortConfig?.key === 'discountUsageCount' ? classes[`sort-${sortConfig.direction}`] : ''}
              >
                Просмотров реальной скидки
              </th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td></td>
              <td>{calculateTotal('onlineUsers')}</td>
              <td>{calculateTotal('newUsers')}</td>
              <td>{calculateTotal('searchCount')}</td>
              <td>{calculateTotal('partnerProductsCount')}</td>
              <td>{calculateTotal('popupUsageCount')}</td>
              <td>{calculateTotal('redirectSearchCount')}</td>
              <td>{calculateTotal('partnerProductsClicksCount')}</td>
              {/* <td>{calculateTotal('menuUsageCount')}</td>
              <td>{calculateTotal('sortUsageCount')}</td>
              <td>{calculateTotal('discountUsageCount')}</td> */}
            </tr>
            <tr>
              <td>Среднее</td>
              <td></td>
              <td>{calculateAverage('onlineUsers')}</td>
              <td>{calculateAverage('newUsers')}</td>
              <td>{calculateAverage('searchCount')}</td>
              <td>{calculateAverage('partnerProductsCount')}</td>
              <td>{calculateAverage('popupUsageCount')}</td>
              <td>{calculateAverage('redirectSearchCount')}</td>
              <td>{calculateAverage('partnerProductsClicksCount')}</td>
              {/* <td>{calculateAverage('menuUsageCount')}</td>
              <td>{calculateAverage('sortUsageCount')}</td>
              <td>{calculateAverage('discountUsageCount')}</td> */}
            </tr>
            {filteredTableData.map((row: IStatisticsArray, index: number) => (
              <tr key={index}>
                <td>{row.date.split('T')[0]}</td>
                <td>{row.usersCount}</td>
                <td>{row.onlineUsers}</td>
                <td>{row.newUsers}</td>
                <td>{row.searchCount}</td>
                <td>{row.partnerProductsCount}</td>
                <td>{row.popupUsageCount}</td>
                <td>{row.redirectSearchCount}</td>
                <td>{row.partnerProductsClicksCount}</td>
                {/* <td>{row.menuUsageCount}</td>
                <td>{row.sortUsageCount}</td>
                <td>{row.discountUsageCount}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
